import { useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { useSourContext } from '@wayste/sour-context';
import { Button, CurrencyTextField, Dialog, Textarea } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import CustomerAccountCreditTable from './customer-account-credit-table';

type Props = {
    open: boolean;
    onClose: () => void;
    customerId: string;
    currentAccountCredit: number;
    onCreditUpdate: (newAmount: number) => void;
};

const CustomerAccountCreditDialog = ({ open, onClose, customerId, currentAccountCredit, onCreditUpdate }: Props) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [newAmount, setNewAmount] = useState<number | ''>('');
    const [note, setNote] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSave = async () => {
        if (!newAmount || !note) return;
        setIsLoading(true);
        try {
            await client.invoice().adminPortal.accountCredit.create({
                amount: newAmount,
                date: new Date().toISOString(),
                note,
                accountCreditType: 'MANUAL',
                customerID: customerId,
            });
            setShowToast({ message: 'Customer account credit updated', severity: 'success' });
            setNewAmount('');
            setNote('');
            onCreditUpdate(newAmount);
            onClose();
        } catch (error) {
            console.warn('Error updating customer account credit:', error);
            setShowToast({ message: 'Error updating customer account credit', severity: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} variant="skinny-pullout" dialogBodyClassName="overflow-auto">
            <div className="flex h-full flex-col gap-4 bg-neutral-50 px-4 pb-4">
                <div className="pt-2 text-left text-xl font-semibold leading-normal tracking-wide text-zinc-700">
                    Update Customer Account Credit
                </div>
                <hr />
                <div className="flex flex-1 flex-col gap-4 pt-1">
                    <p>
                        Enter how much you'd like to adjust Account Credit by. NOT the new value you'd like to make the Account Credit, but
                        the amount you want to increase or decrease Account Credit by.
                    </p>
                    <p className="text-sm text-gray-600">
                        Enter a positive number to add to Account Credit. Enter a negative number to subtract from Account Credit.
                    </p>
                    <div className="flex flex-col gap-y-2">
                        <CurrencyTextField
                            label="Adjust Account Credit By"
                            value={newAmount}
                            useCents
                            onChange={(value) => {
                                setNewAmount(value);
                            }}
                        />
                        <div className="pl-3 text-sm">
                            New Account Credit: {moneyFormatter(newAmount ? currentAccountCredit + newAmount : currentAccountCredit)}
                        </div>
                    </div>
                    <Textarea
                        label="Note"
                        required
                        inputProps={{
                            value: note,
                            onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => setNote(e.target.value),
                        }}
                    />
                </div>
                <CustomerAccountCreditTable customerId={customerId} />
                <div className="flex flex-col gap-y-4">
                    <Button className="btn-primary w-full" disabled={!newAmount || !note} onClick={handleSave} loading={isLoading}>
                        Save
                    </Button>
                    <Button className="btn-dark-grey-outlined w-full" disabled={isLoading}>
                        Cancel
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default CustomerAccountCreditDialog;
