import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Material, MaterialLabels, Notification, Order } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Dialog } from '@wayste/sour-ui';
import { formatEmailDestination, formatServiceAddress, formatServiceDate, getDateFormat, moneyFormatter } from '@wayste/utils';
import { addDays, format, parseISO } from 'date-fns';
import { getCustomerToAndCCEmails, getOrderCurrentSwitch } from '../../utils';
import OrderForm from '../OrderForm';
import SendEmailDialog from './SendEmailDialog';

const emailCheckKeys = {
    addressString: { label: 'Address', value: (value: string) => value },
    material: { label: 'Material', value: (value: Material) => MaterialLabels[value] },
    expectedSize: {
        label: 'Dumpster Size',
        value: (value: number | Order.AllianceOrderTransport['expectedSize']) => `${typeof value === 'number' ? value : value.size} YD`,
    },
    expectedDeliveryDate: {
        label: 'Delivery Date',
        value: (value: string) => format(new Date(getDateFormat(value)), 'EEEE MM/dd/yy'),
    },
    expectedPickupDate: {
        label: 'Pickup Date',
        value: (value: string) => (value ? format(new Date(getDateFormat(value)), 'EEEE MM/dd/yy') : ''),
    },
    sharedDeliveryNotes: {
        label: 'Delivery Instructions',
        value: (value: string) => value,
    },
    sharedPickupNotes: { label: 'Pickup Instructions', value: (value: string) => value },
    sharedDeliveryNotesPrivate: {
        label: 'Additional Delivery Notes',
        value: (value: string) => value,
    },
    sharedPickupNotesPrivate: {
        label: 'Additional Pickup Notes',
        value: (value: string) => value,
    },
};

/**
 * Component to Update an item in the orders table
 * @param {*} order A order schema object
 * @param {Function} onBackButtonClick Function on modal close
 * @param {Boolean} open Controls if modal is visible
 * @param {Function} onSubmit Function on update
 */
interface OrderUpdateProps {
    order: Order.AllianceOrderTransport;
    open: boolean;
    onBackButtonClick: () => void;
    customer: Customer.AllianceCustomerTransport;
}
const OrderUpdate = ({ order, onBackButtonClick, open, customer }: OrderUpdateProps) => {
    const client = useWaysteClient();
    const userProfile = client.user().get();
    const { useConfirmationDialog, setShowToast } = useSourContext();
    const { getConfirmation } = useConfirmationDialog();
    const [showSendUpdateEmail, setShowSendUpdateEmail] = useState<boolean>(false);
    const [emailChanges, setEmailChanges] = useState<{ label: string; oldValue: string; newValue: string }[]>([]);
    const [currentSwitch, setCurrentSwitch] = useState<(Order.OrderSwitchTransport & { relationship: 'CHILD' | 'PARENT' }) | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setCurrentSwitch(getOrderCurrentSwitch(order));
    }, [order]);

    const checkForEmailChanges = (data: Order.AllianceOrderUpdateInput & { addressString: string }) => {
        const currentOrder = {
            ...order,

            addressString: formatServiceAddress(order.serviceLocation.address),
            weightLimit: order?.weightLimit?.value,
            expectedSize: order?.expectedSize?.size,
        };

        const changes: { label: string; oldValue: string; newValue: string }[] = [];

        Object.keys(emailCheckKeys).forEach((key) => {
            if (
                //@ts-expect-error typscript doesn't like this TODO
                emailCheckKeys[key as keyof typeof emailCheckKeys].value(currentOrder[key]) !==
                //@ts-expect-error typscript doesn't like this TODO
                emailCheckKeys[key as keyof typeof emailCheckKeys].value(data[key])
            ) {
                const change = {
                    label:
                        (emailCheckKeys[key as keyof typeof emailCheckKeys].label === 'Delivery Date' ||
                            emailCheckKeys[key as keyof typeof emailCheckKeys].label === 'Pickup Date') &&
                        currentSwitch
                            ? 'Final Removal Date'
                            : emailCheckKeys[key as keyof typeof emailCheckKeys].label,
                    //@ts-expect-error typscript doesn't like this TODO
                    oldValue: emailCheckKeys[key as keyof typeof emailCheckKeys].value(currentOrder[key]),
                    //@ts-expect-error typscript doesn't like this TODO
                    newValue: emailCheckKeys[key as keyof typeof emailCheckKeys].value(data[key]),
                };
                changes.push(change);
            }
        });
        return changes;
    };

    const submitHandler = async (data: Order.AllianceOrderUpdateInput) => {
        setIsLoading(true);
        if (currentSwitch) {
            if (
                data.expectedDeliveryDate &&
                format(new Date(data.expectedDeliveryDate), 'yyyy-MM-dd') !== format(new Date(order.expectedDeliveryDate), 'yyyy-MM-dd') &&
                currentSwitch.relationship === 'CHILD'
            ) {
                const confirmed = await getConfirmation({
                    title: 'Handle the Switch',
                    message:
                        "This order is part of a switch. Do you want to update the other order's Pickup Date? Or do you want to Split the Switch?",
                    cancelText: 'Split Switch',
                    confirmText: 'Update Other Order',
                });
                if (confirmed) {
                    // update other order
                    await client.order().adminPortal.update(currentSwitch.fromOrder, {
                        expectedPickupDate: data.expectedDeliveryDate,
                    });
                } else if (!confirmed) {
                    await client.order().adminPortal.deleteSwitch(currentSwitch.id);
                }
            }
            if (data.expectedPickupDate && order.expectedPickupDate) {
                if (
                    format(new Date(data?.expectedPickupDate), 'yyyy-MM-dd') !==
                        format(new Date(order?.expectedPickupDate), 'yyyy-MM-dd') &&
                    currentSwitch.relationship === 'PARENT'
                ) {
                    const confirmed = await getConfirmation({
                        title: 'Handle the Switch',
                        message:
                            "This order is part of a switch. Do you want to update the other order's Delivery Date? Or do you want to Split the Switch?",
                        cancelText: 'Split Switch',
                        confirmText: 'Update Other Order',
                    });
                    if (confirmed) {
                        // update other order
                        await client.order().adminPortal.update(currentSwitch.toOrder, {
                            expectedDeliveryDate: data.expectedPickupDate,
                        });
                    } else if (!confirmed) {
                        await client.order().adminPortal.deleteSwitch(currentSwitch.id);
                    }
                }
            }
        }

        const updatedOrder: Order.AllianceOrderUpdateInput = {
            ...data,
            expirationDate:
                data.expectedDeliveryDate && data.adjustedRentalPeriod?.value
                    ? addDays(parseISO(data.expectedDeliveryDate), Number(data.adjustedRentalPeriod.value)).toISOString()
                    : undefined,
        };

        await client.order().adminPortal.update(order.id, updatedOrder);
        const changes = checkForEmailChanges({
            ...data,
            expectedDeliveryDate: format(formatServiceDate(data.expectedDeliveryDate as string, 'date') as Date, 'yyyy-MM-dd'),
            addressString: formatServiceAddress(data?.serviceLocation?.address),
        });

        if (data.extendingRental && !order.extendingRental) {
            const extendingEmailConfirmed = await getConfirmation({
                title: 'Send Extending Rental Email',
                message:
                    'Do you want to send an email to the customer letting them know that they are Extending their Rental and the per day rental extension rate?',
                confirmText: 'Send Email',
                cancelText: 'Cancel',
            });
            if (extendingEmailConfirmed) {
                const contactEmailsDispatch = getCustomerToAndCCEmails('dispatch', customer, order.serviceLocation.address);
                const extendingEmailData: Notification.SendGrid.RoExtendingRental = {
                    csa_name: userProfile.firstName || '',
                    order_number: order.orderNumber?.toString() || '',
                    service_location: formatServiceAddress(order.serviceLocation.address),
                    extension_end_date: updatedOrder.expectedPickupDate
                        ? format(new Date(updatedOrder.expectedPickupDate), 'EEEE MM/dd/yy')
                        : '',
                    first_name: contactEmailsDispatch.toContact.firstName || '',
                    rental_extension_fee: updatedOrder.rentExtensionFee
                        ? moneyFormatter(updatedOrder.rentExtensionFee, { hideDollarSign: true })
                        : '',
                    custom_message: '',
                };
                const destination = formatEmailDestination(
                    contactEmailsDispatch.to,
                    contactEmailsDispatch.toContact.firstName || '',
                    contactEmailsDispatch.cc,
                );
                try {
                    await client.notification().adminPortal.createInstantNotification({
                        handler: 'sendgrid',
                        topic: 'ro-extending-rental',
                        body: JSON.stringify(extendingEmailData),
                        destination,
                    });
                    setShowToast({
                        severity: 'success',
                        message: 'Extending Rental Email Sent Successfully',
                    });
                } catch (error) {
                    console.warn('OrderUpdate send extending rental email error: ', error);
                    setShowToast({
                        severity: 'error',
                        message: 'An error occurred sending the Extending Rental Email',
                    });
                }
            }
        }

        if (changes.length > 0) {
            setEmailChanges(changes);
            setShowSendUpdateEmail(true);
        }
        onBackButtonClick();
        setIsLoading(false);
    };

    return (
        <>
            <Dialog open={open} onClose={onBackButtonClick} styledTitle="Update Order" className="max-w-2xl">
                <div className="pt-2">
                    <OrderForm order={order} onCancel={onBackButtonClick} onSubmit={submitHandler} isLoading={isLoading} />
                </div>
            </Dialog>
            <SendEmailDialog
                open={showSendUpdateEmail}
                onClose={() => setShowSendUpdateEmail(false)}
                changes={emailChanges}
                customer={customer}
                order={order}
            />
        </>
    );
};

export default OrderUpdate;
