import { Order, Pricing } from '@alliance-disposal/transport-types';
import { OrderPricingBlockFormProps } from '../components/OrderPricingBlock';

export const createSourgumPricingSnapshot = (
    formData: OrderPricingBlockFormProps,
    zonePricing: Pricing.PricingTransport | null,
): Order.SourgumPricingSnapshot | undefined => {
    const chosenMaterial = formData.material || undefined;
    const chosenSize = formData.expectedSize || undefined;
    if (!chosenMaterial || !chosenSize) return;

    const overrideValues = {
        dump: 0, //will always be 0 without provided zone?
        over: +formData.overage,
        haul: +formData.price,
        tonLimit: formData.weightLimit,
    };

    let zoneID = undefined;
    let materialID = undefined;
    let pricingData = {
        size: String(chosenSize),
        ...overrideValues,
    } as Pricing.PricingSizeDataTransport;

    let zoneMaterialPricingData: Pricing.MaterialPricingTransport | undefined = undefined;

    if (zonePricing) {
        zoneID = zonePricing.id; //update zoneID if zonePricing exists

        zoneMaterialPricingData = zonePricing.pricingData.find((item) => item.material === chosenMaterial);
        if (zoneMaterialPricingData?.id) {
            materialID = zoneMaterialPricingData.id; //update materialID if material pricing exists for zone

            const zoneSizeData = zoneMaterialPricingData?.sizes.find((item) => Number(item.size) === chosenSize);
            if (zoneSizeData) {
                pricingData = zoneSizeData; //update pricingData if pricing exists for size
            }
        }
    }

    if (!zoneMaterialPricingData) {
        return;
    }

    return {
        zoneID,
        materialID,
        material: chosenMaterial,
        // this will need to be updated to include the zoneMaterialPricingData when types are updated
        pricingData,
    };
};
