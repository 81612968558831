import { useContext, useState } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { Dialog, Menu, MenuItem, Radio } from '@wayste/sour-ui';
import { getPrimaryCustomerContact } from '@wayste/utils';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import { BillingContext } from '../../context';

type Props = {
    onAddPayment: (haulerID?: string) => void;
    onCreateNewCard: () => void;
};

const TopBar = ({ onAddPayment, onCreateNewCard }: Props) => {
    const {
        customer,
        hasChanges,
        selectedOrder,
        selectedOrderType,
        handleReceivableSave,
        handlePayableSave,
        handleCreateNewReceivable,
        refetchOrder,
        view,
        setView,
        isLoadingOrder,
        payables,
    } = useContext(BillingContext);
    const history = useHistory();
    const [showHaulerBillSelect, setShowHaulerBillSelect] = useState(false);

    const onSave = async () => {
        if (view === 'invoice') {
            handleReceivableSave();
        } else if (view === 'bill') {
            handlePayableSave();
        }
    };

    const primaryContact: Customer.AllianceCustomerContactTransport | undefined = (() => {
        if (!customer) return undefined;
        return getPrimaryCustomerContact(customer) ?? customer.contacts[0];
    })();

    const handleOnAddPayment = () => {
        if (!selectedOrder) return;
        if (view !== 'bill') {
            onAddPayment();
            return;
        }
        if (payables.some((item) => item.haulerID !== payables[0].haulerID) || !selectedOrder.vendorID) {
            setShowHaulerBillSelect(true);
            return;
        }
        onAddPayment(selectedOrder.vendorID);
    };

    const handleOnCreateNewCard = () => {
        if (view === 'invoice') handleCreateNewReceivable();
        if (view === 'bill') onCreateNewCard();
    };

    if (!selectedOrder || !customer) {
        return (
            <div className="rounded-br-4 rounded-bl-4 sticky top-0 z-10 flex items-center justify-center bg-white px-5 py-1 shadow-md">
                Select an order
            </div>
        );
    }

    return (
        <div className="rounded-br-4 rounded-bl-4 sticky top-0 z-10 flex items-center justify-center bg-white px-5 py-1 shadow-md">
            <div className="flex flex-1 items-center justify-between">
                <div className="flex items-center gap-2">
                    {selectedOrderType === 'universal-service' && (
                        <button className="btn-icon" onClick={() => history.goBack()}>
                            <ArrowLeftIcon className="h-5 w-5" />
                        </button>
                    )}
                    {customer.companyName
                        ? customer.companyName
                        : primaryContact
                          ? `${primaryContact.firstName} ${primaryContact.lastName}`
                          : ''}
                </div>
                <div className="flex gap-4 text-sm">
                    <Radio
                        label="Receivable"
                        inputProps={{
                            value: 'invoice',
                            checked: view === 'invoice',
                            onChange: () => setView('invoice'),
                            disabled: hasChanges,
                        }}
                    />
                    <Radio
                        label="Payable"
                        inputProps={{
                            value: 'bill',
                            checked: view === 'bill',
                            onChange: () => setView('bill'),
                            disabled: hasChanges,
                        }}
                    />
                </div>
                <div>
                    <button className="btn-dark-grey-outlined py-1" onClick={() => refetchOrder()}>
                        {hasChanges ? 'Cancel' : 'Refresh'}
                    </button>
                    <button className="btn-primary mx-5 py-1" disabled={!hasChanges || isLoadingOrder} onClick={() => onSave()}>
                        save
                    </button>
                    <Menu className="btn-icon h-9 w-9 !p-1" button={<EllipsisVerticalIcon className="h-6 w-6" />}>
                        <MenuItem onClick={() => handleOnAddPayment()} disabled={hasChanges || (view === 'bill' && !payables[0])}>
                            Add Payment
                        </MenuItem>
                        <MenuItem onClick={handleOnCreateNewCard}>Create New {view}</MenuItem>
                    </Menu>
                </div>
            </div>
            <Dialog open={showHaulerBillSelect} onClose={() => setShowHaulerBillSelect(false)} styledTitle="Select Hauler to Pay">
                <div className="flex flex-col">
                    {payables.map((bill) => (
                        <div
                            className="cursor-pointer border-b px-4 py-2 hover:bg-gray-100"
                            key={bill.invoiceDetails.invoiceNumber}
                            onClick={() => {
                                setShowHaulerBillSelect(false);
                                onAddPayment(bill.haulerID);
                            }}
                        >
                            {bill.vendorName}
                        </div>
                    ))}
                </div>
                <div className="mt-4 flex justify-end">
                    <button className="btn-dark-grey-outlined" onClick={() => setShowHaulerBillSelect(false)} type="button">
                        Cancel
                    </button>
                </div>
            </Dialog>
        </div>
    );
};

export default TopBar;
