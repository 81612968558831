import React from 'react';

export interface CardInfoListData {
    label: string;
    value: React.ReactNode;
    hidden?: boolean;
}

interface CardInfoListProps {
    data: Array<CardInfoListData>;
    heading?: string;
    border?: boolean;
    containerSize?: number;
}

const CardInfoList = ({ data, heading, border }: CardInfoListProps) => {
    return (
        <div className={`h-full ${border ? 'lg:border-r lg:pr-4' : ''}`}>
            <div className={`grid h-fit grid-cols-3 gap-2`}>
                {heading ? <div className="col-span-3 truncate pb-2 font-medium">{heading}</div> : null}
                {data.map((item) => {
                    if (item.hidden) return null;
                    return (
                        <React.Fragment key={item.label}>
                            <div className="min-w-0">
                                <span className="block truncate text-gray-500" title={item.label}>
                                    {item.label}:
                                </span>
                            </div>
                            <div className="col-span-2 max-h-32 overflow-auto break-words">
                                {typeof item.value === 'string' ? <span className="whitespace-pre-line">{item.value}</span> : item.value}
                            </div>
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default CardInfoList;
