import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { differenceInBusinessDays, endOfDay, endOfMonth, endOfYesterday, getDay, previousFriday, startOfDay, startOfToday } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const Dashboard = () => {
    const client = useWaysteClient();
    const [yesterdayOrders, setYesterdayOrders] = useState<number>(0);
    const [isMonSunSat, setIsMonSunSat] = useState(false);
    const [todaysOrders, setTodaysOrders] = useState<number>(0);
    const [totalOrders, setTotalOrders] = useState<number>(0);
    const [businessDays, setBusinessDays] = useState<number>(0);

    const handleGetYesterdayOrders = async () => {
        const isNotWeekDay = getDay(new Date()) === 0 || getDay(new Date()) === 6 || getDay(new Date()) === 1;
        setIsMonSunSat(isNotWeekDay);

        const now = new Date();
        const timeZone = 'America/New_York';

        // Get today's start and end in New York timezone
        const todayStart = toZonedTime(startOfDay(now), timeZone);
        const todayEnd = toZonedTime(endOfDay(now), timeZone);

        if (isNotWeekDay) {
            const fridayStart = toZonedTime(startOfDay(previousFriday(now)), timeZone);
            const yesterdayEnd = toZonedTime(endOfDay(endOfYesterday()), timeZone);

            const response = await client.order().adminPortal.query({
                limit: 0,
                createdAfter: fridayStart.toISOString(),
                createdBefore: yesterdayEnd.toISOString(),
            });

            setYesterdayOrders(response?.total || 0);
        } else {
            const yesterdayStart = toZonedTime(startOfDay(endOfYesterday()), timeZone);
            const yesterdayEnd = toZonedTime(endOfDay(endOfYesterday()), timeZone);

            const response = await client.order().adminPortal.query({
                limit: 0,
                createdAfter: yesterdayStart.toISOString(),
                createdBefore: yesterdayEnd.toISOString(),
            });

            setYesterdayOrders(response?.total || 0);
        }

        // today orders - matching the SQL query exactly
        const todayOrder = await client.order().adminPortal.query({
            limit: 0,
            createdAfter: todayStart.toISOString(),
            createdBefore: todayEnd.toISOString(),
        });

        setTodaysOrders(todayOrder?.total || 0);

        // business days
        const businessDays = Math.abs(differenceInBusinessDays(startOfToday(), endOfDay(endOfMonth(new Date()))));
        setBusinessDays(businessDays);
    };

    const handleGetTotalOrders = async () => {
        const response = await client.order().adminPortal.query({
            limit: 0,
        });

        setTotalOrders(response?.total || 0);
    };

    useEffect(() => {
        handleGetTotalOrders();
        handleGetYesterdayOrders();
    }, []);

    const rows: { heading: string; value: number; headingSize: string }[] = [
        {
            heading: 'Total Trees Planted',
            value: totalOrders,
            headingSize: 'text-5xl',
        },
        {
            heading: 'Orders Placed Today',
            value: todaysOrders,
            headingSize: 'text-5xl',
        },
        {
            heading: `Orders Placed ${isMonSunSat ? 'Fri - Sun' : 'Yesterday'}`,
            value: yesterdayOrders,
            headingSize: 'text-5xl',
        },
        {
            heading: `Business Days Left in Month`,
            value: businessDays,
            headingSize: 'text-5xl',
        },
    ];

    return (
        <div className="mx-auto flex w-full max-w-screen-xl flex-1 flex-col items-center justify-center px-6 py-3">
            <div className="w-full">
                <table className="w-full">
                    <tbody>
                        {rows.map((row) => (
                            <tr key={row.heading}>
                                <td className={`p-4 font-light ${row.headingSize}`}>{row.heading}</td>
                                <td className={`p-4 text-right ${row.headingSize}`}>{row.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Dashboard;
