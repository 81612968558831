import { useEffect, useState } from 'react';
import { V1 as sourgumPricingFunctions } from '@alliance-disposal/pricing';
import { Material, MaterialLabels, Pricing } from '@alliance-disposal/transport-types';
import { Tooltip } from '@wayste/sour-ui';
import { moneyFormatter, sortByKey } from '@wayste/utils';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { useHistory } from 'react-router-dom';
import { priceTypesEnums, routes } from '../../utils';

type Props = {
    haulersPricing: Pricing.PricingTransport[];
    material?: Material | '' | null;
    onRowClick?: (pricing: any) => void;
    showZoneName?: boolean;
};

const headers = ['Zone Name', 'Hauler', 'Material', 'Price Type', 'Haul Rate', 'Dump Rate'];

const HaulersPricingTable = ({ haulersPricing, material = '', onRowClick, showZoneName = false }: Props) => {
    const [sorted, setSorted] = useState([]);

    useEffect(() => {
        const data: any = [];
        haulersPricing.forEach((pricing) => {
            if (material) {
                const found = pricing.pricingData.find((mat) => mat.material === material);
                let normalizedMaterial = null;
                if (found) {
                    normalizedMaterial = sourgumPricingFunctions.normalizeMaterialSize(found);
                    normalizedMaterial.sizes = normalizedMaterial.sizes.map((item) => {
                        return {
                            ...item,
                            originalType: item.type,
                        };
                    });
                }
                data.push({ ...pricing, material: normalizedMaterial });
            } else {
                pricing.pricingData.forEach((mat) => {
                    const normalizedMat = sourgumPricingFunctions.normalizeMaterialSize({
                        ...mat,
                    });
                    normalizedMat.sizes = normalizedMat.sizes.map((item) => {
                        return {
                            ...item,
                            originalType: item.type,
                        };
                    });
                    data.push({
                        ...pricing,
                        material: normalizedMat,
                    });
                });
            }
        });
        const filteredData = material ? data.filter((item: any) => item.material?.material === material) : data;
        setSorted(sortByKey('material', filteredData, true));
    }, [haulersPricing, material]);

    return (
        <div className="w-full flex-1 overflow-x-auto">
            <table className="w-full border-collapse">
                <thead>
                    <tr>
                        {headers.map((heading) => (
                            <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm" key={heading}>
                                {heading}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {sorted.map((pricing: any) => (
                        <HaulerRow
                            key={pricing.id + (pricing?.material?.material || '')}
                            pricing={pricing}
                            material={pricing.material}
                            onRowClick={onRowClick}
                            showZoneName={showZoneName}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default HaulersPricingTable;

type HaulerRowProps = {
    pricing: any;
    material: any;
    onRowClick?: (pricing: any) => void;
    showZoneName: any;
};

const HaulerRow = ({ pricing, material, onRowClick, showZoneName }: HaulerRowProps) => {
    const history = useHistory();
    const getHaulRateDisplay = () => {
        if (material?.doesNotService) return '';
        if (!material) return 'N/A';
        return Object.values(material.sizes).map((item: any) => (
            <div key={item.size}>
                <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}>{item.size}:</span> {moneyFormatter(item.haul)}
                {material.originalType !== priceTypesEnums.flat ? (
                    <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}>
                        {' '}
                        TL: <span style={{ color: 'rgba(0, 0, 0, 0.88)' }}>{item.tonLimit}</span>
                    </span>
                ) : null}
                {', '}
            </div>
        ));
    };

    const getDumpRateDisplay = () => {
        if (material?.doesNotService) return '';
        if (!material) return 'N/A';
        return Object.values(material.sizes).map((item: any) => (
            <div key={item.size}>
                <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}>{item.size}:</span> {moneyFormatter(item.dump)}
                {', '}
            </div>
        ));
    };

    return (
        <tr
            className={`${onRowClick ? 'cursor-pointer hover:bg-slate-100' : ''} ${
                !pricing.hauler.active || material?.doesNotService ? 'bg-red-300' : ''
            }`}
            onClick={onRowClick ? () => onRowClick(pricing) : () => null}
        >
            {/* Zone Name */}
            {showZoneName ? (
                <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm">{pricing.zoneName}</td>
            ) : null}
            {/* Hauler name */}
            <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm">
                <Tooltip
                    text={
                        <div onClick={(e) => e.stopPropagation()}>
                            <div>Notes: {`${pricing.notes} ${pricing.hauler.notes}`}</div>
                            <div>Open Sat: {pricing.hauler?.hoursOfOperation?.sat?.start ? 'Yes' : 'No'}</div>
                            <div>Sizes: {material ? material.sizes.map((size: any) => `${size.size}, `) : null}</div>
                        </div>
                    }
                >
                    <span
                        onClick={() => {
                            if (!onRowClick) {
                                history.push(routes.haulers.details(pricing.haulerID), {
                                    modal: true,
                                });
                            }
                        }}
                    >
                        {pricing.haulerName}
                    </span>
                </Tooltip>
            </td>
            {/* If applicable material and and material type */}
            <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {material?.material ? MaterialLabels[material.material as Material] : 'N/A'}
                    {!pricing.sourgumApproved && (
                        <div style={{ marginLeft: 5 }}>
                            <Tooltip text="Pricing has not been approved by Sourgum">
                                <ExclamationTriangleIcon className="text-warning h-6 w-6" />
                            </Tooltip>
                        </div>
                    )}
                </div>
            </td>
            <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm">
                {material?.doesNotService ? 'Does not service' : material?.type || 'N/A'}
            </td>
            {/* Haul rate or array of haul rates per size */}
            <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm">{getHaulRateDisplay()}</td>
            {/* Dump rate */}
            <td className="border-0 border-b border-solid border-b-gray-200 px-4 py-1.5 text-sm">{getDumpRateDisplay()}</td>
        </tr>
    );
};
