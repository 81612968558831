import { Material, MaterialLabels } from '@alliance-disposal/transport-types';
import { buildAddressString, moneyFormatter, titleCase } from '@wayste/utils';
import { OrderStatusText } from '../../utils';

const backendToFrontendFieldMap: any = {
    Pricing: {
        tripCharge: {
            label: 'Trip Charge',
            formatter: (value: number) => moneyFormatter(value),
        },
        notes: {
            label: 'Notes',
        },
        prohibitedItems: {
            label: 'Prohibited Items',
        },
        monday: {
            label: 'Monday',
        },
        tuesday: {
            label: 'Tuesday',
        },
        wednesday: {
            label: 'Wednesday',
        },
        thursday: {
            label: 'Thursday',
        },
        friday: {
            label: 'Friday',
        },
        saturday: {
            label: 'Saturday',
        },
        sunday: {
            label: 'Sunday',
        },
        zoneName: {
            label: 'Zone Name',
        },
        pricingData: {
            allowOnlineCheckout: {
                label: 'Allow Online Checkout',
                formatter: (value: string) => (value === 'true' ? 'Yes' : 'No'),
            },
            doesNotService: {
                label: 'Do Not Service',
                formatter: (value: string) => (value === 'true' ? 'Yes' : 'No'),
            },
            dump: {
                label: 'Dump Rate',
                formatter: (value: number) => moneyFormatter(value),
            },
            haul: {
                label: 'Haul Rate',
                formatter: (value: number) => moneyFormatter(value),
            },
            over: {
                label: 'Overage Rate',
                formatter: (value: number) => moneyFormatter(value),
            },
            tax: {
                label: 'Taxable',
                formatter: (value: string) => (value === 'true' ? 'Yes' : 'No'),
            },
            type: {
                label: 'Pricing Type',
            },
        },
    },
    AllianceCustomer: {
        credit: {
            label: 'Account Credit',
            formatter: (value: number) => moneyFormatter(value),
        },
    },
    AllianceOrder: {
        haulerID: {
            label: 'Hauler',
            formatter: (value: any, specialFormatters: any) => [specialFormatters.haulerNameMap][value],
        },
        poNumber: {
            label: 'PO Number',
            formatter: (value: string) => (value && value !== 'null' ? value.slice(1, -1) : value),
        },
        status: {
            label: 'Status',
            formatter: (value: string) => (value ? titleCase(OrderStatusText[value.slice(1, -1) as keyof typeof OrderStatusText]) : value),
        },
        serviceLocationAddress: {
            label: 'Address',
            formatter: (value: any) => (value ? buildAddressString(JSON.parse(value)) : value),
        },
        serviceLocationCounty: {
            label: 'County',
            formatter: (value: string) => (value ? value.slice(1, -1) : value),
        },
        expectedDeliveryDate: {
            label: 'Delivery Date',
            formatter: (value: string) => (value ? value.slice(1, 11) : value),
        },
        expectedPickupDate: {
            label: 'Pickup Date',
            formatter: (value: string) => (value && value !== 'null' ? value.slice(1, 11) : value),
        },
        rentalEndDate: {
            label: 'Rental End Date',
            formatter: (value: string) => (value && value !== 'null' ? value.slice(1, 11) : value),
        },
        expirationDate: {
            label: 'Expiration Date',
            formatter: (value: string) => (value ? value.slice(1, 11) : value),
        },
        expectedSize: {
            label: 'Size',
            formatter: (value: string) => value + ' YD',
        },
        sharedPickupNotes: {
            label: 'Pickup Notes',
        },
        sharedDeliveryNotes: {
            label: 'Delivery Notes',
        },
        sharedPickupNotesPrivate: {
            label: 'Hauler Only Pickup Notes',
        },
        sharedDeliveryNotesPrivate: {
            label: 'Hauler Only Delivery Notes',
        },
        dumpTicketNumber: {
            label: 'Dump Ticket Number',
        },
        images: {
            type: {
                label: 'Image Type',
            },
            // key
            // bucket
            // region
        },
        switches: {
            fromOrder: {
                label: 'From Order',
            },
            toOrder: {
                label: 'To Order',
            },
            // id
            // type
        },
        material: {
            label: 'Material',
            formatter: (value: string) => (value ? MaterialLabels[value.slice(1, -1) as Material] : value),
        },
        weightLimit: {
            label: 'Weight Limit',
            formatter: (value: string) => (Number(value) ? Number(value) / 2000 + ' tons' : value),
        },
        actualWeightDumped: {
            label: 'Weight Dumped',
            formatter: (value: string) => (Number(value) ? Number(value) / 2000 + ' tons' : value),
        },
        adjustedRentalPeriod: {
            label: 'Rental Period',
            formatter: (value: string) => Number(value) / 24 + ' days',
        },
        extendingRental: {
            label: 'Extending Rental',
            formatter: (value: string) => (value === 'true' ? 'Yes' : 'No'),
        },
        needsAttention: {
            label: 'Needs Attention',
            formatter: (value: string) => (value === 'true' ? 'Yes' : 'No'),
        },
        needsAttentionBilling: {
            label: 'Needs Billing Attention',
            formatter: (value: string) => (value === 'true' ? 'Yes' : 'No'),
        },
        recurringOnCall: {
            label: 'Recurring On Call',
            formatter: (value: string) => (value === 'true' ? 'Yes' : 'No'),
        },
        haulerConfirmedDelivery: {
            label: 'Hauler Confirmed Delivery',
            formatter: (value: string) => (value === 'true' ? 'Yes' : 'No'),
        },
        haulerConfirmedPickup: {
            label: 'Hauler Confirmed Pickup',
            formatter: (value: string) => (value === 'true' ? 'Yes' : 'No'),
        },
        haulerDumpRate: {
            label: 'Hauler Dump Rate',
            formatter: (value: number) => moneyFormatter(value),
        },
        haulerHaulRate: {
            label: 'Hauler Haul Rate',
            formatter: (value: number) => moneyFormatter(value),
        },
        rentExtensionFee: {
            label: 'Rental Extension Fee',
            formatter: (value: string) => moneyFormatter(Number(value)),
        },
        overage: {
            label: 'Overafe Fee',
            formatter: (value: string) => moneyFormatter(Number(value)),
        },
        priceType: {
            label: 'Pricing Type',
            formatter: (value: string) => (value ? value.slice(1, -1) : value),
        },
        paymentMethod: {
            label: 'Payment Method',
        },
        paymentTerm: {
            label: 'Payment Term',
        },
        closed: {
            label: 'Closed',
            formatter: (value: string) => (value === 'true' ? 'Yes' : 'No'),
        },
    },
};

export default backendToFrontendFieldMap;
