import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Material, MaterialLabels, Pricing } from '@alliance-disposal/transport-types';
import { Button, Dialog } from '@wayste/sour-ui';
import PricingPeriod from './pricing-period';

export interface MaterialPricingHistoryDialogProps {
    open: boolean;
    zoneID: string;
    material: Material;
    onClose: () => void;
}
const MaterialPricingHistoryDialog = ({ open, zoneID, material, onClose }: MaterialPricingHistoryDialogProps) => {
    const client = useWaysteClient();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [pricingHistory, setPricingHistory] = useState<Pricing.PricingDataTransport[]>([]);

    const getMaterialPricingHistory = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const data = await client.pricing().adminPortal.pricing.fetch(zoneID, true);
            const sortedPricing = data.pricingData
                .filter((pricing) => pricing.material === material)
                .sort((a, b) => new Date(b.lastUpdatedAt).getTime() - new Date(a.lastUpdatedAt).getTime());
            setPricingHistory(sortedPricing);
        } catch (error) {
            console.error('Failed to fetch pricing history:', error);
            setError('Failed to load pricing history. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (open) {
            getMaterialPricingHistory();
        }
    }, [open]);

    return (
        <Dialog open={open} className="max-w-6xl" styledTitle={`Pricing History - ${MaterialLabels[material]}`} onClose={onClose} showX>
            {isLoading ? (
                <div className="space-y-4 p-8">
                    {/* Skeleton loading state */}
                    {[1, 2].map((i) => (
                        <div key={i} className="animate-pulse">
                            <div className="mb-4 h-4 w-1/4 rounded bg-gray-200" />
                            <div className="h-32 rounded bg-gray-100" />
                        </div>
                    ))}
                </div>
            ) : error ? (
                <div className="p-8 text-center">
                    <div className="mb-4 text-red-600">{error}</div>
                    <Button onClick={getMaterialPricingHistory} className="btn-primary">
                        Retry
                    </Button>
                </div>
            ) : (
                <div className="space-y-6 p-6">
                    {pricingHistory.map((pricing, index) => (
                        <div key={pricing.id} className="relative">
                            <PricingPeriod pricingHistory={pricingHistory} pricing={pricing} index={index} />
                        </div>
                    ))}
                </div>
            )}
        </Dialog>
    );
};

export default MaterialPricingHistoryDialog;
