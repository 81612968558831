import { useContext } from 'react';
import { useIsAppAdmin } from '@alliance-disposal/client';
import { Customer } from '@alliance-disposal/transport-types';
import { AddressSubForm } from '@wayste/sour-components';
import { Checkbox, CurrencyTextField, Tooltip } from '@wayste/sour-ui';
import { Select, SelectOption, TextField } from '@wayste/sour-ui';
import { moneyFormatter } from '@wayste/utils';
import { FaceFrownIcon, FaceSmileIcon } from '@heroicons/react/24/outline';
import { Controller, get, useFormContext } from 'react-hook-form';
import { FaceNeutralIcon } from '../../assets/icons/misc';
import { UIContext } from '../../contexts';
import { channelTypes, customerMotivations, paymentMethods, paymentMethodsEnums, paymentTerms } from '../../utils';
import { accountExecutives, accountManagers } from '../../utils/shared-types';
import { ContactSubForm } from '../ContactForm/ContactSubForm';

interface CustomerForm2FieldsProps {
    customer?: Customer.AllianceCustomerTransport;
    fieldName?: string;
    subForm?: boolean;
    onUseExistingCustomer?: (value: Customer.AllianceCustomerTransport | undefined) => void;
    disabled?: boolean;
    showSameAs?: boolean;
    hideContactSubForm?: boolean;
}

export const CustomerForm2Fields = ({
    customer,
    fieldName,
    onUseExistingCustomer,
    disabled,
    showSameAs,
    hideContactSubForm,
}: CustomerForm2FieldsProps) => {
    const { godModeActive } = useContext(UIContext);
    if (!fieldName) {
        fieldName = '';
    }
    const { isAdmin } = useIsAppAdmin();

    const methods = useFormContext();
    const {
        control,
        setValue,
        formState: { errors },
    } = methods;

    return (
        <div className="flex flex-col space-y-6">
            <div className="text-l border-b border-gray-300 pb-2">Customer</div>
            <Controller
                control={control}
                name={`${fieldName}companyName`}
                defaultValue={''}
                render={({ field, fieldState }) => (
                    <TextField
                        type={'string'}
                        label="Company name"
                        error={fieldState.error}
                        inputProps={{
                            ...field,
                            disabled: disabled,
                        }}
                    />
                )}
            />
            <Controller
                control={control}
                name={`${fieldName}notes`}
                defaultValue={''}
                render={({ field }) => (
                    <TextField
                        type={'string'}
                        label="Customer notes"
                        error={get(errors, `${fieldName}notes`)}
                        inputProps={{
                            ...field,
                            disabled: disabled,
                        }}
                    />
                )}
            />

            {customer && (
                <div className="ml-1 flex">
                    <div className="flex w-1/2 space-x-2">
                        <Controller
                            name={`${fieldName}reviewed`}
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                                <Checkbox
                                    label="Has Reviewed Us"
                                    inputProps={{ value: 'type', checked: field.value, onChange: field.onChange, disabled: disabled }}
                                />
                            )}
                        />
                    </div>

                    <div className="flex w-1/2 justify-between space-x-2">
                        <div className="block whitespace-nowrap text-gray-700">Experience</div>
                        <Controller
                            name={`${fieldName}overallExperience`}
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                                <FaceSmileIcon
                                    className={`size-6 cursor-pointer ${field.value === 'Positive' ? 'text-success' : 'text-[#D8D8D8]'}`}
                                    onClick={() => {
                                        if (!disabled) field.onChange('Positive');
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name={'overallExperience'}
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                                <FaceNeutralIcon
                                    className={`size-6 cursor-pointer ${field.value === 'Neutral' ? 'text-[#F0810F]' : 'text-[#D8D8D8]'}`}
                                    onClick={() => {
                                        if (!disabled) field.onChange('Neutral');
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name={'overallExperience'}
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                                <FaceFrownIcon
                                    className={`size-6 cursor-pointer ${field.value === 'Negative' ? 'text-delete' : 'text-[#D8D8D8]'}`}
                                    onClick={() => {
                                        if (!disabled) field.onChange('Negative');
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
            )}
            {!hideContactSubForm && (
                <div>
                    <div className="text-l mb-2 border-b border-gray-300 pb-2">Contacts</div>
                    <ContactSubForm
                        disabled={Boolean(customer) || disabled}
                        fieldName={fieldName}
                        checkForDuplicateEmail="customer"
                        duplicateEmailOnConfirm={
                            onUseExistingCustomer
                                ? (value) => {
                                      onUseExistingCustomer(value);
                                  }
                                : undefined
                        }
                    />
                </div>
            )}
            <div className="text-l border-b border-gray-300 pb-2">Billing Details</div>

            <div className="flex justify-between space-x-2">
                <div className="w-1/2 space-y-4 py-1">
                    <Controller
                        control={control}
                        name={`${fieldName}balanceLimit`}
                        defaultValue={customer?.balanceLimit || ''}
                        disabled={!isAdmin}
                        render={({ field }) => (
                            <Tooltip text={isAdmin ? '' : 'You must be an admin to edit this field'}>
                                <CurrencyTextField
                                    value={field.value}
                                    onChange={field.onChange}
                                    useCents
                                    allowZeroFormatting
                                    label="Credit Limit"
                                    inputProps={{ disabled: disabled || !isAdmin }}
                                />
                            </Tooltip>
                        )}
                    />
                    <div className="text-sm text-gray-600">Current Account Credit: {moneyFormatter(customer?.accountCredit || 0)}</div>
                </div>

                <div className="ml-2 flex w-1/2 flex-col">
                    <Controller
                        name={`${fieldName}taxExempt`}
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                            <Checkbox
                                inputProps={{ value: 'type', checked: field.value, onChange: field.onChange, disabled: disabled }}
                                label="Tax Exempt"
                                className="flex-row-reverse gap-4"
                            />
                        )}
                    />
                    <Controller
                        name={`${fieldName}doNotService`}
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                            <Checkbox
                                inputProps={{ value: 'type', checked: field.value, onChange: field.onChange, disabled: disabled }}
                                label="Do Not Service"
                                className="flex-row-reverse gap-4"
                            />
                        )}
                    />
                    <Controller
                        name={`${fieldName}inCollections`}
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                            <Checkbox
                                inputProps={{ value: 'type', checked: field.value, onChange: field.onChange, disabled: disabled }}
                                label="In Collections"
                                className="flex-row-reverse gap-4"
                            />
                        )}
                    />
                </div>
            </div>

            {showSameAs && (
                <div>
                    <Controller
                        name={`${fieldName}sameAsService`}
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                            <Checkbox
                                inputProps={{ value: 'type', checked: field.value, onChange: field.onChange, disabled: disabled }}
                                label="Billing address is same as service"
                            />
                        )}
                    />
                </div>
            )}
            <AddressSubForm
                apiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
                fieldName={`${fieldName}billingAddress`}
                disabled={disabled}
                required
            />

            <div className="flex space-x-2">
                <div className="w-1/2">
                    <Controller
                        name={`${fieldName}defaultPaymentSettings.paymentTerm`}
                        control={control}
                        render={({ field, fieldState }) => (
                            <Select
                                error={fieldState.error}
                                label="Default Payment Term"
                                required
                                onSelect={field.onChange}
                                defaultValue={''}
                                value={field.value}
                                disabled={disabled}
                            >
                                {Object.entries(paymentTerms).map((option: [string, string], index: number) => (
                                    <SelectOption key={'paymentTerms' + index} value={option[0]}>
                                        {option[1]}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                </div>
                <div className="w-1/2">
                    <Controller
                        name={`${fieldName}defaultPaymentSettings.paymentMethod`}
                        control={control}
                        render={({ field, fieldState }) => (
                            <Select
                                error={fieldState.error}
                                label="Default Payment Method"
                                required
                                onSelect={field.onChange}
                                defaultValue={''}
                                value={field.value}
                                disabled={disabled}
                            >
                                {Object.entries(paymentMethods).map((option: [string, string], index: number) => (
                                    <SelectOption key={'paymentMethods' + index} value={option[0]}>
                                        {option[1]}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                </div>
            </div>
            <div className="flex space-x-2">
                <div className="w-1/2">
                    <Controller
                        name={`${fieldName}defaultPaymentSettings.allowedPaymentMethod`}
                        control={control}
                        render={({ field }) => (
                            <Select
                                error={get(errors, `${fieldName}defaultPaymentSettings.allowedPaymentMethod`)}
                                label={'Allowed Payment Method'}
                                onSelect={field.onChange}
                                defaultValue={''}
                                value={field.value}
                                multiple
                                disabled={disabled}
                            >
                                {Object.entries(paymentMethods).map((option: [string, string], index: number) => (
                                    <SelectOption
                                        key={'allowedPaymentMethods' + index}
                                        value={option[0]}
                                        disabled={option[0] === paymentMethodsEnums.creditCard}
                                    >
                                        {option[1]}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                </div>
                <div className="w-1/2">
                    <Controller
                        name={`${fieldName}invoiceIssueTerm`}
                        control={control}
                        render={({ field }) => (
                            <Select
                                error={get(errors, `${fieldName}invoiceIssueTerm`)}
                                label={'Invoice Issue Term'}
                                onSelect={field.onChange}
                                defaultValue={''}
                                value={field.value}
                                disabled={disabled}
                            >
                                {Object.entries(Customer.InvoiceIssueTermLabels).map((option: [string, string], index: number) => (
                                    <SelectOption key={'invoiceIssueTerm' + index} value={option[0]}>
                                        {option[1]}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                </div>
            </div>
            <div className="text-l border-b border-gray-300 pb-2">Marketing Details</div>
            <div className="flex space-x-2">
                <div className="w-1/2">
                    <Controller
                        name={`${fieldName}channel`}
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Channel is required',
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <Select
                                error={fieldState.error}
                                label={'Channel'}
                                required
                                onSelect={field.onChange}
                                defaultValue={''}
                                value={field.value}
                                disabled={disabled}
                            >
                                {Object.entries(channelTypes).map((option: [string, string], index: number) => (
                                    <SelectOption key={'channel' + index} value={option[0]}>
                                        {option[1]}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                </div>
                <div className="w-1/2">
                    <Controller
                        name={`${fieldName}type`}
                        control={control}
                        render={({ field }) => (
                            <Select
                                error={get(errors, `${fieldName}customerType`)}
                                label={'Customer Type'}
                                required
                                onSelect={field.onChange}
                                defaultValue={''}
                                value={field.value}
                                disabled={disabled}
                            >
                                {Customer.customerTypes.map((option: string, index: number) => (
                                    <SelectOption key={'types' + index} value={option}>
                                        {Customer.CustomerTypeLabels[option as keyof typeof Customer.CustomerTypeLabels]}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                </div>
            </div>
            <div className="w-1/2 pr-1">
                <Controller
                    name={`${fieldName}motivation`}
                    control={control}
                    render={({ field }) => (
                        <Select
                            error={get(errors, `${fieldName}motivation`)}
                            label={'Motivation'}
                            onSelect={field.onChange}
                            defaultValue={''}
                            value={field.value}
                            disabled={disabled}
                        >
                            {Object.entries(customerMotivations).map((option: [string, string], index: number) => (
                                <SelectOption key={'motivation' + index} value={option[0]}>
                                    {option[1]}
                                </SelectOption>
                            ))}
                        </Select>
                    )}
                />
            </div>
            <div className="text-l border-b border-gray-300 pb-2">Sales Details</div>
            <div className="grid grid-cols-2 gap-2">
                <div>
                    <Controller
                        name={`${fieldName}accountRepID`}
                        control={control}
                        render={({ field, fieldState }) => (
                            <Select
                                error={fieldState.error}
                                label={'Account Executive'}
                                onSelect={(value) => {
                                    field.onChange(value);
                                    if (value) {
                                        const profile = accountExecutives.find((profile) => profile.id === value);
                                        if (profile) {
                                            setValue(`${fieldName}accountRepName`, profile.firstName + ' ' + profile.lastName);
                                        }
                                    }
                                }}
                                defaultValue={''}
                                disabled={disabled || (customer && field.value && !godModeActive)}
                                value={field.value || ''}
                                renderLabel={(value) => {
                                    // if the value is blank, and not dirty, then return null
                                    if (!value && !fieldState.isTouched) return null;

                                    const profile = accountExecutives.find((profile) => profile.id === value);
                                    return profile ? profile.firstName + ' ' + profile.lastName : 'None';
                                }}
                            >
                                <SelectOption key={'accountRepID-blank'} value={''}>
                                    None
                                </SelectOption>
                                {accountExecutives.map((profile) => (
                                    <SelectOption key={'accountRepID' + profile.id} value={profile.id}>
                                        {profile.firstName + ' ' + profile.lastName}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                </div>
                <div className="">
                    <Controller
                        name={`${fieldName}accountManagerID`}
                        control={control}
                        render={({ field, fieldState }) => (
                            <Select
                                error={fieldState.error}
                                label="Account Manager"
                                onSelect={(value) => {
                                    field.onChange(value);
                                    if (value) {
                                        const profile = accountManagers.find((profile) => profile.id === value);
                                        if (profile) {
                                            setValue(`${fieldName}accountManagerName`, profile.firstName + ' ' + profile.lastName);
                                        }
                                    }
                                }}
                                defaultValue={''}
                                disabled={disabled}
                                value={field.value}
                                renderLabel={(value) => {
                                    if (!value && !fieldState.isTouched) return null;
                                    const profile = accountManagers.find((profile) => profile.id === value);
                                    return profile ? profile.firstName + ' ' + profile.lastName : 'None';
                                }}
                            >
                                <SelectOption key={'accountManagerID-blank'} value={''}>
                                    None
                                </SelectOption>
                                {accountManagers.map((profile) => (
                                    <SelectOption key={'accountManagerID' + profile.id} value={profile.id}>
                                        {profile.firstName + ' ' + profile.lastName}
                                    </SelectOption>
                                ))}
                            </Select>
                        )}
                    />
                </div>
            </div>
            {customer && godModeActive && (
                <div className="mt-2">
                    <div className="text-l mb-4 border-b border-gray-300">God Mode Fields</div>
                    <Controller
                        control={control}
                        name={`${fieldName}stripeId`}
                        defaultValue={''}
                        render={({ field }) => (
                            <TextField
                                type={'string'}
                                label="DANGER STRIPE ID CHANGE"
                                error={get(errors, `${fieldName}stripeId`)}
                                helperText="Make sure in Stripe Metadata has source sourgum"
                                inputProps={{
                                    ...field,
                                }}
                            />
                        )}
                    />
                </div>
            )}
        </div>
    );
};
