import { V1 as sourgumPricingFunctions } from '@alliance-disposal/pricing';
import { Pricing } from '@alliance-disposal/transport-types';
import { moneyFormatter } from '@wayste/utils';
import { ArrowUpIcon } from '@heroicons/react/20/solid';
import { format, parseISO } from 'date-fns';
import PriceChangeIndicator from './price-change-indicator';

interface PricingPeriodProps {
    pricingHistory: Pricing.PricingDataTransport[];
    pricing: Pricing.PricingDataTransport;
    index: number;
}

const PricingPeriod = ({ pricingHistory, pricing, index }: PricingPeriodProps) => {
    const previousPricing = pricingHistory[index + 1];
    const isLatest = index === 0;

    const calculatePricing = (size: Pricing.PricingSizeDataTransport, pricing: Pricing.PricingDataTransport) => {
        const basePrice = sourgumPricingFunctions.quotedPriceBuild(
            pricing.type === 'yard' ? Number(size.size) : Number(size.tonLimit),
            Number(size.dump),
            Number(size.haul),
            0,
            0,
            false,
            0,
        );
        return basePrice.total;
    };

    return (
        <div className="relative">
            {/* Timeline connector */}
            {!isLatest && <ArrowUpIcon className="absolute -top-6 left-1/2 size-6 -translate-x-1/2 text-blue-400" />}

            <div className={`rounded-lg border p-6 ${isLatest ? 'border-blue-200 bg-blue-50' : 'border-gray-200 bg-gray-50'} `}>
                {/* Header Section */}
                <div className="mb-4 flex items-center justify-between">
                    <div className="flex items-center space-x-4">
                        <div className="font-semibold">
                            {pricing.effectiveDate ? format(parseISO(pricing.effectiveDate), 'MMMM d, yyyy') : 'No Effective Date'}
                        </div>
                        <div className="text-sm text-gray-500">Updated {format(new Date(pricing.lastUpdatedAt), 'MMM d, yyyy h:mm a')}</div>
                        {pricing.expirationDate && (
                            <div className="text-sm text-gray-500">Expires {format(parseISO(pricing.expirationDate), 'MMM d, yyyy')}</div>
                        )}
                    </div>
                    <div className="flex gap-2">
                        <div className="rounded-full bg-blue-100 px-2 py-1 text-xs text-blue-800">Type: {pricing.type}</div>
                    </div>
                </div>

                {pricing.sizes.length === 0 ? (
                    <div className="rounded-lg bg-gray-50 p-4 text-center text-gray-600">No sizes configured for this pricing period.</div>
                ) : (
                    <div className="overflow-x-auto rounded-lg border border-gray-200">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="w-40 px-6 py-3 text-center text-xs uppercase tracking-wider text-gray-500">
                                        Size (yards)
                                    </th>
                                    {pricing.type === 'ton' && (
                                        <th className="w-40 px-6 py-3 text-center text-xs uppercase tracking-wider text-gray-500">
                                            Ton Limit
                                        </th>
                                    )}
                                    <th className="w-48 px-6 py-3 text-center text-xs uppercase tracking-wider text-gray-500">Haul Fee</th>
                                    {pricing.type !== 'flat' && (
                                        <th className="w-48 px-6 py-3 text-center text-xs uppercase tracking-wider text-gray-500">
                                            Dump Fee
                                        </th>
                                    )}
                                    <th className="px-6 py-3 text-right text-xs uppercase tracking-wider text-gray-500">Total</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {pricing.sizes.map((size) => {
                                    const previousSize = previousPricing?.sizes.find((s) => s.size === size.size);
                                    const isNewSize = !previousSize;
                                    const subtotal = calculatePricing(size, pricing);
                                    const previousSubtotal = previousSize ? calculatePricing(previousSize, previousPricing) : undefined;

                                    return (
                                        <tr key={size.size}>
                                            <td className="px-6 py-4">
                                                <div className="flex h-12 flex-col items-center">
                                                    <div>{size.size}</div>
                                                    {isNewSize && (
                                                        <div className="mt-1 inline-flex rounded-full bg-blue-100 px-2 py-0.5 text-xs text-blue-800">
                                                            New Size
                                                        </div>
                                                    )}
                                                </div>
                                            </td>
                                            {pricing.type === 'ton' && (
                                                <td className="px-6 py-4">
                                                    <div className="flex h-12 flex-col items-center">
                                                        <div>{size.tonLimit}</div>
                                                        <PriceChangeIndicator
                                                            current={size.tonLimit}
                                                            previous={previousSize?.tonLimit}
                                                            isCurrency={false}
                                                        />
                                                    </div>
                                                </td>
                                            )}
                                            <td className="px-6 py-4">
                                                <div className="flex h-12 flex-col items-center">
                                                    <div>{moneyFormatter(size.haul)}</div>
                                                    <PriceChangeIndicator current={size.haul} previous={previousSize?.haul} />
                                                </div>
                                            </td>
                                            {pricing.type !== 'flat' && (
                                                <td className="px-6 py-4">
                                                    <div className="flex h-12 flex-col items-center">
                                                        <div>{moneyFormatter(size.dump)}</div>
                                                        <PriceChangeIndicator current={size.dump} previous={previousSize?.dump} />
                                                    </div>
                                                </td>
                                            )}
                                            <td className="px-6 py-4">
                                                <div className="flex h-12 flex-col items-end font-semibold">
                                                    <div>{moneyFormatter(subtotal)}</div>
                                                    <PriceChangeIndicator current={subtotal} previous={previousSubtotal} />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PricingPeriod;
