//////////////////////////////////////////////
// MANAGE ORDER IMAGES UPLOAD, DELETE, VIEW
//////////////////////////////////////////////
import { useEffect, useRef, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { S3ItemReference } from '@alliance-disposal/transport-types';
import { AllianceOrderTransport } from '@alliance-disposal/transport-types/dist/order';
import { FileDropZone } from '@wayste/sour-components';
import { useSourContext } from '@wayste/sour-context';
import { Button, Dialog, Loading, Menu, MenuItem, Tooltip } from '@wayste/sour-ui';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { ArrowDownTrayIcon, ArrowPathRoundedSquareIcon, PhotoIcon, TrashIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { orderImageTypes } from '../utils';

type Props = {
    order: AllianceOrderTransport;
    inline?: boolean;
    open?: boolean;
    showUploadDropZones?: boolean;
    onCancel?: () => void;
    onDumpTicketUpload?: (imageKey: string) => void;
};

type ExtendedS3ItemReference = S3ItemReference & {
    blob?: Blob;
    href: string;
    localUrl?: string;
};

const OrderImageHandler = ({ order, inline, open, onCancel, showUploadDropZones, onDumpTicketUpload }: Props) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();

    //////////////////////////////////////////////
    // STATE
    //////////////////////////////////////////////
    const [isLoading, setIsLoading] = useState(false);
    const [allFiles, setAllFiles] = useState<ExtendedS3ItemReference[]>([]);
    const [zoomFile, setZoomFile] = useState<string | null>(null);

    const [rotate, setRotate] = useState<{
        fileName: string | null;
        deg: number;
    }>({ fileName: null, deg: 0 });

    const [currentImageType, setCurrentImageType] = useState<string | null>();
    const fileInputRef = useRef<HTMLInputElement>(null);

    //////////////////////////////////////////////
    // HOOKS SECTION
    //////////////////////////////////////////////

    useEffect(() => {
        getImages();
    }, [order.images]);

    //////////////////////////////////////////////
    // FUNCTIONS SECTION
    //////////////////////////////////////////////

    const getImages = async () => {
        setIsLoading(true);
        try {
            // we have order.images, where image is { bucket, key, region, string }
            // are we trying to fetch by
            const images = order.images;
            const folderName = 'order' + (order.orderNumber?.toString() ?? '') + '/';

            const res = await client.upload().adminPortal.image.fetchAll(folderName, images);

            const parseFileTypeFromExtension = (fileName: string) => {
                const extension = fileName.split('.').pop()?.toLowerCase() || '';
                switch (extension) {
                    case 'pdf':
                        return 'application/pdf';
                    case 'jpg': // fallthrough intended
                    case 'jpeg':
                        return 'image/jpeg';
                    case 'png':
                        return 'image/png';
                    case 'gif':
                        return 'image/gif';
                    case 'svg':
                        return 'image/svg+xml';
                    case 'webp':
                        return 'image/webp';
                    case 'tiff': // fallthrough intended
                    case 'tif':
                        return 'image/tiff';
                }

                return 'application/octet-stream';
            };

            const withBlobs = res.map(async (S3WithURL) => {
                if (!S3WithURL.href || !S3WithURL.key) return;
                try {
                    const blob = await axios.get<Blob>(S3WithURL.href, {
                        responseType: 'blob',
                    });

                    // convert the blob to a file
                    return {
                        ...S3WithURL,
                        blob: new File([blob.data], S3WithURL.key.split('/')?.pop() || 'file', {
                            type: parseFileTypeFromExtension(S3WithURL.key),
                        }),
                    };
                } catch {
                    return S3WithURL;
                }
            });

            const createObjectUrl = (file: any) => {
                try {
                    return window.URL.createObjectURL(file.blob);
                } catch (error) {
                    console.warn('KEY error: ', error);
                    return undefined;
                }
            };

            try {
                const newFiles = await Promise.all(withBlobs);
                const withLocalUrl = newFiles.map((item: any) => {
                    const localUrl = createObjectUrl(item);
                    return {
                        ...item,
                        localUrl,
                    };
                });
                setAllFiles(withLocalUrl);
            } catch (error) {
                console.warn('Errors in Promise.all: ', error);
                setAllFiles([]);
            }

            setIsLoading(false);

            //return await Promise.all(withBlobs);
            return;
        } catch (error) {
            console.warn('S3 Get Image Error: ', error);
            setIsLoading(false);
        }
    };

    const handleFileUpload = async (files: FileList | null, imageType: keyof typeof orderImageTypes) => {
        setIsLoading(true);
        if (!files) return;
        const file = files[0];

        if (!file) return;

        const folderName = 'order' + (order.orderNumber?.toString() ?? '') + '/';
        const ext = file.name.split('.').pop();
        const key = `${folderName}/${uuidv4()}.${ext}`;

        try {
            const response = await client.upload().adminPortal.image.upload(key, file);
            const s3Object: S3ItemReference = {
                bucket: response.bucket,
                key: response.key,
                region: response.region,
                type: imageType || 'other',
            };

            // If this is a dump ticket and we have a callback, trigger it
            if (imageType === 'dumpTicket') {
                await client.order().adminPortal.ocr.upload(order.id, s3Object);
                onDumpTicketUpload?.(response.key);
            } else {
                await client.order().adminPortal.image.create(order.id, s3Object);
            }

            getImages();
            setIsLoading(false);
        } catch (error) {
            console.error('uploadS3FilesToFolder Error:', error);
            setShowToast({
                message: 'Failed to upload image',
                severity: 'error',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const deleteFileHandler = async (
        file: S3ItemReference & {
            blob?: Blob;
            href: string;
        },
    ) => {
        try {
            setIsLoading(true);
            const payload = order.images.find((item: S3ItemReference) => item.key === file.key);

            if (!payload) return;

            const res = await client.order().adminPortal.deleteOrderImage(order.id, payload);

            if (res.status === 200) {
                setIsLoading(false);
                const newArray = allFiles.filter((item) => item.key !== file.key);
                setAllFiles(newArray);
                return;
            }
        } catch (error) {
            setIsLoading(false);
            console.warn('error: ', error);
            console.warn('deleteS3FileFromFolder Error: ', error);
        }
    };

    const handleRotate = (fileName: string) => {
        if (rotate.fileName === fileName) {
            const newDeg = rotate.deg >= 360 ? 0 : rotate.deg + 90;
            setRotate({ fileName: fileName, deg: newDeg });
        } else {
            setRotate({ fileName, deg: 90 });
        }
    };

    const zoomHandler = (fileName: string) => {
        if (zoomFile === fileName) {
            setZoomFile(null);
        } else {
            setZoomFile(fileName);
        }
    };

    const toggleImageVisibility = async (file: S3ItemReference) => {
        const payload = order.images.find((image) => {
            return image.key === file.key;
        });

        if (!payload) return;

        payload.visibleToCustomer = !(payload.visibleToCustomer ?? false);

        try {
            await client.order().adminPortal.image.update(order.id, payload);
            getImages();
        } catch (error) {
            setShowToast({
                message: 'Error updating image visibility',
                severity: 'warning',
            });
            console.warn('toggleImageVisibility Error: ', error);
        }
    };

    //////////////////////////////////////////////
    // RENDER SECTION
    //////////////////////////////////////////////

    const body = () => {
        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                    <div className="grid grid-cols-2 gap-2">
                        {allFiles.map((file, index) => (
                            <div
                                key={file.key}
                                className="relative rounded border border-gray-300"
                                style={{
                                    minHeight: 505,
                                    minWidth: 505,
                                    maxHeight: 505,
                                    maxWidth: 505,
                                    marginRight: allFiles.length - 1 !== index ? 20 : 0,
                                }}
                            >
                                <div className="absolute right-2 top-2 z-10 flex gap-2">
                                    <TrashIcon
                                        className="h-6 w-6 rounded bg-black text-white opacity-70"
                                        onClick={() => deleteFileHandler(file)}
                                    />
                                    {/* for dump ticket clean show the eye to make visable to customer */}
                                    {file.type === 'dumpTicketClean' &&
                                        (file.visibleToCustomer ? (
                                            <Tooltip text="Visible to Customer. Click to hide.">
                                                <Button onClick={() => toggleImageVisibility(file)}>
                                                    <EyeSlashIcon className="h-6 w-6 rounded bg-black text-white opacity-70" />
                                                </Button>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip text="Hidden from Customer. Click to show.">
                                                <Button onClick={() => toggleImageVisibility(file)}>
                                                    <EyeIcon className="h-6 w-6 rounded bg-black text-white opacity-70" />
                                                </Button>
                                            </Tooltip>
                                        ))}
                                    <a
                                        href={file.localUrl}
                                        download
                                        style={{
                                            height: 24,
                                            width: 24,
                                            zIndex: 3,
                                        }}
                                    >
                                        <ArrowDownTrayIcon className="rounded bg-black text-white opacity-70" />
                                    </a>
                                    <ArrowPathRoundedSquareIcon
                                        className="h-6 w-6 rounded bg-black text-white opacity-70"
                                        onClick={() => handleRotate(file.key)}
                                    />
                                </div>
                                {file?.blob?.type === 'application/pdf' ? (
                                    <object style={{ width: '100%', height: '100%' }} data={file.localUrl} type="application/pdf">
                                        <param name="width" value="100%" />
                                    </object>
                                ) : file.key ? (
                                    <img
                                        src={file.localUrl}
                                        style={{
                                            cursor: zoomFile === file.key ? 'zoom-out' : 'zoom-in',
                                            height: zoomFile === file.key ? 600 : 300,
                                            width: zoomFile === file.key ? 600 : 300,
                                            transform: rotate.fileName === file.key ? `rotate(${rotate.deg}deg)` : 'rotate(0deg)',
                                        }}
                                        alt="Error With SRC"
                                        onClick={() => zoomHandler(file.key)}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        ))}
                    </div>
                    {isLoading && (
                        <div className="flex w-full items-center justify-center">
                            <Loading />
                        </div>
                    )}
                </div>
                {/* File Drop Zone */}

                {showUploadDropZones && (
                    <div className="mt-2 grid grid-cols-2 gap-2">
                        <FileDropZone
                            handleFileUpload={(files) => {
                                handleFileUpload(files, 'haulerPayable');
                            }}
                            text="Invoice from Hauler"
                        />
                        <FileDropZone
                            handleFileUpload={(files) => {
                                handleFileUpload(files, 'dumpTicket');
                            }}
                            text="Dump Ticket from Hauler"
                        />
                    </div>
                )}
                <div className="mt-4 flex border-t p-2">
                    <input
                        ref={fileInputRef}
                        className="blankFileInput"
                        type="file"
                        onChange={(e) => {
                            handleFileUpload(e.target.files, currentImageType as keyof typeof orderImageTypes);
                        }}
                        disabled={isLoading}
                        style={{ display: 'none' }} // hide the input
                    />
                    <Menu
                        className="flex w-full justify-center"
                        button={
                            <div className="relative flex w-full flex-1 cursor-pointer items-center justify-center rounded border border-gray-300 p-2.5 text-center">
                                <PhotoIcon className={`mr-2 h-6 w-6 ${isLoading ? 'text-black' : 'text-red-500'}`} />
                                <div>Upload Image</div>
                            </div>
                        }
                    >
                        {Object.keys(orderImageTypes).map((imageType) => (
                            <MenuItem
                                className="relative block px-4 py-2 text-sm hover:bg-slate-50"
                                key={imageType}
                                onClick={() => {
                                    setCurrentImageType(imageType);
                                    fileInputRef.current?.click();
                                }}
                            >
                                {orderImageTypes[imageType as keyof typeof orderImageTypes]}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            </>
        );
    };

    if (inline) return <div>{body()}</div>;

    return (
        <Dialog
            open={open ?? false}
            className="max-w-screen-2xl"
            onClose={() => (onCancel ? onCancel() : null)}
            styledTitle="Order Images"
            showX
        >
            {body()}
        </Dialog>
    );
};

export default OrderImageHandler;
