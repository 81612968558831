import { useState } from 'react';
import { Customer } from '@alliance-disposal/transport-types';
import { CustomerTypeLabels } from '@alliance-disposal/transport-types/dist/customer';
import { Button } from '@wayste/sour-ui';
import { formatServiceAddress, moneyFormatter } from '@wayste/utils';
import { PencilIcon } from '@heroicons/react/20/solid';
import { FaceFrownIcon, FaceSmileIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { FaceNeutralIcon } from '../../assets/icons/misc';
import CustomerAccountCreditDialog from '../../pages/customers/customer-account-credit/customer-account-credit-dialog';
import { paymentMethods, paymentTerms } from '../../utils';
import { channelTypes, commercialLocationTypes, customerMotivations } from '../../utils/shared-types';
import CardInfoList from '../CardInfoList';

interface CustomerDetailsListsProps {
    customer: Customer.AllianceCustomerTransport;
}
const CustomerDetailsLists = ({ customer: initialCustomer }: CustomerDetailsListsProps) => {
    const [openAccountCredit, setOpenAccountCredit] = useState(false);
    const [customer, setCustomer] = useState(initialCustomer);

    const colOne = [
        { label: 'Account #', value: customer.customerNumber },
        { label: 'Company', value: customer.companyName },
        {
            label: 'Customer Type',
            value: customer.type && CustomerTypeLabels[customer.type as keyof typeof CustomerTypeLabels],
        },
        {
            label: 'Business Type',
            value: customer.businessType && commercialLocationTypes[customer.businessType as keyof typeof commercialLocationTypes],
        },
        {
            label: 'Motivation',
            value: customer.motivation && customerMotivations[customer.motivation as keyof typeof customerMotivations],
        },
        { label: 'Account Manager', value: customer.accountManagerName },
        { label: 'Account Executive', value: customer.accountRepName },
        {
            label: 'Experience',
            value:
                customer.overallExperience === 'Positive' ? (
                    <FaceSmileIcon className="text-success size-6" />
                ) : customer.overallExperience === 'Negative' ? (
                    <FaceFrownIcon className="text-delete size-6" />
                ) : (
                    <FaceNeutralIcon className="size-6 text-[#F0810F]" />
                ),
        },
        { label: 'Reviewed Us', value: customer.reviewed ? 'Yes' : 'No' },

        { label: 'Customer Notes', value: customer.notes },
    ];

    const colTwo = [
        { label: 'Account Balance', value: moneyFormatter(customer.balance || 0) },
        {
            label: 'Account Credit Limit',
            value: moneyFormatter(customer.balanceLimit, { undefinedBehavior: 'None' }),
        },
        {
            label: 'Account Credit',
            value: (
                <Button
                    className="btn-secondary-text-only btn-dense"
                    endIcon={<PencilIcon className="size-4" />}
                    onClick={() => setOpenAccountCredit(true)}
                >
                    {moneyFormatter(customer?.accountCredit)}
                </Button>
            ),
        },
        { label: 'Billing Address', value: formatServiceAddress(customer.billingAddress) },
        { label: 'Tax Exempt', value: customer.taxExempt ? 'Yes' : 'No' },
        {
            label: 'Default Payment Settings',
            value: `${paymentTerms[customer.defaultPaymentSettings?.paymentTerm as keyof typeof paymentTerms]} ${
                paymentMethods[customer.defaultPaymentSettings?.paymentMethod as keyof typeof paymentMethods]
            }`,
        },
        {
            label: 'Allowed Payment Methods',
            value: customer.defaultPaymentSettings?.allowedPaymentMethod
                ?.map((item) => paymentMethods[item as keyof typeof paymentMethods])
                .join(', '),
        },
        {
            label: 'Marketing Channel',
            value: customer.channel
                ? channelTypes[customer.channel as keyof typeof channelTypes]
                    ? channelTypes[customer.channel as keyof typeof channelTypes]
                    : customer.channel
                : '',
        },
        {
            label: 'Created',
            value: format(new Date(customer.metadata?.createdAt || Date.now()), 'MM/dd/yy hh:mm aaa'),
        },
    ];

    return (
        <>
            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                <CardInfoList data={colOne} border />
                <CardInfoList data={colTwo} />
            </div>
            {/* Modals */}
            <CustomerAccountCreditDialog
                open={openAccountCredit}
                onClose={() => setOpenAccountCredit(false)}
                customerId={customer.id}
                currentAccountCredit={customer.accountCredit}
                onCreditUpdate={(newAmount: number) => {
                    setCustomer((prev) => ({
                        ...prev,
                        accountCredit: prev.accountCredit + newAmount,
                    }));
                }}
            />
        </>
    );
};

export default CustomerDetailsLists;
