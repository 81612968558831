import React, { useState } from 'react';
import { InternalTicket } from '@alliance-disposal/transport-types';
import { TaskBoard } from '@wayste/sour-components';
import { DetailsDrawer } from './DetailsDrawer';

const InternalTicketWorkflow = () => {
    const [selectedTask, setSelectedTask] = useState<InternalTicket.WorkflowTaskTransport | null>(null);

    const handleTaskClick = (task: InternalTicket.WorkflowTaskTransport) => {
        setSelectedTask(task);
    };

    return (
        <div className="flex h-full flex-col">
            <TaskBoard onTaskClick={handleTaskClick} />
            {selectedTask && <DetailsDrawer isOpen={!!selectedTask} onClose={() => setSelectedTask(null)} task={selectedTask} />}
        </div>
    );
};

export default InternalTicketWorkflow;
