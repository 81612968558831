import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Invoice, Notification, Order } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Checkbox, Dialog, Loading, TextField, Textarea, Toggle, Tooltip } from '@wayste/sour-ui';
import { formatEmailDestination } from '@wayste/utils';
import { blobToBase64, fileToBase64, generateReviewLink } from '@wayste/utils';
import { ChevronLeftIcon, ChevronRightIcon, PaperClipIcon, TrashIcon } from '@heroicons/react/24/solid';
import { isAxiosError } from 'axios';
import { getCustomerCCAddresses, getCustomerToAndCCEmails } from '../../../utils/email-utils';
import { priceTypesEnums } from '../../../utils/shared-types';
import { reviewStateLinks } from '../../../utils/shared-types';

interface InvoicePDFBundle {
    blob: Blob;
    invoice: Invoice.ReceivableTransport;
}

interface InvoiceSendProps {
    order: Order.AllianceOrderTransport;
    customer: Customer.AllianceCustomerTransport;
    open: boolean;
    onCancel: () => void;
    receivable: Invoice.ReceivableTransport;
    receivables: Invoice.ReceivableTransport[];
    onSend: (isInvoice: boolean) => void;
}

const InvoiceSend = ({ order, customer, onSend, open, onCancel, receivable: invoice, receivables: receivables }: InvoiceSendProps) => {
    const invoiceContainer = document.getElementById('container');
    const client = useWaysteClient();
    const { useConfirmationDialog, setShowToast } = useSourContext();
    const { getConfirmation } = useConfirmationDialog();

    // STATE
    const [containerHeight, setContainerHeight] = useState<number>(0);
    const [images, setImages] = useState<File[]>([]);
    const [customerEmail, setCustomerEmail] = useState<string>();
    const [editEmail, setEditEmail] = useState<boolean>(false);
    const [askReview, setAskReview] = useState<boolean>(false);
    const [emailText, setEmailText] = useState<string>('');
    const [isInvoice, setIsInvoice] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [activeImageStep, setActiveImageStep] = useState<number>(0);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [attachAllInvoices, setAttachAllInvoices] = useState<boolean>(false);

    const [pdf, setPdf] = useState<Blob>();
    const [additionalPDFs, setAdditionalPDFs] = useState<InvoicePDFBundle[]>([]);
    const [downloading, setDownloading] = useState<boolean>(false);

    //////////////////////////////////////////////////
    // FUNCTIONS SECTION
    //////////////////////////////////////////////////

    const ccEmails = getCustomerCCAddresses(customerEmail || '', customer, order.serviceLocation.address).billingCCAddresses.filter(
        (email) => {
            return email !== customerEmail;
        },
    );

    // FETCH ADDITIONAL PDF'S FOR ATTACHMENT WHEN ATTACH ALL INVOICES IS TRUE
    const fetchAdditionalPDFs = async (): Promise<void> => {
        setDownloading(true);

        let otherReceivables = receivables.filter((receivable) => receivable.id !== invoice.id);
        otherReceivables = otherReceivables.filter(
            (receivable) => receivable.invoiceDetails.status !== 'DRAFT' && !receivable.invoiceDetails.void,
        );

        try {
            const PDFs = await Promise.all(
                otherReceivables.map(async (receivable) => {
                    const response = await client.invoice().adminPortal.receivable.pdf.fetch(receivable.id);
                    const blob = new Blob([response], { type: 'application/pdf' });
                    return {
                        blob: blob,
                        invoice: receivable,
                    };
                }),
            );
            setAdditionalPDFs(PDFs);
        } catch (error) {
            console.error(error);
            setShowToast({
                severity: 'warning',
                message: 'Something went wrong loading the PDFs. Please try again.',
            });
        } finally {
            setDownloading(false);
        }
    };

    // VERIFIES CUSTOMER EXPERIENCE IS POSITIVE MODAL
    const verifyCustomerExperience = async () => {
        setTimeout(async () => {
            // Check if we should ask for a review:
            // - Must be a receipt (not an invoice)
            // - Order must be completed
            // - Customer had positive experience
            // - Customer hasn't reviewed yet
            if (!isInvoice && order.status === 'COMPLETED' && customer.overallExperience === 'Positive' && !customer.reviewed) {
                const confirmed = await getConfirmation({
                    title: 'Verify User Experience',
                    message:
                        'Are you sure the customer had a good experience? If so click CONFIRM otherwise click CANCEL and update the customer experience via Update Customer Details.',
                });
                if (!confirmed) onCancel();
                setAskReview(true);
            }
        }, 1000);
    };

    // HANDLES IMAGE UPLOAD
    const handleImageUpload = (file: File) => {
        const imagesCopy = [...images, file];
        setImages(imagesCopy);
    };

    // DELETES IMAGE FROM IMAGES ARRAY
    const handleImageDelete = (index: number) => {
        const imagesCopy = [...images];
        setImages(imagesCopy.filter((item, i) => i !== index));
        setActiveImageStep(0);
    };

    // PREPARES IMAGES FOR EMAIL
    const prepareImage = async (image: File) => {
        try {
            return await fileToBase64(image);
        } catch (error) {
            console.error('There was an error compressing the file', error);
            setShowToast({
                severity: 'warning',
                message: 'There was an error compressing the file',
            });
        }
    };

    // PREPARES ATTACHMENTS FOR EMAIL
    const prepareAttachments = async () => {
        if (!pdf) {
            setShowToast({
                severity: 'warning',
                message: 'Something went wrong loading the PDF. Please try again.',
            });
            return;
        }
        const currentInvoicePDF = ((await blobToBase64(pdf)) as string).split(',')[1];

        if (!currentInvoicePDF) {
            setShowToast({
                severity: 'warning',
                message: 'Something went wrong loading the PDF. Please try again.',
            });
            return;
        }

        const attachments = [
            {
                content: currentInvoicePDF,
                type: 'application/pdf',
                filename: `${!invoice.invoiceDetails.paidInFull ? 'Invoice' : 'Receipt'} ${order.orderNumber} - ${
                    invoice.invoiceDetails.invoiceNumber
                }.pdf`,
                disposition: 'attachment',
            },
        ];

        // Attach additional invoices if attachAllInvoices is true
        if (attachAllInvoices == true && additionalPDFs.length > 0) {
            await (async () => {
                try {
                    await Promise.all(
                        additionalPDFs.map(async (pdf) => {
                            const blob = ((await blobToBase64(pdf.blob)) as string).split(',')[1];
                            attachments.push({
                                content: blob,
                                type: 'application/pdf',
                                filename: `Invoice ${order.orderNumber} - ${pdf.invoice.invoiceDetails.invoiceNumber}.pdf`,
                                disposition: 'attachment',
                            });
                        }),
                    );
                } catch (error) {
                    setShowToast({
                        severity: 'warning',
                        message: 'Something went wrong sending additional PDFs. Please try again.',
                    });
                    return;
                }
            })();
        }

        // Attach images
        if (images.length > 0) {
            for (const image of images) {
                const attachment = await prepareImage(image);
                if (attachment) {
                    attachments.push({
                        content: attachment,
                        type: image.type,
                        filename: image.name,
                        disposition: 'attachment',
                    });
                } else {
                    setShowToast({
                        severity: 'warning',
                        message: 'Something went wrong sending images. Please try again.',
                    });
                    return;
                }
            }
        }

        // get total size of attachments
        const totalSize = attachments.reduce((acc, attachment) => {
            return acc + attachment.content.length;
        }, 1000000);

        // if total size is greater than 6MB, throw error
        if (totalSize > 6000000) {
            setShowToast({
                severity: 'warning',
                message: 'Attachments are too large. Please reduce the size of your attachments and try again.',
            });
            throw new Error(
                `Attachments are too large. Please reduce the size of your attachments and try again. ${(totalSize / 1000000).toFixed(
                    2,
                )}MB. Email size limit is 6MB.`,
            );
        }

        return attachments;
    };

    // GENERATES PAYMENT LINK
    const generatePaymentLink = async () => {
        // Check if every receivable is paid in full
        if (receivables.every((receivable) => receivable.invoiceDetails.paidInFull)) {
            return null;
        }

        // Find the receivable with the greatest remaining balance
        let maxRemainingBalance = 0;
        let receivableWithMaxBalance: Invoice.ReceivableTransport | null = null;

        let nonVoidedReceivables = receivables.filter(
            (receivable) => receivable.invoiceDetails.status !== 'VOID' && receivable.invoiceDetails.status !== 'DRAFT',
        );

        // Add the current invoice to the list of receivables to consider it for highest remaining balance
        nonVoidedReceivables = [...nonVoidedReceivables, invoice];

        nonVoidedReceivables.forEach((receivable) => {
            if (receivable.invoiceDetails.remainingBalance > maxRemainingBalance) {
                maxRemainingBalance = receivable.invoiceDetails.remainingBalance;
                receivableWithMaxBalance = receivable;
            }
        });

        if (receivableWithMaxBalance !== null) {
            const response = await client.invoice().adminPortal.receivable.customerAccessToken.create({
                // @ts-expect-error typescript is being dumb here how is this never if im checking for it above
                receivableID: receivableWithMaxBalance?.id,
            });

            return response.key;
        }

        return null;
    };

    // HANDLES SENDING THE EMAIL GENERATING THE PAYMENT LINK, REVIEW LINK, AND ATTACHMENTS
    const handleSend = async () => {
        if (!customerEmail) {
            alert('Email not sent, please enter a valid email address');
            return;
        }

        try {
            setIsLoading(true);
            // If the invoice doesn't have an issue date, set it to the current date
            if (!invoice.invoiceDetails.issueDate) {
                await client.invoice().adminPortal.receivable.update(invoice.id, {
                    invoiceDetails: {
                        issueDate: new Date().toISOString(),
                    },
                });
            }

            // If we are attaching all invoices, add an issue date to all invoices
            if (attachAllInvoices == true) {
                const updates = receivables
                    .filter((receivable) => !receivable.invoiceDetails.issueDate)
                    .map(async (receivable) => {
                        await client.invoice().adminPortal.receivable.update(receivable.id, {
                            invoiceDetails: {
                                issueDate: new Date().toISOString(),
                            },
                        });
                    });

                await Promise.all(updates);
            }

            const attachments = await prepareAttachments();

            const payment_link = await generatePaymentLink();

            const review_link = generateReviewLink(customer.billingAddress, reviewStateLinks);

            let body:
                | Notification.SendGrid.InvoiceIntermediary
                | Notification.SendGrid.InvoiceReceipt
                | Notification.SendGrid.InvoiceReceiptNeedsReview;
            let topic = '';

            const contactEmail = getCustomerToAndCCEmails('billing', customer);

            // For completed orders, send invoice-receipt email with order details and optional review link
            // For in-progress orders, send invoice-intermediary email with basic invoice info
            // Body includes customer name, order/invoice numbers, payment link, and custom message if applicable
            // If the toggle to send the review link is on, and the customer has had a positive experience and hasn't reviewed yet, include the review link
            if (order.status === 'COMPLETED') {
                topic = 'invoice-receipt';
                body = {
                    first_name: contactEmail.toContact.firstName || '',
                    receipt_invoice: isInvoice ? 'Invoice' : 'Receipt',
                    order_number: order.orderNumber?.toString() || '',
                    invoice_number: `${order.orderNumber} - ${invoice.invoiceDetails.invoiceNumber}`,
                    over_weight_limit_by:
                        order.weightLimit?.value && (order.actualWeightDumped?.value || 0) > order.weightLimit?.value
                            ? (Number(order.actualWeightDumped?.value) - Number(order.weightLimit.value)).toFixed(2).toString()
                            : '',
                    custom_message: emailText || '',
                    payment_link: payment_link || '',
                };
                if (askReview && !customer.reviewed && customer.overallExperience === 'Positive') {
                    topic = 'invoice-receipt-needs-review';
                    (body as Notification.SendGrid.InvoiceReceiptNeedsReview).review_link = review_link || '';
                }
            } else {
                topic = 'invoice-intermediary';
                body = {
                    first_name: contactEmail.toContact.firstName || '',
                    invoice_number: `${order.orderNumber} - ${invoice.invoiceDetails.invoiceNumber}`,
                    order_number: order.orderNumber?.toString() || '',
                    payment_link: payment_link || '',
                    receipt_invoice: isInvoice ? 'Invoice' : 'Receipt',
                };
            }

            const destination = formatEmailDestination(
                customerEmail,
                contactEmail.toContact.firstName || '',
                contactEmail.cc,
                order.status === 'COMPLETED' && !customer.reviewed && customer.overallExperience === 'Positive'
                    ? ['www.sourgum.com+972c7c30ce@invite.trustpilot.com']
                    : undefined,
            );

            try {
                await client.notification().adminPortal.createInstantNotification({
                    handler: 'sendgrid',
                    topic,
                    destination,
                    body: JSON.stringify(body),
                    directAttachments: attachments,
                });
                if (order.status === 'COMPLETED' && !customer.reviewed && customer.overallExperience !== 'Positive') {
                    await client.notification().adminPortal.createInstantNotification({
                        handler: 'sendgrid',
                        topic: 'sap-request-for-feedback',
                        destination,
                        body: JSON.stringify({ first_name: body.first_name || '' }),
                    });
                }
                setIsLoading(false);
                onSend(isInvoice);
            } catch (error) {
                alert('Something went wrong sending the email');
                throw new Error('Something went wrong sending the email');
            }
        } catch (error) {
            let errorMessage = 'Something went wrong. Please try again.';

            if ((error as Error).message) {
                errorMessage = (error as Error).message;
            }

            if (isAxiosError(error)) {
                errorMessage = error.response?.data?.message || errorMessage;
            }
            setShowToast({
                severity: 'warning',
                message: errorMessage,
            });
        } finally {
            setIsLoading(false);
        }
    };

    //////////////////////////////////////////////////
    // HOOKS SECTION
    //////////////////////////////////////////////////

    useEffect(() => {
        if (customer) {
            const contactEmail = getCustomerToAndCCEmails('billing', customer);
            setCustomerEmail(contactEmail.to);
        }
    }, [customer]);

    useEffect(() => {
        if (attachAllInvoices == true && additionalPDFs.length < 1) {
            fetchAdditionalPDFs();
        }
    }, [attachAllInvoices]);

    useEffect(() => {
        if (invoiceContainer) setContainerHeight(invoiceContainer.clientHeight);
    }, [invoiceContainer]);

    useEffect(() => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setIsValid(emailPattern.test(customerEmail || ''));
    }, [customerEmail]);

    useEffect(() => {
        if (invoice) {
            setIsInvoice(!invoice.invoiceDetails.paidInFull);

            (async () => {
                try {
                    const response = await client.invoice().adminPortal.receivable.pdf.fetch(invoice.id);
                    const blob = new Blob([response], { type: 'application/pdf' });
                    setPdf(blob);
                } catch (error) {
                    console.error(error);
                    setShowToast({
                        severity: 'warning',
                        message: 'Something went wrong loading the PDF. Please try again.',
                    });
                }
            })();
        }
    }, [invoice]);

    const file = useMemo(() => {
        if (pdf) {
            return URL.createObjectURL(pdf);
        }
    }, [pdf]);

    useEffect(() => {
        verifyCustomerExperience();
    }, [isInvoice]);

    //////////////////////////////////////////////////
    // RENDER SECTION
    //////////////////////////////////////////////////

    return (
        <Dialog
            open={open}
            onClose={onCancel}
            styledTitle={`Send Invoice ${order.orderNumber}-${invoice.invoiceDetails.invoiceNumber}`}
            fullScreen
            dialogBodyClassName="flex-1"
        >
            <div className="h-full">
                <div className="flex justify-between" style={{ height: 'calc(100% - 56px)' }} id="container">
                    <div className="flex flex-col border-r-2 border-gray-300 pr-10">
                        <div className="mb-4 text-lg text-gray-600">Email Details</div>
                        <div className="mb-4 flex w-60 flex-col gap-1">
                            <TextField
                                label="Email to"
                                inputProps={{
                                    value: customerEmail,
                                    onChange: (e: ChangeEvent<HTMLInputElement>) => setCustomerEmail(e.target.value),
                                }}
                            />
                            <p className="text-xs text-gray-500">CC: {ccEmails.join(', ') || 'None'}</p>
                            <p className="text-xs text-gray-500">
                                Additional PDFs: {/* If we are attaching all PDFs display the invoice numbers to the user */}
                                {attachAllInvoices
                                    ? additionalPDFs
                                          .map((pdf) => {
                                              return order.orderNumber + '-' + pdf.invoice.invoiceDetails.invoiceNumber;
                                          })
                                          .join(', ') || 'None'
                                    : 'None'}
                            </p>
                        </div>

                        <div className="space-y-2">
                            <Checkbox
                                label="Attach all invoices to email"
                                inputProps={{
                                    checked: attachAllInvoices,
                                    onChange: () => setAttachAllInvoices(!attachAllInvoices),
                                }}
                            />
                            <Tooltip
                                text={
                                    order.status !== 'COMPLETED'
                                        ? 'This is not a completed order'
                                        : customer.reviewed
                                          ? 'Customer has already reviewed us'
                                          : ''
                                }
                            >
                                <Checkbox
                                    label="Include review link in email"
                                    inputProps={{
                                        checked: askReview,
                                        onChange: () => setAskReview(!askReview),
                                        disabled: order.status !== 'COMPLETED' || customer.reviewed,
                                    }}
                                />
                            </Tooltip>
                        </div>

                        <div className="mt-4">
                            <Toggle label="Edit email" value={editEmail} onChange={setEditEmail} />
                        </div>
                        <div className="max-w-xs">
                            <>
                                Thank you for choosing Sourgum Waste! The {isInvoice ? 'Invoice' : 'Receipt'} for your order{' '}
                                {order.orderNumber} is ready. Find it attached to this email, or log in to your customer dashboard to access
                                it at any time.
                                {(order.actualWeightDumped?.value || 0) > (order.weightLimit?.value || 0) ? (
                                    <>
                                        <br />
                                        <br />
                                        <span>Overweight text...</span>
                                    </>
                                ) : null}
                            </>
                        </div>
                        <div className="mb-0 flex-1">
                            <Textarea
                                label="Optional custom message"
                                height="h-full"
                                inputProps={{
                                    disabled: !editEmail,
                                    value: emailText,
                                    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => setEmailText(e.target.value),
                                }}
                            />
                        </div>
                        <div className="max-w-xs">
                            {askReview ? (
                                <span>
                                    We'd love to hear about your experience! Please take a moment to leave us a review (it just takes a
                                    minute) and help us continue to provide the best modern waste & recycling services
                                </span>
                            ) : (
                                <span>
                                    If you have any questions or feedback about how things went, please reply to this email and let us know.
                                    We value your business and are committed to providing the best modern waste & recycling services.
                                </span>
                            )}
                        </div>
                    </div>

                    <div
                        className="text-wayste-corral-400"
                        style={{
                            overflow: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flex: 0.75,
                            position: 'relative',
                        }}
                    >
                        {images.length > 0 ? (
                            <div className="relative">
                                <TrashIcon
                                    className="text-delete absolute right-5 top-10 size-6 cursor-pointer"
                                    onClick={() => handleImageDelete(activeImageStep)}
                                />
                                {images[activeImageStep].type === 'application/pdf' ? (
                                    <object
                                        width={containerHeight * 0.85}
                                        height={containerHeight * 0.77273}
                                        data={(() => {
                                            const file = images[activeImageStep];
                                            return URL.createObjectURL(file);
                                        })()}
                                        className="object-contain"
                                        type="application/pdf"
                                    ></object>
                                ) : (
                                    <img
                                        src={window.URL.createObjectURL(images[activeImageStep])}
                                        alt="Dump ticket"
                                        style={{
                                            height: containerHeight * 0.85,
                                            width: containerHeight * 0.77273,
                                            border: 'solid 1px #D8D8D8',
                                        }}
                                    />
                                )}
                                <div className="flex items-center justify-between py-2">
                                    <Button
                                        startIcon={<ChevronLeftIcon className="size-5" />}
                                        className={`btn-primary-text-only px-2 py-1 ${
                                            activeImageStep === 0 ? 'cursor-not-allowed opacity-50' : ''
                                        }`}
                                        onClick={() => {
                                            setActiveImageStep((prevActiveStep) => prevActiveStep - 1);
                                        }}
                                        disabled={activeImageStep === 0}
                                    >
                                        Back
                                    </Button>
                                    <p className="text-sm font-medium text-gray-500">{`${activeImageStep + 1} / ${images.length}`}</p>
                                    <Button
                                        endIcon={<ChevronRightIcon className="size-5" />}
                                        className={`btn-primary-text-only px-2 py-1 ${
                                            activeImageStep === images.length - 1 ? 'cursor-not-allowed opacity-50' : ''
                                        }`}
                                        onClick={() => {
                                            setActiveImageStep((prevActiveStep) => prevActiveStep + 1);
                                        }}
                                        disabled={activeImageStep === images.length - 1}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <div className="relative flex flex-col items-center text-center">
                                <PaperClipIcon className="size-10" />
                                <div>Attach Dump Ticket</div>
                                <input
                                    className="blankFileInput"
                                    type="file"
                                    onChange={(event) => {
                                        if (event?.target?.files?.[0]) {
                                            handleImageUpload(event?.target?.files?.[0]);
                                        } else {
                                            setShowToast({
                                                severity: 'warning',
                                                message: 'Please select a file to upload',
                                            });
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div className="flex h-full flex-1 items-center justify-center overflow-auto">
                        {file ? (
                            <object data={file} type="application/pdf" width="100%" height="100%">
                                <param name="width" value="100%" />

                                <p className="text-center">
                                    It appears your Web browser is not configured to display PDF files. No worries, just
                                    <a className="text-green-500" href={file}>
                                        click here
                                    </a>{' '}
                                    to download the PDF file.
                                </p>
                            </object>
                        ) : (
                            <div className="flex size-full flex-row items-center justify-center">
                                <Loading />
                            </div>
                        )}
                    </div>
                </div>
                <div className="flex gap-4 pt-6">
                    <Button type="button" className="btn-dark-grey-outlined" onClick={onCancel} disabled={isLoading}>
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        className="btn-primary"
                        disabled={isLoading || !isValid || !file || downloading}
                        onClick={() => handleSend()}
                    >
                        <div className="flex flex-row flex-nowrap items-center gap-2">
                            Email Invoice
                            {(isLoading || downloading) && <Loading className="text-sourgum-greyblue-900 ml-1" size="h-4 w-4" />}
                        </div>
                    </Button>
                    {images.length > 0 && (
                        <Button className="btn-secondary-white relative" disabled={isLoading} type="button">
                            Upload Another Image
                            <input
                                className="blankFileInput"
                                type="file"
                                onChange={(event) => {
                                    if (event?.target?.files?.[0]) {
                                        handleImageUpload(event?.target?.files?.[0]);
                                    } else {
                                        setShowToast({
                                            severity: 'warning',
                                            message: 'Please select a file to upload',
                                        });
                                    }
                                }}
                            />
                        </Button>
                    )}
                </div>
            </div>
        </Dialog>
    );
};
export default InvoiceSend;
