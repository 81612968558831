import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Notification, Order } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Dialog, Loading, Toggle } from '@wayste/sour-ui';
import { formatEmailDestination, formatServiceAddress, getDispatchEmailsString, getPrimaryCustomerContact } from '@wayste/utils';
import { sendEmail } from '../../axios/ses';
import { getCustomerToAndCCEmails, noReplyEmail } from '../../utils';

export interface SendEmailDialogProps {
    open: boolean;
    onClose: () => void;
    changes: {
        label: string;
        oldValue: string;
        newValue: string;
    }[];
    customer: Customer.AllianceCustomerTransport;
    order: Order.AllianceOrderTransport;
}

const SendEmailDialog = ({ open, onClose, changes, customer, order }: SendEmailDialogProps) => {
    const client = useWaysteClient();
    const userProfile = client.user().get();
    const { setShowAlert, setShowToast } = useSourContext();
    const [sendCustomerEmail, setSendCustomerEmail] = useState(false);
    const [sendHaulerEmail, setSendHaulerEmail] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [resetHaulerConfirmed, setResetHaulerConfirmed] = useState(true);

    useEffect(() => {
        if (order.status === 'UNASSIGNED') setSendHaulerEmail(false);
    }, [order]);

    const customerEmailData: Notification.SendGrid.RoChangesEmail = {
        first_name: getPrimaryCustomerContact(customer)?.firstName || '',
        csa_name: userProfile.firstName || '',
        order_number: order.orderNumber?.toString() || '',
        service_location: formatServiceAddress(order.serviceLocation.address),
        changes_block: changes
            .filter((item) => item.label !== 'Additional Delivery Notes' && item.label !== 'Additional Pickup Notes')
            .map((item) => {
                if (item.label === 'Pickup Date' && item.oldValue && !item.newValue) {
                    return `<p><b>${item.label}:</b> Cancel pickup until further notice.</p>`;
                }
                return `<p><b>${item.label}:</b><br />Old: ${item.oldValue}<br />New: ${item.newValue}</p>`;
            })
            .join(''),
    };

    const customerHtmlMessage = `<p>Hi ${getPrimaryCustomerContact(customer)?.firstName},</p>
  <p>This is ${userProfile.firstName} with Sourgum Waste. I've updated the following information for your order #${
      order.orderNumber
  } at ${formatServiceAddress(order.serviceLocation.address)} for you.</p>
  ${changes
      .filter((item) => item.label !== 'Additional Delivery Notes' && item.label !== 'Additional Pickup Notes')
      .map((item) => {
          if (item.label === 'Pickup Date' && item.oldValue && !item.newValue) {
              return `<p><b>${item.label}:</b> Cancel pickup until further notice.</p>`;
          }
          return `<p><b>${item.label}:</b><br />Old: ${item.oldValue}<br />New: ${item.newValue}</p>`;
      })
      .join('')}
  <p>If you have any questions please let me know, and I'll be happy to help you.</p>
  <p>Thank you,</p>
  <p>${userProfile.firstName}</p>`;

    const haulerEmailText = `Confirmation of changes to Sourgum Waste dumpster rental:
  \n\nOrder #${order.orderNumber}
  \n\nAddress: ${formatServiceAddress(order.serviceLocation.address)}
  \n\n${changes.map((item) => {
      if (item.label === 'Pickup Date' && item.oldValue && !item.newValue) {
          return `${item.label}: Cancel pickup until further notice.\n\n`;
      }
      return `${item.label}:\n\nOld: ${item.oldValue}\n\nNew: ${item.newValue}\n\n`;
  })}
  If you have any questions please let me know and I'll be happy to help you.
  \n\nThank you,
  \n\n${userProfile.firstName}`;

    const confirmationUrl = 'https://app.wayste.com/update-alliance/';
    const haulerHtmlMessage = `<p>Confirmation of changes to Sourgum Waste dumpster rental:</p>
  <p><b>Order #</b>: ${order.orderNumber}</p>
  <p><b>Address</b>: ${formatServiceAddress(order.serviceLocation.address)}</p>
  ${changes
      .map((item) => {
          if (item.label === 'Pickup Date' && item.oldValue && !item.newValue) {
              return `<p><b>${item.label}:</b> Cancel pickup until further notice.</p>`;
          }
          return `<p><b>${item.label}:</b><br />Old: ${item.oldValue}<br />New: ${item.newValue}</p>`;
      })
      .join('')}
  
  <p style="margin: 20px 0">
          <a href="${confirmationUrl}?category=delivery_confirmed&id=${
              order.id
          }" style="text-decoration: none;color: white;background: #007AFF;padding: 7px 15px;border-radius: 4px;box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);">Click Here to Confirm Changes</a>
   </p>
  
  <p>If you have any questions please let me know, and I'll be happy to help you.</p>
  <p>Thank you,</p>
  <p>${userProfile.firstName}</p>`;

    const handleSendClick = async () => {
        setIsLoading(true);
        if (sendHaulerEmail && order.haulerID) {
            const haulerResponse = await client.vendorService().fetchById(order.haulerID);
            const hauler = haulerResponse.data;
            const dispatchEmailsString = getDispatchEmailsString(hauler.contacts);
            if (dispatchEmailsString) {
                const haulerData = {
                    htmlMessage: haulerHtmlMessage,
                    textMessage: haulerEmailText,
                    toAddress: dispatchEmailsString,
                    subject: `Sourgum Waste Order Update - ${order.orderNumber}`,
                    bcc: noReplyEmail,
                };
                try {
                    await sendEmail('send-email', haulerData);
                    setShowToast({
                        severity: 'success',
                        message: 'Hauler Email Sent Successfully',
                    });
                } catch (error) {
                    console.warn('OrderUpdate send hauler email error: ', error);
                    setShowToast({
                        severity: 'error',
                        message: 'An error occurred sending Hauler email',
                    });
                }
            } else {
                setShowToast({
                    severity: 'warning',
                    message: 'No Hauler Email',
                });
            }
        }
        if (sendCustomerEmail) {
            try {
                const contactEmails = getCustomerToAndCCEmails('dispatch', customer, order.serviceLocation.address);
                const destination = formatEmailDestination(contactEmails.to, contactEmails.toContact.firstName || '', contactEmails.cc);
                try {
                    await client.notification().adminPortal.createInstantNotification({
                        handler: 'sendgrid',
                        topic: 'ro-changes-email',
                        destination,
                        body: JSON.stringify(customerEmailData),
                    });
                    setShowToast({
                        severity: 'success',
                        message: 'Customer Email Sent Successfully',
                    });
                } catch (error) {
                    console.warn('OrderUpdate send customer email error: ', error);
                    setShowToast({
                        severity: 'warning',
                        message: 'An error occurred sending Customer email',
                    });
                }
            } catch (error) {
                setShowAlert({
                    severity: 'error',
                    title: 'Error Sending Customer Cancellation Email',
                    message: (error as Error)?.message || 'An unknown error occurred.',
                });
            }
        }
        // Reset hauler confirmed
        if (resetHaulerConfirmed) {
            try {
                await client.order().adminPortal.update(order.id, {
                    haulerConfirmedDelivery: false,
                });
                setShowToast({
                    severity: 'success',
                    message: 'Hauler confirmed reset successfully',
                });
            } catch (error) {
                console.error('OrderUpdate reset hauler confirmed error: ', error);
                setShowToast({
                    severity: 'error',
                    message: 'An error occurred resetting hauler confirmed',
                });
            }
        }
        setIsLoading(false);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} styledTitle="Send Update Emails" className="max-w-2xl">
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <div className="flex flex-col gap-2">
                        <div>Do you want to send the email below to the hauler?</div>
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: haulerHtmlMessage }} style={{ color: 'rgba(0, 0, 0, 0.58)' }} />
                        </div>
                        <div>
                            <Toggle
                                value={sendHaulerEmail}
                                label="Hauler"
                                onChange={setSendHaulerEmail}
                                disabled={order.status === 'UNASSIGNED'}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex flex-col gap-2">
                        <div>Do you want to send the email below to the customer?</div>
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: customerHtmlMessage }} style={{ color: 'rgba(0, 0, 0, 0.58)' }} />
                        </div>
                        <div>
                            <Toggle value={sendCustomerEmail} label="Customer" onChange={setSendCustomerEmail} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-2 pt-4">
                <Toggle
                    label="Reset Hauler Confirmed"
                    value={resetHaulerConfirmed}
                    onChange={(value) => {
                        setResetHaulerConfirmed(value);
                    }}
                />
            </div>
            <div className="flex justify-end gap-4 pt-4">
                <button className="btn-dark-grey-outlined" onClick={onClose} type="button" disabled={isLoading}>
                    Cancel
                </button>
                <button className="btn-primary" onClick={handleSendClick} type="submit" disabled={isLoading}>
                    Send to Selected
                    {isLoading && <Loading className="text-sourgum-greyblue-900" size="h-4 w-4 ml-2" />}
                </button>
            </div>
        </Dialog>
    );
};

export default SendEmailDialog;
