import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import type { Invoice, UniversalService } from '@alliance-disposal/transport-types';
import { SourFiltersOld, SourSearchOld, SourSearchResponseOld, SourSearchWrapperOld } from '@wayste/sour-search';
import { DataGrid, DataGridProps } from '@wayste/sour-ui';
import { formatISODateString, formatServiceAddress, getRouterPath } from '@wayste/utils';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { UIContext } from '../../../contexts';
import { routes } from '../../../utils';
import { getGroupingBillStatusFromPayables, getStatusFromGroupingReceivables } from '../../../utils/invoice-utils';
import { BillingContext } from '../context';
import { GridCellStatus, GridCellStatusProps } from './renderCellReceivablesStatus';

export type ExtendedServiceGrouping = UniversalService.ServiceGrouping & {
    receivables: Invoice.ReceivableTransport[];
    payables: Invoice.PayableTransport[];
    receivablesStatus: Invoice.InvoiceStatus | 'NONE';
    payablesStatus: Invoice.InvoiceStatus | 'NONE';
};

const GroupingStatusBadge = ({ status }: { status: UniversalService.ServiceGroupingStatus }) => {
    switch (status) {
        case 'pending':
            return (
                <div className="inline-flex h-6 items-center rounded-full border-2 border-dashed border-[#e0e0e0] bg-white px-2.5 text-xs font-medium text-[#6b7280]">
                    Pending
                </div>
            );
        case 'ongoing':
            return (
                <div className="inline-flex h-6 items-center rounded-full bg-[#d7f7c2] px-2.5 text-xs font-medium text-[#05690d]">
                    Active
                </div>
            );
        case 'closed':
            return (
                <div className="bg-error-light text-error-dark inline-flex h-6 items-center rounded-full px-2.5 text-xs font-medium">
                    Inactive
                </div>
            );
        default:
            return <span className="min-w-[125px] text-center">{'status not found'}</span>;
    }
};

const BillingListUniversal = () => {
    const client = useWaysteClient();
    const history = useHistory();
    const { lastViewedId, setLastViewedId } = useContext(BillingContext);

    // SERVICE ORDERS
    const [serviceOrders, setServiceOrders] = useState<ExtendedServiceGrouping[]>([]);

    // PAGINATION STATE
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(25);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const defaultFilter = 'sourgum-service-grouping';
    const [isDefaultFilter, setIsDefaultFilter] = useState<boolean | undefined>(true);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [filterActive, setFilterActive] = useState(false);
    const [searchResults, setSearchResults] = useState<ExtendedServiceGrouping[]>([]);
    const [searchResultsCount, setSearchResultsCount] = useState<number>(0);
    const [searchPage, setSearchPage] = useState<number>(0);
    const [searchPageSize, setSearchPageSize] = useState<number>(25);

    ///////////////////////////////////////////////
    // SOUR SEARCH
    ///////////////////////////////////////////////

    // NAVIGATE TO APPROPRIATE PAGE
    const onNavigate = (_: unknown, recordID: string, name: string) => {
        const path = getRouterPath(name, recordID, routes);
        history.push(path);
    };

    const processHits = async (hits: SourSearchResponseOld['results']['hits']['hits']) => {
        const results = hits.map((hit) => hit._source) as UniversalService.ServiceGrouping[];

        const processedOrders: ExtendedServiceGrouping[] = [];
        const ids = results.map((result) => result.id);

        if (ids.length === 0) {
            return [];
        }

        const [receivables, payables] = await Promise.all([
            ids.length > 0
                ? client.invoice().adminPortal.receivable.query({
                      serviceGroupingID: ids.join(','),
                  })
                : Promise.resolve({ results: [] }),
            ids.length > 0
                ? client.invoice().adminPortal.payable.query({
                      serviceGroupingID: ids.join(','),
                      limit: 10000,
                  })
                : Promise.resolve({ results: [] }),
        ]);

        const receivablesMap = ids.reduce((obj: Record<string, Invoice.ReceivableTransport[]>, str: string) => {
            obj[str] = [];
            return obj;
        }, {});
        receivables.results.forEach((receivable: Invoice.ReceivableTransport) => {
            if (receivable.invoiceDetails.serviceGroupingID) {
                receivablesMap[receivable.invoiceDetails.serviceGroupingID].push(receivable);
            }
        });

        const payablesMap = ids.reduce((obj: Record<string, Invoice.PayableTransport[]>, str: string) => {
            obj[str] = [];
            return obj;
        }, {});
        payables.results.forEach((payable: Invoice.PayableTransport) => {
            if (payable?.invoiceDetails?.serviceGroupingID) {
                payablesMap[payable.invoiceDetails.serviceGroupingID].push(payable);
            }
        });

        results.forEach((grouping) => {
            // TODO sort service orders by newest to oldest so item 0 is the most recent
            processedOrders.push({
                ...grouping,
                receivables: receivablesMap[grouping.id] || [],
                payables: payablesMap[grouping.id] || [],
                receivablesStatus: getStatusFromGroupingReceivables(receivablesMap[grouping.id] || []),
                payablesStatus: getGroupingBillStatusFromPayables(payablesMap[grouping.id] || []),
            });
        });

        return processedOrders;
    };

    const handleSearchResults = async (response: SourSearchResponseOld) => {
        setSearchLoading(true);
        if (searchPage !== response.page) setSearchPage(response.page);
        response.type === defaultFilter ? setIsDefaultFilter(true) : setIsDefaultFilter(false);
        const res = await processHits(response.results.hits.hits);
        setSearchResultsCount(response.totalHits);
        setSearchResults(res as ExtendedServiceGrouping[]);

        setSearchLoading(false);
    };

    const onActiveSearch = (active: boolean) => {
        setSearchActive(active);
    };

    const onActiveFilter = (active: boolean) => {
        setFilterActive(active);
    };

    ///////////////////////////////////////////////
    // SERVICE ORDERS FETCH AND PROCESSING
    ///////////////////////////////////////////////

    const getServiceOrders = async (query: UniversalService.ServiceGroupingQuery) => {
        const serviceGroupings = await client.universalService().serviceGrouping.query(query);

        // const serviceOrders = await client.universalService().serviceOrder.query(query);
        setTotalPages(serviceGroupings.total);

        const processedOrders: ExtendedServiceGrouping[] = [];
        const ids = serviceGroupings.results.map((result) => result.id);

        if (ids.length === 0) {
            setLoading(false);
            return;
        }

        const [receivables, payables] = await Promise.all([
            ids.length > 0
                ? client.invoice().adminPortal.receivable.query({
                      serviceGroupingID: ids.join(','),
                  })
                : Promise.resolve({ results: [] }),
            ids.length > 0
                ? client.invoice().adminPortal.payable.query({
                      serviceGroupingID: ids.join(','),
                      limit: 10000,
                  })
                : Promise.resolve({ results: [] }),
        ]);

        const receivablesMap = ids.reduce((obj: Record<string, Invoice.ReceivableTransport[]>, str: string) => {
            obj[str] = [];
            return obj;
        }, {});
        receivables.results.forEach((receivable: Invoice.ReceivableTransport) => {
            if (receivable.invoiceDetails.serviceGroupingID) {
                receivablesMap[receivable.invoiceDetails.serviceGroupingID].push(receivable);
            }
        });

        const payablesMap = ids.reduce((obj: Record<string, Invoice.PayableTransport[]>, str: string) => {
            obj[str] = [];
            return obj;
        }, {});
        payables.results.forEach((payable: Invoice.PayableTransport) => {
            if (payable?.invoiceDetails?.serviceGroupingID) {
                payablesMap[payable.invoiceDetails.serviceGroupingID].push(payable);
            }
        });

        serviceGroupings.results.forEach((grouping) => {
            // TODO sort service orders by newest to oldest so item 0 is the most recent
            processedOrders.push({
                ...grouping,
                receivables: receivablesMap[grouping.id] || [],
                payables: payablesMap[grouping.id] || [],
                receivablesStatus: getStatusFromGroupingReceivables(receivablesMap[grouping.id] || []),
                payablesStatus: getGroupingBillStatusFromPayables(payablesMap[grouping.id] || []),
            });
        });
        setLoading(false);
        setServiceOrders(processedOrders);
    };

    // FETCH SERVICE ORDERS ON PAGE CHANGE
    useEffect(() => {
        setLoading(true);
        const query: UniversalService.ServiceGroupingQuery = {
            limit: pageSize,
            offset: page * pageSize,
        };
        getServiceOrders(query);
    }, [page, pageSize]);

    ///////////////////////////////////////////////
    // TABLE SCHEMA
    ///////////////////////////////////////////////

    const columns: DataGridProps<ExtendedServiceGrouping>['columns'] = [
        {
            key: 'orderNumber',
            name: 'Order #',
            align: 'center',
            width: 75,
        },
        {
            key: 'serviceType',
            name: 'Service Type',
            formatter: ({ row }) =>
                row.serviceOrders
                    .map((order) => order?.serviceType?.family?.name)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .join(', '),
        },
        {
            key: 'status',
            name: 'Status',
            headerAlign: 'center',
            align: 'center',
            width: 130,
            formatter: (value) => <GroupingStatusBadge status={value.row.status} />,
        },

        {
            key: 'receivablesStatus',
            name: 'Receivables',
            headerAlign: 'center',
            align: 'center',
            width: 100,
            formatter: (value) => <GridCellStatus value={value.row.receivablesStatus} />,
        },
        {
            key: 'payablesStatus',
            name: 'Payables',
            headerAlign: 'center',
            align: 'center',
            width: 100,
            formatter: (value) => <GridCellStatus value={value.row.payablesStatus} />,
        },
        {
            key: 'customerName',
            name: 'Customer',
            width: 300,
            formatter: (value) => <>{value.row.customerCompanyName || value.row.customerName}</>,
        },
        {
            key: 'serviceLocation',
            name: 'Address',
            width: 300,
            formatter: (value) => <>{formatServiceAddress(value.row.serviceOrders[0]?.serviceLocation?.address)}</>,
        },
        {
            key: 'vendorName',
            name: 'Vendor',
            formatter: (value) => (
                <>{Array.from(new Set(value.row.serviceOrders.map((order) => order.vendorName).filter(Boolean))).join(', ')}</>
            ),
        },

        // {
        //   key: 'servicePeriod',
        //   name: 'Service Period',
        //   align: 'center',
        //   headerAlign: 'center',
        //   formatter: (value) => (
        //     <>
        //       {formatISODateString(value.row.startDate)} - {formatISODateString(value.row.endDate)}
        //     </>
        //   ),
        //   width: 150,
        // },
        // {
        //   key: 'sku',
        //   name: 'SKUS',
        //   align: 'center',
        //   headerAlign: 'center',
        //   formatter: (value) => (
        //     <>
        //       {value.row.serviceType.family.shortCode} - {value.row.serviceType.name}
        //     </>
        //   ),

        //   width: 150,
        // },
    ];

    ///////////////////////////////////////////////
    // RENDER
    ///////////////////////////////////////////////

    return (
        <>
            <div className="item-center flex w-full flex-row justify-between py-2">
                <SourSearchWrapperOld
                    options={{
                        application: 'aap',
                        apiKey: import.meta.env.VITE_ELASTIC_KEY as string,
                        environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                    }}
                    onNavigate={onNavigate}
                    defaultFilters={{
                        query: {
                            type: defaultFilter,
                        },
                    }}
                    createQueryParams={{ method: 'with_filter', removeOn: 'empty_string_inactive_filter' }}
                    onResults={handleSearchResults}
                    onSearch={onActiveSearch}
                    onFilter={onActiveFilter}
                    size={searchPageSize}
                    page={searchPage}
                >
                    <div className="flex w-full flex-row justify-between space-x-4">
                        <SourSearchOld
                            options={{
                                searchPopoverFixed: false,
                                showTips: !isDefaultFilter,
                                showMessages: !isDefaultFilter,
                                showResults: !isDefaultFilter,
                                placeholder: 'Search Universal Service Orders',
                            }}
                        />
                        <SourFiltersOld />
                    </div>
                </SourSearchWrapperOld>
            </div>
            <div className="flex h-0 w-full flex-1 grow basis-0">
                <DataGrid
                    rows={searchActive || filterActive ? searchResults : serviceOrders}
                    loading={searchActive || filterActive ? searchLoading : loading}
                    columns={columns}
                    rowHeight={50}
                    onRowClick={(row, column) => {
                        if (column.key === 'status') {
                            return;
                        }
                        setLastViewedId(row.id);
                        history.push({
                            pathname: routes.billingProduct.list,
                            search: qs.stringify({
                                serviceGroupingID: row.id,
                            }),
                        });
                    }}
                    makeRow={(row) => ({
                        className: row.id === lastViewedId ? 'bg-blue-50' : '',
                    })}
                    pagination={{
                        itemCount: searchActive ? searchResultsCount : totalPages,
                        page: searchActive || filterActive ? searchPage : page,
                        rowsPerPage: searchActive || filterActive ? searchPageSize : pageSize,
                        rowsPerPageOptions: [25, 50, 100],
                        onPageChange: (newPage) => {
                            setPage(newPage);
                            setSearchPage(newPage);
                        },
                        onRowsPerPageChange: (rowsPerPage) => {
                            setPageSize(rowsPerPage);
                            setSearchPageSize(rowsPerPage);
                            setPage(0);
                        },
                    }}
                />
            </div>
        </>
    );
};

export default BillingListUniversal;
