import React, { useMemo, useRef } from 'react';
import { Order } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { formatServiceDate } from '@wayste/utils';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { ClassValue, clsx } from 'clsx';
import { differenceInDays, format, parseISO } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { RiCloseCircleFill, RiSparklingFill } from 'react-icons/ri';
import { twMerge } from 'tailwind-merge';
import * as THREE from 'three';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export const CanvasRevealEffect = ({
    animationSpeed = 0.4,
    opacities = [0.3, 0.3, 0.3, 0.5, 0.5, 0.5, 0.8, 0.8, 0.8, 1],
    colors = [[0, 255, 255]],
    containerClassName,
    dotSize,
    showGradient = true,
}: {
    /**
     * 0.1 - slower
     * 1.0 - faster
     */
    animationSpeed?: number;
    opacities?: number[];
    colors?: number[][];
    containerClassName?: string;
    dotSize?: number;
    showGradient?: boolean;
}) => {
    return (
        <div className={cn('relative h-full w-full bg-white', containerClassName)}>
            <div className="h-full w-full">
                <DotMatrix
                    colors={colors ?? [[0, 255, 255]]}
                    dotSize={dotSize ?? 3}
                    opacities={opacities ?? [0.3, 0.3, 0.3, 0.5, 0.5, 0.5, 0.8, 0.8, 0.8, 1]}
                    shader={`
              float animation_speed_factor = ${animationSpeed.toFixed(1)};
              float intro_offset = distance(u_resolution / 2.0 / u_total_size, st2) * 0.01 + (random(st2) * 0.15);
              opacity *= step(intro_offset, u_time * animation_speed_factor);
              opacity *= clamp((1.0 - step(intro_offset + 0.1, u_time * animation_speed_factor)) * 1.25, 1.0, 1.25);
            `}
                    center={['x', 'y']}
                />
            </div>
            {showGradient && <div className="absolute inset-0 bg-gradient-to-t from-gray-950 to-[84%]" />}
        </div>
    );
};

interface DotMatrixProps {
    colors?: number[][];
    opacities?: number[];
    totalSize?: number;
    dotSize?: number;
    shader?: string;
    center?: ('x' | 'y')[];
}

const DotMatrix: React.FC<DotMatrixProps> = ({
    colors = [[0, 0, 0]],
    opacities = [0.04, 0.04, 0.04, 0.04, 0.04, 0.08, 0.08, 0.08, 0.08, 0.14],
    totalSize = 4,
    dotSize = 2,
    shader = '',
    center = ['x', 'y'],
}) => {
    const uniforms = useMemo(() => {
        let colorsArray = [colors[0], colors[0], colors[0], colors[0], colors[0], colors[0]];
        if (colors.length === 2) {
            colorsArray = [colors[0], colors[0], colors[0], colors[1], colors[1], colors[1]];
        } else if (colors.length === 3) {
            colorsArray = [colors[0], colors[0], colors[1], colors[1], colors[2], colors[2]];
        }

        return {
            u_colors: {
                value: colorsArray.map((color) => [color[0] / 255, color[1] / 255, color[2] / 255]),
                type: 'uniform3fv',
            },
            u_opacities: {
                value: opacities,
                type: 'uniform1fv',
            },
            u_total_size: {
                value: totalSize,
                type: 'uniform1f',
            },
            u_dot_size: {
                value: dotSize,
                type: 'uniform1f',
            },
        };
    }, [colors, opacities, totalSize, dotSize]);

    return (
        <Shader
            source={`
        precision mediump float;
        in vec2 fragCoord;

        uniform float u_time;
        uniform float u_opacities[10];
        uniform vec3 u_colors[6];
        uniform float u_total_size;
        uniform float u_dot_size;
        uniform vec2 u_resolution;
        out vec4 fragColor;
        float PHI = 1.61803398874989484820459;
        float random(vec2 xy) {
            return fract(tan(distance(xy * PHI, xy) * 0.5) * xy.x);
        }
        float map(float value, float min1, float max1, float min2, float max2) {
            return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
        }
        void main() {
            vec2 st = fragCoord.xy;
            ${center.includes('x') ? 'st.x -= abs(floor((mod(u_resolution.x, u_total_size) - u_dot_size) * 0.5));' : ''}
            ${center.includes('y') ? 'st.y -= abs(floor((mod(u_resolution.y, u_total_size) - u_dot_size) * 0.5));' : ''}
      float opacity = step(0.0, st.x);
      opacity *= step(0.0, st.y);

      vec2 st2 = vec2(int(st.x / u_total_size), int(st.y / u_total_size));

      float frequency = 5.0;
      float show_offset = random(st2);
      float rand = random(st2 * floor((u_time / frequency) + show_offset + frequency) + 1.0);
      opacity *= u_opacities[int(rand * 10.0)];
      opacity *= 1.0 - step(u_dot_size / u_total_size, fract(st.x / u_total_size));
      opacity *= 1.0 - step(u_dot_size / u_total_size, fract(st.y / u_total_size));

      vec3 color = u_colors[int(show_offset * 6.0)];

      ${shader}

      fragColor = vec4(color, opacity);
      fragColor.rgb *= fragColor.a;
        }`}
            uniforms={uniforms}
            maxFps={60}
        />
    );
};

type Uniforms = {
    [key: string]: {
        value: number[] | number[][] | number;
        type: string;
    };
};
const ShaderMaterial = ({ source, uniforms, maxFps = 60 }: { source: string; hovered?: boolean; maxFps?: number; uniforms: Uniforms }) => {
    const { size } = useThree();
    const ref = useRef<THREE.Mesh>();
    let lastFrameTime = 0;

    useFrame(({ clock }) => {
        if (!ref.current) return;
        const timestamp = clock.getElapsedTime();
        if (timestamp - lastFrameTime < 1 / maxFps) {
            return;
        }
        lastFrameTime = timestamp;

        const material: any = ref.current.material;
        const timeLocation = material.uniforms.u_time;
        timeLocation.value = timestamp;
    });

    const getUniforms = () => {
        const preparedUniforms: any = {};

        for (const uniformName in uniforms) {
            const uniform: any = uniforms[uniformName];

            switch (uniform.type) {
                case 'uniform1f':
                    preparedUniforms[uniformName] = { value: uniform.value, type: '1f' };
                    break;
                case 'uniform3f':
                    preparedUniforms[uniformName] = {
                        value: new THREE.Vector3().fromArray(uniform.value),
                        type: '3f',
                    };
                    break;
                case 'uniform1fv':
                    preparedUniforms[uniformName] = { value: uniform.value, type: '1fv' };
                    break;
                case 'uniform3fv':
                    preparedUniforms[uniformName] = {
                        value: uniform.value.map((v: number[]) => new THREE.Vector3().fromArray(v)),
                        type: '3fv',
                    };
                    break;
                case 'uniform2f':
                    preparedUniforms[uniformName] = {
                        value: new THREE.Vector2().fromArray(uniform.value),
                        type: '2f',
                    };
                    break;
                default:
                    console.error(`Invalid uniform type for '${uniformName}'.`);
                    break;
            }
        }

        preparedUniforms['u_time'] = { value: 0, type: '1f' };
        preparedUniforms['u_resolution'] = {
            value: new THREE.Vector2(size.width * 2, size.height * 2),
        }; // Initialize u_resolution
        return preparedUniforms;
    };

    // Shader material
    const material = useMemo(() => {
        const materialObject = new THREE.ShaderMaterial({
            vertexShader: `
      precision mediump float;
      in vec2 coordinates;
      uniform vec2 u_resolution;
      out vec2 fragCoord;
      void main(){
        float x = position.x;
        float y = position.y;
        gl_Position = vec4(x, y, 0.0, 1.0);
        fragCoord = (position.xy + vec2(1.0)) * 0.5 * u_resolution;
        fragCoord.y = u_resolution.y - fragCoord.y;
      }
      `,
            fragmentShader: source,
            uniforms: getUniforms(),
            glslVersion: THREE.GLSL3,
            blending: THREE.CustomBlending,
            blendSrc: THREE.SrcAlphaFactor,
            blendDst: THREE.OneFactor,
        });

        return materialObject;
    }, [size.width, size.height, source]);

    return (
        <mesh ref={ref as any}>
            <planeGeometry args={[2, 2]} />
            <primitive object={material} attach="material" />
        </mesh>
    );
};

const Shader: React.FC<ShaderProps> = ({ source, uniforms, maxFps = 60 }) => {
    return (
        <Canvas className="absolute inset-0 h-full w-full">
            <ShaderMaterial source={source} uniforms={uniforms} maxFps={maxFps} />
        </Canvas>
    );
};
interface ShaderProps {
    source: string;
    uniforms: {
        [key: string]: {
            value: number[] | number[][] | number;
            type: string;
        };
    };
    maxFps?: number;
}

export function CanvasRevealEffectDemo2() {
    return (
        <>
            <div className="mx-auto flex w-full flex-col items-center justify-center gap-4 bg-white px-8 py-20 lg:flex-row dark:bg-black">
                <div className="group/canvas-card relative mx-auto flex h-[30rem] w-full max-w-sm items-center justify-center border border-black/[0.2] p-4 dark:border-white/[0.2]">
                    <Icon className="absolute -left-3 -top-3 h-6 w-6 text-black dark:text-white" />
                    <Icon className="absolute -bottom-3 -left-3 h-6 w-6 text-black dark:text-white" />
                    <Icon className="absolute -right-3 -top-3 h-6 w-6 text-black dark:text-white" />
                    <Icon className="absolute -bottom-3 -right-3 h-6 w-6 text-black dark:text-white" />
                    <AnimatePresence>
                        <div className="absolute inset-0 h-full w-full">
                            <CanvasRevealEffect
                                animationSpeed={3}
                                containerClassName="bg-black"
                                colors={[
                                    [236, 72, 153],
                                    [232, 121, 249],
                                ]}
                                dotSize={2}
                            />
                        </div>
                    </AnimatePresence>
                    <div className="relative z-20">
                        <div className="mx-auto flex w-full items-center justify-center text-center transition duration-200 group-hover/canvas-card:-translate-y-4 group-hover/canvas-card:opacity-0">
                            <AceternityIcon />
                        </div>
                        <h2 className="relative z-10 mt-4 text-xl font-bold text-black opacity-0 transition duration-200 group-hover/canvas-card:-translate-y-2 group-hover/canvas-card:text-white group-hover/canvas-card:opacity-100 dark:text-white">
                            I&apos;m static and I know it.
                        </h2>
                    </div>
                </div>
            </div>
        </>
    );
}

const Card = ({ title, icon, children }: { title: string; icon: React.ReactNode; children?: React.ReactNode }) => {
    const [hovered, setHovered] = React.useState(false);
    return (
        <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className="group/canvas-card relative mx-auto flex h-[30rem] w-full max-w-sm items-center justify-center border border-black/[0.2] p-4 dark:border-white/[0.2]"
        >
            <Icon className="absolute -left-3 -top-3 h-6 w-6 text-black dark:text-white" />
            <Icon className="absolute -bottom-3 -left-3 h-6 w-6 text-black dark:text-white" />
            <Icon className="absolute -right-3 -top-3 h-6 w-6 text-black dark:text-white" />
            <Icon className="absolute -bottom-3 -right-3 h-6 w-6 text-black dark:text-white" />

            <AnimatePresence>
                {hovered && (
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="absolute inset-0 h-full w-full">
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>

            <div className="relative z-20">
                <div className="mx-auto flex w-full items-center justify-center text-center transition duration-200 group-hover/canvas-card:-translate-y-4 group-hover/canvas-card:opacity-0">
                    {icon}
                </div>
                <h2 className="relative z-10 mt-4 text-xl font-bold text-black opacity-0 transition duration-200 group-hover/canvas-card:-translate-y-2 group-hover/canvas-card:text-white group-hover/canvas-card:opacity-100 dark:text-white">
                    {title}
                </h2>
            </div>
        </div>
    );
};

const AceternityIcon = () => {
    return (
        <svg
            width="66"
            height="65"
            viewBox="0 0 66 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-10 w-10 text-black group-hover/canvas-card:text-white dark:text-white"
        >
            <path
                d="M8 8.05571C8 8.05571 54.9009 18.1782 57.8687 30.062C60.8365 41.9458 9.05432 57.4696 9.05432 57.4696"
                stroke="currentColor"
                strokeWidth="15"
                strokeMiterlimit="3.86874"
                strokeLinecap="round"
                style={{ mixBlendMode: 'darken' }}
            />
        </svg>
    );
};

export const Icon = ({ className, ...rest }: any) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className={className}
            {...rest}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
        </svg>
    );
};

interface OCRAnalysisProps {
    result?: Order.OCRResultTransport | null;
    isLoading?: boolean;
    order?: Pick<Order.AllianceOrderTransport, 'expectedPickupDate'>;
    onRegenerate?: () => Promise<void>;
}

const AnimatedSparkle = ({ className }: { className?: string }) => (
    <motion.div initial={{ rotate: 0 }} animate={{ rotate: 360 }} transition={{ duration: 4, repeat: Infinity, ease: 'linear' }}>
        <RiSparklingFill className={className} />
    </motion.div>
);

const OCRAnalysis: React.FC<OCRAnalysisProps> = ({ result, isLoading, order, onRegenerate }) => {
    if (!isLoading && !result) return null;

    const isDateValid = (dateDumped?: string, pickupDate?: string | null) => {
        if (!dateDumped || !pickupDate) return true;
        const dumpDate = parseISO(dateDumped);
        const pickup = parseISO(pickupDate);
        const daysDiff = differenceInDays(dumpDate, pickup);
        // The dump date should be exactly the same as pickup date or one day after
        return daysDiff === 0 || daysDiff === 1;
    };

    const dumpTicket = result?.results?.type === 'dump-ticket' ? result.results.dumpTicket : null;
    const isDateInvalid =
        dumpTicket?.dateDumped && order?.expectedPickupDate && !isDateValid(dumpTicket.dateDumped, order.expectedPickupDate);

    return (
        <div className={cn('bg-white, my-4 rounded-lg border border-gray-200 shadow-sm transition-all', isLoading ? 'p-0' : 'px-4 pt-4')}>
            <AnimatePresence mode="wait">
                {isLoading ? (
                    <motion.div
                        key="loading"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="relative h-48"
                    >
                        <CanvasRevealEffect
                            animationSpeed={0.6}
                            colors={[
                                [0, 178, 206], // #00b2ce (300)
                                [0, 117, 135], // #007587 (400)
                            ]}
                            opacities={[0.2, 0.2, 0.2, 0.3, 0.3, 0.3, 0.4, 0.4, 0.4, 0.5]}
                            dotSize={3}
                            showGradient={false}
                        />
                        <div className="absolute inset-0 flex items-center justify-center">
                            <div
                                className="h-8 w-8 animate-spin rounded-full border-4 border-solid border-[#00b2ce] border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                role="status"
                            >
                                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                    Loading...
                                </span>
                            </div>
                        </div>
                    </motion.div>
                ) : result?.results?.type === 'dump-ticket' ? (
                    <motion.div
                        key="results"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div className="flex items-start gap-3">
                            <RiSparklingFill className="mt-0.5 h-5 w-5 animate-pulse text-[#00b2ce]" />
                            <div className="flex-1 space-y-3">
                                {!dumpTicket?.isDumpTicket ? (
                                    <div className="flex items-center gap-2.5 rounded-md bg-red-50 px-3 py-2 text-red-600">
                                        <RiCloseCircleFill className="h-5 w-5 flex-shrink-0" />
                                        <span>The uploaded image does not appear to be a dump ticket</span>
                                    </div>
                                ) : (
                                    <div className="flex flex-wrap items-center gap-x-8 gap-y-2 text-sm">
                                        {dumpTicket.tonsDumped && (
                                            <div className="flex items-center gap-2 font-medium">
                                                <span className="text-gray-500">Tons Dumped:</span>
                                                <span>{dumpTicket.tonsDumped}</span>
                                            </div>
                                        )}
                                        {dumpTicket.facilityName && (
                                            <div className="flex items-center gap-2 font-medium">
                                                <span className="text-gray-500">Facility:</span>
                                                <span>{dumpTicket.facilityName}</span>
                                            </div>
                                        )}
                                        {dumpTicket.dateDumped && (
                                            <div className={cn('flex items-center gap-2 font-medium', isDateInvalid && 'text-red-600')}>
                                                <span className={cn('text-gray-500', isDateInvalid && 'text-red-600')}>Dump Date:</span>
                                                <span>{format(parseISO(dumpTicket.dateDumped), 'MM/dd/yyyy')}</span>
                                                {isDateInvalid && order?.expectedPickupDate && (
                                                    <span className="text-xs text-red-600">
                                                        (I noticed this date seems off - the dump should have happened on{' '}
                                                        {format(parseISO(order.expectedPickupDate), 'MM/dd/yyyy')} or the day after)
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className="border-t border-gray-100">
                                    <div className="flex items-center justify-between">
                                        {onRegenerate && (
                                            <Button onClick={onRegenerate} className="p-1 text-gray-500 hover:text-gray-700">
                                                <ArrowPathIcon className="h-4 w-4" />
                                            </Button>
                                        )}
                                        <p className="text-xs italic text-gray-400">
                                            Note: AI analysis may not be perfect - please verify all information before proceeding.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                ) : null}
            </AnimatePresence>
        </div>
    );
};

export default OCRAnalysis;
