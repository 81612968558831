import { ReactNode, useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Invoice } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { Button, Tooltip } from '@wayste/sour-ui';
import { formatServiceAddress, formatServiceDate, moneyFormatter, round } from '@wayste/utils';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { ArrowPathIcon, EnvelopeIcon, PencilIcon, PhotoIcon, TrashIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import ButtonSelect from '../../../../components/ButtonSelect';
import { ccRate } from '../../../../utils/pricing-utils';
import { paymentMethods, paymentMethodsEnums, paymentTerms } from '../../../../utils/shared-types';
import { BillingContext } from '../../context';
import { GridCellStatus } from '../renderCellReceivablesStatus';

const tableHeadings = [
    {
        heading: 'Item',
    },
    {
        heading: 'Description',
        className: 'w-full',
    },
    {
        heading: 'Qty',
        className: 'max-w-[62px]',
    },
    {
        heading: 'Rate',
    },
    {
        heading: 'Amount',
    },
    {
        heading: 'Tax',
        className: 'text-center max-w-[45px]',
    },
];

const tableData = [
    {
        key: 'item',
        value: (item: Invoice.LineItemTransport) => item.itemName,
        className: 'border-l-0 whitespace-nowrap',
    },
    {
        key: 'description',
        value: (item: Invoice.LineItemTransport) => <div> {item.description}</div>,
        className: 'w-full',
    },
    {
        key: 'quantity',
        value: (item: Invoice.LineItemTransport) => item.quantity,
        className: 'text-right max-w-[62px]',
    },
    {
        key: 'rate',
        value: (item: Invoice.LineItemTransport) => (
            <div className="flex justify-between">
                <div>$</div>
                {moneyFormatter(round(item.unitPrice || 0, item.quantity > 1 ? 5 : 2), {
                    hideDollarSign: true,
                })}
            </div>
        ),
        className: 'pl-1.5 pr-2.5 min-w-[117px] max-w-[117px] text-right',
    },
    {
        key: 'amount',
        value: (item: Invoice.LineItemTransport) => (
            <div className="flex justify-between">
                <div>$</div>
                {moneyFormatter(item.totalPrice || 0, {
                    hideDollarSign: true,
                })}
            </div>
        ),
        className: 'pl-1.5 pr-2.5 min-w-[117px] max-w-[117px] text-right',
    },
    {
        key: 'tax',
        value: (item: Invoice.LineItemTransport) => (item.taxable ? 'Y' : null),
        className: 'text-center max-w-[45px]',
    },
];

interface Props {
    receivable: Invoice.ReceivableTransport;
    receivableIndex: number;
    onCreateReceipt: () => void;
    onOrderImageClicked: (value: boolean) => void;
    onEditInvoiceClicked: (value: boolean) => void;
    onLineItemClicked: (value: { item: Invoice.LineItemTransport; index: number } | null) => void;
    onRefundClick: () => void;
}

const ReceivableCard = ({
    receivable,
    receivableIndex,
    onCreateReceipt,
    onOrderImageClicked,
    onEditInvoiceClicked,
    onLineItemClicked,
    onRefundClick,
}: Props) => {
    const client = useWaysteClient();
    const { setShowToast, useConfirmationDialog, godModeActive } = useSourContext();
    const { getConfirmation } = useConfirmationDialog();
    const { handleReceivableChanges, selectedOrder, selectedServiceGrouping, hasChanges, customer, handleVoidIssuedInvoice } =
        useContext(BillingContext);
    const [invoiceStatus, setInvoiceStatus] = useState<Invoice.InvoiceStatus | null>(null);
    const [invoiceCCRate, setInvoiceCCRate] = useState(ccRate);
    const [ccLineItem, setCCLineItem] = useState<Invoice.LineItemTransport | null>(null);

    useEffect(() => {
        setInvoiceStatus(receivable.invoiceDetails.status);
        const found = receivable.invoiceDetails.lineItems.find((item) => item.itemName === 'CC Fee');
        setCCLineItem(found || null);
        setInvoiceCCRate(found ? ccRate : 0);
    }, [receivable.invoiceDetails.status, receivable.invoiceDetails.lineItems, selectedOrder, client]);

    const getPaymentStatus = () => {
        if (!invoiceStatus) return null;

        const statusDisplay: Record<Invoice.InvoiceStatus, ReactNode> = {
            DRAFT: <span className="text-sourgum-greyblue-400">Draft</span>,
            PAID: <span className="text-success">Paid</span>,
            PENDING: receivable?.invoiceDetails?.issueDate
                ? `Invoiced on ${format(formatServiceDate(receivable.invoiceDetails.issueDate.slice(0, 10), 'date'), 'MM/dd/yyyy')}`
                : '',
            PAST_DUE: (
                <span className="text-red-500">
                    Past Due - Invoiced on{' '}
                    {receivable.invoiceDetails.issueDate
                        ? format(formatServiceDate(receivable.invoiceDetails.issueDate?.slice(0, 10), 'date'), 'MM/dd/yyyy')
                        : ''}
                </span>
            ),
            VOID: <span className="text-red-500">Void</span>,
            PARTIALLY_PAID: <span className="text-warning">Partially Paid</span>,
            PARTIALLY_REFUNDED: <span className="text-warning">Partially Refunded</span>,
            PARTIALLY_VOID: <span className="text-warning">Partially Voided</span>,
            REFUNDED: <span className="text-warning">Refunded</span>,
        };

        return statusDisplay[invoiceStatus];
    };

    const paymentLineText = (payment: Invoice.PaymentTransport) => {
        let text = '';
        text += payment.paymentReceivedDate
            ? format(new Date(payment.paymentReceivedDate), 'MM/dd/yy')
            : format(new Date(payment.date), 'MM/dd/yy');
        switch (payment.paymentMethod) {
            case paymentMethodsEnums.creditCard:
                text += ' CC';
                break;
            case paymentMethodsEnums.stripe:
                text += ' CC';
                break;
            case paymentMethodsEnums.check:
                text += ' CHK';
                break;
            case paymentMethodsEnums.accountCredit:
                text += ' ACC CREDIT';
                break;
            case paymentMethodsEnums.ach:
                text += ' ACH';
                break;
            case paymentMethodsEnums.revenueShare:
                text += ` REVENUE SHARE`;
                break;
            default:
                text += ' Unknown (Please contact a manager)';
                console.error('Error, unknown payment type.', payment);
                break;
        }
        if (payment.paymentIdentifier) {
            text += ' - ' + payment.paymentIdentifier;
        }
        return text;
    };

    const handleChangeCC = async (value: number) => {
        if (value === invoiceCCRate) return;

        // grab the invoice we are editing
        const receivableCopy = JSON.parse(JSON.stringify(receivable)) as Invoice.ReceivableTransport;

        const keepTotal = await getConfirmation({
            title: 'Keep Current Invoice Total?',
            message: 'Do you want to keep the existing invoice total as is OR do you want to change the total to reflect the new CC Fee?',
            confirmText: 'Keep Total',
            cancelText: 'Change Total',
        });

        if (!keepTotal) {
            if (value === 0) {
                receivableCopy.invoiceDetails.lineItems = receivableCopy.invoiceDetails.lineItems.filter(
                    (item) => item.itemName !== 'CC Fee',
                );
            } else if (value === ccRate && !receivableCopy.invoiceDetails.lineItems.find((item) => item.itemName === 'CC Fee')) {
                const calculatedCCPrice = receivableCopy.invoiceDetails.total / (1 - ccRate) - receivableCopy.invoiceDetails.total;
                receivableCopy.invoiceDetails.lineItems.push({
                    description: '',
                    itemName: 'CC Fee',
                    quantity: 1,
                    taxable: false,
                    totalPrice: calculatedCCPrice,
                    unitPrice: calculatedCCPrice,
                    id: '',
                });
            }

            handleReceivableChanges(receivableCopy, receivableIndex);
        } else if (keepTotal) {
            const currentTotal = receivableCopy.invoiceDetails.total;

            if (value === 0) {
                const ccLineItem = receivableCopy.invoiceDetails.lineItems.find((item) => item.itemName === 'CC Fee');
                // Never had a CC Fee
                if (!ccLineItem) return;
                const creditCardFee = ccLineItem.totalPrice || 0;
                receivableCopy.invoiceDetails.lineItems = receivableCopy.invoiceDetails.lineItems.filter(
                    (item) => item.itemName !== 'CC Fee',
                );

                const taxRate = receivable.invoiceDetails.taxRate || 0;
                const totalWithoutCreditCard = currentTotal - creditCardFee;

                const newLineItems = receivableCopy.invoiceDetails.lineItems.map((lineItem) => {
                    let proportion;
                    let newTotalPrice;
                    if (lineItem.taxable) {
                        const adjustedTotal = Math.round((lineItem.totalPrice || 0) * (1 + taxRate));
                        proportion = adjustedTotal / totalWithoutCreditCard;

                        const newAdjustedTotal = Math.round(adjustedTotal + proportion * creditCardFee);
                        newTotalPrice = Math.round(newAdjustedTotal / (1 + taxRate));
                    } else {
                        proportion = (lineItem.totalPrice || 0) / totalWithoutCreditCard;
                        newTotalPrice = (lineItem.totalPrice || 0) + Math.round(proportion * creditCardFee);
                    }
                    return {
                        ...lineItem,
                        unitPrice: Math.round(newTotalPrice / lineItem.quantity),
                        totalPrice: newTotalPrice,
                    };
                });

                receivableCopy.invoiceDetails.lineItems = newLineItems;
            } else {
                const calculatedCCFee = Math.round(currentTotal * ccRate);
                const newLineItems = receivableCopy.invoiceDetails.lineItems.map((lineItem) => {
                    const newTotalPrice = (lineItem.totalPrice || 0) - (lineItem.totalPrice || 0) * ccRate;
                    return {
                        ...lineItem,
                        unitPrice: newTotalPrice / lineItem.quantity,
                        totalPrice: newTotalPrice,
                    };
                });

                // save new line item and add it to invoice
                newLineItems.push({
                    description: '',
                    itemName: 'CC Fee',
                    quantity: 1,
                    taxable: false,
                    totalPrice: calculatedCCFee,
                    unitPrice: calculatedCCFee,
                    id: '',
                });

                receivableCopy.invoiceDetails.lineItems = newLineItems;
            }

            handleReceivableChanges(receivableCopy, receivableIndex);

            if (currentTotal !== receivable.invoiceDetails.total) {
                setShowToast({
                    severity: 'warning',
                    message: 'Please double check the values are as expected, otherwise contact Luc.',
                });
            }
        }
    };

    // HANDLE INVOICE/RECEIPT PDF DOWNLOAD
    const handleDownload = () => {
        (async () => {
            if (receivable) {
                const response = await client.invoice().adminPortal.receivable.pdf.fetch(receivable.id);
                const blob = new Blob([response], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `receivable-${selectedOrder?.orderNumber}-${receivable?.invoiceDetails.invoiceNumber}.pdf`;
                a.click();
            }
        })();
    };

    const handleDeleteInvoice = async () => {
        // mark invoice as void
        if (receivable.id && receivable.invoiceDetails.issueDate) {
            // handle this specifaclly
            handleVoidIssuedInvoice(receivable.id);
        } else {
            receivable.invoiceDetails.void = true;
            receivable.invoiceDetails.status = 'VOID';
            handleReceivableChanges(receivable, receivableIndex);
        }
    };

    const handleChangeTax = async (taxRate: number) => {
        const receivableCopy = JSON.parse(JSON.stringify(receivable)) as Invoice.ReceivableTransport;
        receivableCopy.invoiceDetails.taxRate = taxRate;

        const keepTotal = await getConfirmation({
            title: 'Keep Current Invoice Total?',
            message: 'Do you want to keep the existing invoice total as is OR do you want to change the total to reflect the new CC Fee?',
            confirmText: 'Keep Total',
            cancelText: 'Change Total',
        });
        if (keepTotal) {
            const currentTotal = receivableCopy.invoiceDetails.total;
            const totalWithoutTax = receivableCopy.invoiceDetails.lineItems.reduce((total, lineItem) => {
                return total + (lineItem.totalPrice || (lineItem.unitPrice || 0) * lineItem.quantity);
            }, 0);
            const taxableTotal = receivableCopy.invoiceDetails.lineItems.reduce((total, lineItem) => {
                if (!lineItem.taxable) return total;
                return total + (lineItem.totalPrice || (lineItem.unitPrice || 0) * lineItem.quantity);
            }, 0);

            const difference = currentTotal - totalWithoutTax;
            const adjustmentRate = difference / taxableTotal + 1;

            if (taxRate === 0) {
                // tax is being set to 0 & line items need to increase proportionally
                const newLineItems = receivableCopy.invoiceDetails.lineItems.map((lineItem) => {
                    if (!lineItem.taxable) return lineItem;

                    const newTotalPrice = (lineItem.totalPrice || 0) * adjustmentRate;

                    return {
                        ...lineItem,
                        unitPrice: Math.round(newTotalPrice / lineItem.quantity),
                        totalPrice: Math.round(newTotalPrice),
                    };
                });

                receivableCopy.invoiceDetails.lineItems = newLineItems;

                handleReceivableChanges(receivableCopy, receivableIndex);
                return;
            }

            // tax is being set to the taxRate have to decrease line items

            const newLineItems = receivable.invoiceDetails.lineItems.map((lineItem) => {
                if (!lineItem.taxable) return lineItem;

                const newTotalPrice = (lineItem.totalPrice || 0) / (1 + taxRate);

                return {
                    ...lineItem,
                    unitPrice: Math.round(newTotalPrice / lineItem.quantity),
                    totalPrice: Math.round(newTotalPrice),
                };
            });

            receivable.invoiceDetails.lineItems = newLineItems;
            handleReceivableChanges(receivable, receivableIndex);
        }

        // remove tax from invoice without keeping the total
        if (taxRate === 0) {
            receivable.invoiceDetails.taxRate = 0;
            receivable.invoiceDetails.taxAmount = 0;

            handleReceivableChanges(receivable, receivableIndex);
            return;
        }

        // tax rate is not 0 so we need to calculate the tax amount and add it to the invoice
        const taxableTotal = receivable.invoiceDetails.lineItems.reduce((total, lineItem) => {
            if (!lineItem.taxable) return total;

            return total + (lineItem.totalPrice || (lineItem.unitPrice || 0) * lineItem.quantity);
        }, 0);

        const taxAmount = taxableTotal * taxRate;

        receivable.invoiceDetails.taxRate = taxRate;
        receivable.invoiceDetails.taxAmount = Math.ceil(taxAmount);

        handleReceivableChanges(receivable, receivableIndex);
    };

    const actionIcons = [
        {
            icon: ArrowDownTrayIcon,
            click: () => handleDownload(),
            className: 'text-black opacity-60',
            tip: 'Download receivable/receipt',
        },
        {
            icon: EnvelopeIcon,
            click: onCreateReceipt,
            tip: hasChanges ? 'Save or cancel your changes first' : 'Email receivable/receipt',
            disabled: hasChanges,
        },
        {
            icon: PhotoIcon,
            click: () => onOrderImageClicked(true),
            tip: 'View images',
        },
        {
            icon: PencilIcon,
            click: () => onEditInvoiceClicked(true),
            className: 'text-edit',
            tip: hasChanges ? 'Save or cancel your changes first to click edit' : 'Edit receivable',
            disabled: hasChanges || receivable.invoiceDetails.void || (receivable.invoiceDetails.paidInFull && !godModeActive),
        },
        {
            icon: TrashIcon,
            click: handleDeleteInvoice,
            className: 'text-delete',
            disabled:
                receivable.invoiceDetails.total - receivable.invoiceDetails.remainingBalance > 0 ||
                receivable.invoiceDetails.void ||
                hasChanges,
            tip:
                hasChanges && receivable.invoiceDetails.issueDate
                    ? "You cannot void an invoice as you're editing it or others"
                    : 'Delete receivable',
        },
    ];

    return (
        <div className="shadow-dark my-4 rounded bg-white">
            <div className="px-5 pb-4 pt-2.5">
                <div className="flex items-center justify-between border-b pb-2">
                    <div className="mr-1.5 text-lg">
                        <span className="opacity-50">INVOICE</span>
                        <span className="mx-1.5">
                            #{receivable.invoiceDetails.orderNumber || selectedServiceGrouping?.orderNumber || selectedOrder?.orderNumber} -{' '}
                            {receivable.invoiceDetails.invoiceNumber}
                        </span>
                    </div>
                    <div>{formatServiceAddress(selectedOrder?.serviceLocation?.address)}</div>
                    <div className="flex gap-1">
                        {actionIcons.map((icon) => (
                            <Tooltip text={icon.tip} key={icon.tip}>
                                <span>
                                    <button className="btn-icon" type="button" onClick={icon.click} disabled={icon?.disabled}>
                                        <icon.icon className={`size-6 ${icon.className} ${icon?.disabled ? '!text-slate-300' : ''}`} />
                                    </button>
                                </span>
                            </Tooltip>
                        ))}
                    </div>
                </div>
            </div>
            <div className="relative w-full overflow-x-auto">
                {receivable.invoiceDetails.void && (
                    <div className="absolute inset-0 flex items-center justify-center bg-red-50 bg-opacity-30 text-5xl text-red-500">
                        VOIDED
                    </div>
                )}
                <table className="w-auto border-collapse border-spacing-0 text-sm">
                    <thead>
                        <tr className="border-b [&>*]:px-4 [&>*]:py-1.5 [&>*]:font-normal">
                            {tableHeadings.map((heading) => (
                                <th key={heading.heading} className={`text-left opacity-50 ${heading.className || ''}`}>
                                    {heading.heading}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {receivable.invoiceDetails.lineItems.map((item, index) => {
                            if (item.itemName === 'CC Fee') return null;
                            return (
                                <tr
                                    key={item.itemName + index}
                                    className={`border-b even:bg-gray-100 [&>*]:px-4 [&>*]:py-1.5 ${
                                        receivable.invoiceDetails.total - receivable.invoiceDetails.remainingBalance > 0
                                            ? 'cursor-default'
                                            : 'cursor-pointer'
                                    }`}
                                    onClick={() => {
                                        if (receivable.invoiceDetails.status !== 'DRAFT' && !godModeActive) return;
                                        onLineItemClicked({ item: item, index: index });
                                    }}
                                >
                                    {tableData.map((cell) => (
                                        <td className={`border-l ${cell.className || ''}`} key={cell.key}>
                                            {cell.value(item)}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })}

                        {/* Add Charge && Sales Tax */}
                        <tr className="[&>*]:px-4">
                            <td>
                                <Button
                                    className="btn-primary-text-only whitespace-nowrap"
                                    onClick={() => {
                                        onLineItemClicked(null);
                                    }}
                                    disabled={
                                        (receivable.invoiceDetails.status !== 'DRAFT' || receivable.invoiceDetails.void) && !godModeActive
                                    }
                                >
                                    Add Charge
                                </Button>
                            </td>

                            <td colSpan={2} className="py-1.5 text-right">
                                <ButtonSelect
                                    value={receivable.invoiceDetails.taxRate}
                                    onSelect={handleChangeTax}
                                    menuItems={[
                                        {
                                            label: `${selectedOrder?.serviceLocation?.address?.state} Sales Tax`,
                                            value: selectedOrder?.taxRate || 0,
                                        },
                                        { label: 'None', value: 0 },
                                    ]}
                                    btnClassName="text-xs w-[115px] justify-between pl-[5px] pr-0.5"
                                    disabled={
                                        (receivable.invoiceDetails.status !== 'DRAFT' || receivable.invoiceDetails.void) && !godModeActive
                                    }
                                />
                            </td>

                            <td className="text-right">{receivable.invoiceDetails.taxRate * 100}%</td>

                            <td className="min-w-[117px] max-w-[117px] pl-1.5 pr-2.5 text-right">
                                {receivable.invoiceDetails.taxAmount > 0 ? (
                                    <div className="flex justify-between">
                                        <div>$</div>
                                        {moneyFormatter(receivable.invoiceDetails.taxAmount, {
                                            hideDollarSign: true,
                                        })}
                                    </div>
                                ) : customer?.taxExempt ? (
                                    'EXEMPT'
                                ) : (
                                    <div className="flex justify-between">
                                        <div>$</div>
                                        <div>0</div>
                                    </div>
                                )}
                            </td>
                        </tr>

                        {/* CC Fee */}
                        <tr className="[&>*]:px-4">
                            <td colSpan={1} className="text-left">
                                Memo: {receivable.invoiceDetails.memo}
                            </td>
                            <td colSpan={2} className="text-right">
                                <ButtonSelect
                                    value={invoiceCCRate}
                                    onSelect={handleChangeCC}
                                    menuItems={[
                                        { label: 'CC Fee', value: ccRate },
                                        { label: 'None', value: 0 },
                                    ]}
                                    btnClassName="text-xs w-[115px] justify-between pl-[5px] pr-0.5"
                                    disabled={
                                        (receivable.invoiceDetails.status !== 'DRAFT' || receivable.invoiceDetails.void) && !godModeActive
                                    }
                                />
                            </td>
                            <td className="text-right">{invoiceCCRate * 100}%</td>
                            <td className="min-w-[117px] max-w-[117px] pl-1.5 pr-2.5 text-right">
                                <div className="flex justify-between">
                                    <div>$</div>
                                    {ccLineItem
                                        ? moneyFormatter(ccLineItem.totalPrice || 0, {
                                              hideDollarSign: true,
                                          })
                                        : 0}
                                </div>
                            </td>
                        </tr>

                        {/* Invoice Total */}
                        <tr className="[&>*]:px-4">
                            <td colSpan={2} className="text-left">
                                Internal Notes: {receivable.invoiceDetails.internalNotes}
                            </td>
                            <td colSpan={2} className="text-right">
                                Total
                            </td>
                            <td className="min-w-[117px] max-w-[117px] pl-1.5 pr-2.5 text-right">
                                <div className="flex justify-between">
                                    <div>$</div>
                                    {moneyFormatter(receivable.invoiceDetails.total, {
                                        hideDollarSign: true,
                                    })}
                                </div>
                            </td>
                        </tr>

                        {/* Amount Paid && Terms */}
                        <tr className="[&>*]:px-4">
                            <td colSpan={2}>
                                <span className="opacity-50">Terms:</span>{' '}
                                {paymentTerms[selectedOrder?.paymentTerm as keyof typeof paymentTerms]} -{' '}
                                {paymentMethods[selectedOrder?.paymentMethod as keyof typeof paymentMethods]}
                            </td>
                            <td className="text-right" colSpan={2}>
                                {receivable.invoiceDetails.payments.map((payment) => (
                                    <span key={payment.id} className="whitespace-nowrap">
                                        {paymentLineText(payment)} <br />
                                    </span>
                                ))}
                                <span>Total Paid</span>
                            </td>
                            <td className="min-w-[117px] max-w-[117px] pl-1.5 pr-2.5 text-right">
                                {receivable.invoiceDetails.payments.map((payment, index) => (
                                    <div className="flex justify-between" key={index}>
                                        <div>$</div>
                                        {moneyFormatter(payment.amount, {
                                            hideDollarSign: true,
                                        })}
                                    </div>
                                ))}
                                <div className="flex justify-between">
                                    <div>$</div>
                                    {moneyFormatter(receivable.invoiceDetails.total - receivable.invoiceDetails.remainingBalance, {
                                        hideDollarSign: true,
                                    })}
                                </div>
                            </td>
                            <td>
                                {receivable.invoiceDetails.total - receivable.invoiceDetails.remainingBalance > 0 && (
                                    <Tooltip text={'Refund payment'}>
                                        <button
                                            type="button"
                                            className="btn-icon"
                                            onClick={onRefundClick}
                                            disabled={hasChanges || receivable.invoiceDetails.void}
                                        >
                                            <ArrowPathIcon className="size-5" />
                                        </button>
                                    </Tooltip>
                                )}
                            </td>
                        </tr>

                        {/* Amount Due */}
                        <tr className="[&>*]:px-4 [&>*]:py-0">
                            <td colSpan={3}>
                                <div className="mb-1 flex gap-2">
                                    {getPaymentStatus()}
                                    <GridCellStatus value={receivable.invoiceDetails.status} />
                                </div>
                            </td>
                            <td className="text-right">Due</td>
                            <td className="min-w-[117px] max-w-[117px] pl-1.5 pr-2.5 text-right">
                                <div className="flex justify-between">
                                    <div>$</div>
                                    {moneyFormatter(receivable.invoiceDetails.remainingBalance, {
                                        hideDollarSign: true,
                                    })}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ReceivableCard;
