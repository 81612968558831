import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Duration, Hauler, Invoice, Material, MaterialLabels, Order, Pricing } from '@alliance-disposal/transport-types';
import { Button, Dialog, Loading, Toggle } from '@wayste/sour-ui';
import { determineWaysteActive, formatServiceAddress, getDispatchEmailsString, pricingBreakdownTotal } from '@wayste/utils';
import { WaysteActivity } from '@wayste/utils';
import { sendEmail as sendEmailSES } from '../../axios/ses';
import { UIContext, useConfirmationDialog } from '../../contexts';
import {
    ccRate,
    createHaulerOrderCancellationEmail,
    createHaulerOrderConfirmationEmail,
    paymentMethodsEnums,
    paymentTermsEnums,
    priceTypes,
    quotedPriceItems,
} from '../../utils';
import SpreadsTable, { VendorPricingRow } from '../SpreadsTable/SpreadsTable';

interface Props {
    order: Order.AllianceOrderTransport;
    open: boolean;
    onCancel: () => void;
    onSubmitSuccessful: () => void;
}

export type HaulerPricingWithHauler = Pricing.PricingTransport & {
    hauler: Hauler.HaulerWithAapTransport;
    waysteUsage: WaysteActivity;
};

const OrderAssignHauler = ({ order, open, onCancel, onSubmitSuccessful }: Props) => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const { getConfirmation } = useConfirmationDialog();
    const [haulerID, setHaulerID] = useState('');
    const [rawPricingWithHaulers, setRawPricingWithHaulers] = useState<HaulerPricingWithHauler[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [haulerEmail, setHaulerEmail] = useState<null | string>(null);
    const [isLoadingInvoices, setIsLoadingInvoices] = useState<boolean>(true);
    const [receivables, setReceivables] = useState<Invoice.ReceivableTransport[]>([]);
    const [pricingSnapshot, setPricingSnapshot] = useState<Order.VendorPricingSnapshotTransportCreate | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleGetPricing = async () => {
        setIsLoading(true);

        const pricingResults: Pricing.PricingTransport[] = await client.pricing().adminPortal.location.query({
            ...order.serviceLocation.coordinates,
            zip: order.serviceLocation.address.zip,
            state: order.serviceLocation.address.state,
        });

        const haulerPricing: HaulerPricingWithHauler[] = [];

        const haulerPricingPromises = pricingResults.map(async (item) => {
            if (item.haulerID && item.type === 'SOURGUM_INTERNAL') {
                try {
                    // for anyone who comes across this, this syntax is called destructuring assignment
                    // basically I'm first destructuring the array returned from the promise
                    // then I'm destructuring the results from the paginated result and renaming it to haulerWaysteActivity
                    const [hauler, { results: haulerWaysteActivity }] = await Promise.all([
                        client.vendorService().adminPortal.fetch(item.haulerID),
                        client.vendorService().adminPortal.activity.query({
                            haulerID: item.haulerID,
                        }),
                    ]);

                    if (hauler && hauler.active) {
                        haulerPricing.push({ ...item, hauler, waysteUsage: determineWaysteActive(hauler, haulerWaysteActivity) });
                    }
                } catch (error) {
                    console.error('error: ', error);
                    showFlash('An error occurred fetching hauler pricing', 'warning');
                }
            }
            return null;
        });

        await Promise.all(haulerPricingPromises);

        setRawPricingWithHaulers(haulerPricing);
        setIsLoading(false);
    };

    useEffect(() => {
        if (order.haulerID) setHaulerID(order.haulerID);
        handleGetPricing();
    }, []);

    useEffect(() => {
        const fetchReceivables = async () => {
            try {
                const data = await client.invoice().adminPortal.receivable.query({
                    orderID: order.id,
                });
                setReceivables(data.results);
            } catch (error) {
                console.error('error: ', error);
            } finally {
                setIsLoadingInvoices(false);
            }
        };

        fetchReceivables();
    }, [order]);

    if (isLoadingInvoices) {
        return <Loading />;
    }

    const handleSubmit = async () => {
        setIsSubmitting(true);
        const haulerPricing = rawPricingWithHaulers.find((item) => item.haulerID === haulerID);
        const pricing = haulerPricing?.pricingData.find((item) => item.material === order.material) || null;
        const sizePricing = pricing?.sizes ? pricing.sizes.find((item) => +item.size === +order.expectedSize.size) : null;
        const haulerPricingSnapshot: Order.HaulerPricingSnapshotTransport = {
            dump: sizePricing?.dump || 0,
            over: sizePricing?.over || 0,
            haul: sizePricing?.haul || 0,
            size: Number(sizePricing?.size) || order.expectedSize.size,
            tonLimit: { value: sizePricing?.tonLimit || 0, unit: 'TONS' },
            rentalPeriod: haulerPricing?.rentalPeriod
                ? (haulerPricing?.rentalPeriod as Duration)
                : ({ value: 0, unit: 'DAYS' } as Duration),
            rentalExtensionFee: Math.round(haulerPricing?.rentExtensionFee || 0),
            priceType: pricing?.type || priceTypes.ton,
            paymentTerm: paymentTermsEnums.net30,
            paymentMethod: haulerPricing?.hauler.defaultPaymentMethod || paymentMethodsEnums.check,
        };

        const orderUpdateObject: Order.AllianceOrderUpdateInput = {
            haulerDumpRate: sizePricing?.dump ? +sizePricing?.dump : 0,
            haulerHaulRate: sizePricing?.haul ? +sizePricing?.haul : 0,
            haulerID: haulerID,
            status: 'ASSIGNED',
            haulerPricingSnapshot,
            vendorName: haulerPricing?.haulerName || undefined,
            orderAssignmentTableSnapshot: pricingSnapshot,
        };

        try {
            await client.order().adminPortal.update(order.id, orderUpdateObject);
            showFlash('Order Successfully Updated', 'success');
        } catch (error) {
            setIsSubmitting(false);
            alert('Order did not update, no email sent');
            return;
        }

        if (sendEmail && haulerEmail && !pricing) {
            alert('No pricing found for this hauler, no email sent');
            setIsSubmitting(false);
            return;
        }

        const haulerEmailData =
            sendEmail && haulerEmail && pricing && haulerPricing?.hauler
                ? createHaulerOrderConfirmationEmail(order, haulerPricing.hauler, pricing)
                : undefined;

        // Send email to hauler
        if (haulerEmailData) {
            try {
                await sendEmailSES('send-email', haulerEmailData);
            } catch (error) {
                console.error('error: ', error);
                alert('Error sending hauler email, no email sent');
            }
        }

        // Prompt User to Send email to old hauler that order was cancelled
        if (order.haulerID && order.haulerID !== orderUpdateObject.haulerID) {
            const shouldSendCancellationEmail = await getConfirmation({
                title: 'Send Email to Old Hauler?',
                message: 'Do you want to send a cancellation email to the hauler you are taking this order away from?',
            });
            try {
                if (shouldSendCancellationEmail) {
                    const hauler = await client.vendorService().adminPortal.fetch(order.haulerID);
                    if (!hauler) {
                        alert('No previous hauler found, no email sent');
                        return;
                    }
                    const dispatchEmailsString = getDispatchEmailsString(hauler?.contacts);
                    if (!dispatchEmailsString) {
                        showFlash('No hauler email exists, cancellation email not sent', 'error');
                        return;
                    }

                    const haulerEmailData = createHaulerOrderCancellationEmail(hauler, order);
                    try {
                        await sendEmailSES('send-email', haulerEmailData);
                    } catch (error) {
                        console.warn('error: ', error);
                        alert('Error sending cancellation email, no email sent');
                        return;
                    }
                }
            } catch (error) {
                console.warn('error: ', error);
                showFlash('No hauler email exists, cancellation email not sent', 'error');
                return;
            }
        }

        onSubmitSuccessful();
    };

    const handleHaulerSelected = (pricing: VendorPricingRow, pricingSnapshot?: Order.VendorPricingSnapshotTransportCreate) => {
        setIsLoading(true);
        const dispatchEmailsString = getDispatchEmailsString(pricing.hauler.contacts);
        if (dispatchEmailsString) {
            setHaulerEmail(dispatchEmailsString);
            setSendEmail(true);
        } else {
            setHaulerEmail(null);
            setSendEmail(false);
        }
        setPricingSnapshot(pricingSnapshot);
        setHaulerID(pricing.hauler.id);
        setIsLoading(false);
    };

    return (
        <Dialog open={open} styledTitle="Assign Hauler" onClose={onCancel} className="!max-w-5xl">
            <div className="flex flex-col">
                <div>Assign hauler to:</div>
                <div className="my-2">{formatServiceAddress(order.serviceLocation.address)}</div>
                <div>
                    <>
                        {order.expectedSize.size} YD For {MaterialLabels[order.material as Material]} -{' '}
                        {priceTypes[order.priceType as keyof typeof priceTypes]} Pricing
                        {(Number(order.weightLimit?.value) || 0) >= 0 ? ` - ${order.weightLimit?.value} Ton Limit` : null}
                    </>
                </div>
                <div className="mt-8">
                    <div className="mb-7">
                        <Toggle
                            label="Send confirmation email to hauler"
                            value={sendEmail}
                            onChange={(value) => setSendEmail(value)}
                            disabled={!haulerEmail}
                        />
                    </div>
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <div className="mt-4">
                            <SpreadsTable
                                haulerWithPricing={rawPricingWithHaulers}
                                selectedMaterial={order.material as Material}
                                selectedSize={order.expectedSize.size}
                                selectedHauler={haulerID}
                                orderID={order.id}
                                onRowClick={handleHaulerSelected}
                                sourgumPricing={{
                                    haul:
                                        receivables
                                            .sort(
                                                (a, b) => +(a.invoiceDetails.invoiceNumber || '') - +(b.invoiceDetails.invoiceNumber || ''),
                                            )[0]
                                            ?.invoiceDetails.lineItems.find((item) => item.itemName === quotedPriceItems.haul)?.unitPrice ||
                                        0,

                                    dump: order.dumpRate || 0,
                                    tonLimit: order.weightLimit?.value || 0,
                                    overage: pricingBreakdownTotal(
                                        order.overage || 0,
                                        1,
                                        0,
                                        receivables
                                            .sort(
                                                (a, b) => +(a.invoiceDetails.invoiceNumber || '') - +(b.invoiceDetails.invoiceNumber || ''),
                                            )[0]
                                            ?.invoiceDetails.lineItems.find((item) => item.itemName === 'CC Fee')
                                            ? ccRate
                                            : 0,
                                    ).unitPriceDollars, // unitPriceDollars is actually in cents
                                }}
                            />
                        </div>
                    )}
                </div>
                <div className="flex justify-end gap-4 pt-8">
                    <button className="btn-dark-grey-outlined" type="button" onClick={onCancel} disabled={isLoading || isSubmitting}>
                        Cancel
                    </button>
                    <Button
                        className="btn-primary"
                        type="button"
                        disabled={!haulerID}
                        onClick={() => handleSubmit()}
                        loading={isLoading || isSubmitting}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default OrderAssignHauler;
