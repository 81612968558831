import { useContext, useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { type Invoice, MaterialLabels, type Order } from '@alliance-disposal/transport-types';
import { SourFiltersOld, SourSearchOld, SourSearchResponseOld, SourSearchWrapperOld } from '@wayste/sour-search';
import { SocketError } from '@wayste/sour-socket';
import { DataGrid, DataGridProps } from '@wayste/sour-ui';
import { formatISODateString, formatServiceAddress, getRouterPath } from '@wayste/utils';
import { useHistory } from 'react-router-dom';
import OrderStatusChanger from '../../../components/OrderStatusChanger/OrderStatusChanger';
import { UIContext } from '../../../contexts';
import { routes } from '../../../utils';
import { getOrderBillStatus2, getOrderInvoiceStatus } from '../../../utils/invoice-utils';
import { BillingContext } from '../context';
import { GridCellStatus, GridCellStatusProps } from './renderCellReceivablesStatus';

interface AllianceOrderWithReceivables extends Order.AllianceOrderTransport {
    receivables: Invoice.ReceivableTransport[];
    payables: Invoice.PayableTransport[];
}

type BillingOrder = Order.AllianceOrderTransport & {
    qb?: boolean;
    receivablesStatus: GridCellStatusProps['value'];
    payablesStatus: GridCellStatusProps['value'];
    serviceAddress: string;
    customerDisplayName: string;
};

const BillingListRolloff = () => {
    const client = useWaysteClient();
    const { showFlash } = useContext(UIContext);
    const history = useHistory();
    const { lastViewedId, setLastViewedId } = useContext(BillingContext);

    const [openInvoicesLoading, setOpenInvoicesLoading] = useState(true);
    const [invoicesCount, setInvoicesCount] = useState(0);
    const [openInvoices, setOpenInvoices] = useState<AllianceOrderWithReceivables[]>([]);

    const [filterData, setFilterData] = useState<BillingOrder[]>([]);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(25);
    const [searchActive, setSearchActive] = useState(false);
    const [filterActive, setFilterActive] = useState(false);
    const [searchResults, setSearchResults] = useState<BillingOrder[]>([]);
    const [searchResultsCount, setSearchResultsCount] = useState<number>(0);
    const [searchLoading, setSearchLoading] = useState(false);
    ///////////////////// FOR SOUR SEARCH /////////////////////
    const defaultFilter = 'sourgum-order';
    const [isDefaultFilter, setIsDefaultFilter] = useState<boolean | undefined>(true);
    const [searchPage, setSearchPage] = useState<number>(0);
    const [searchPageSize, setSearchPageSize] = useState<number>(25);

    const onNavigate = (_: unknown, recordID: string, name: string) => {
        const path = getRouterPath(name, recordID, routes);
        history.push(path);
    };

    const processHits = async (hits: SourSearchResponseOld['results']['hits']['hits']) => {
        const results = hits.map((hit) => hit._source) as Order.AllianceOrderTransport[];
        const ids = results.map((result) => result.id);

        // This prevents the query from running if there are no ids
        // which would try to fetch all invoices
        if (!ids.length) return [];

        const receivable = await client.invoice().adminPortal.receivable.query({
            orderID: ids.join(','),
            limit: 10000,
        });

        const receivablesMap: Record<string, Invoice.ReceivableTransport[]> = {};

        receivable.results.forEach((receivable: Invoice.ReceivableTransport) => {
            if (!receivable.invoiceDetails.orderID) return;
            if (receivablesMap[receivable.invoiceDetails.orderID]) {
                receivablesMap[receivable.invoiceDetails.orderID].push(receivable);
            } else {
                receivablesMap[receivable.invoiceDetails.orderID] = [receivable];
            }
        });

        const Payables = await client.invoice().adminPortal.payable.query({
            orderID: ids.join(','),
            limit: 10000,
        });

        const payablesMap: Record<string, Invoice.PayableTransport[]> = {};

        Payables.results.forEach((payable: Invoice.PayableTransport) => {
            if (!payable.invoiceDetails.orderID) return;
            if (payablesMap[payable.invoiceDetails.orderID]) {
                payablesMap[payable.invoiceDetails.orderID].push(payable);
            } else {
                payablesMap[payable.invoiceDetails.orderID] = [payable];
            }
        });
        const processed = await processOrdersForSearchTable(results, receivablesMap, payablesMap);
        return processed;
    };

    // DEFINE ON RESULTS LOGIC
    const handleSearchResults = async (response: SourSearchResponseOld) => {
        setSearchLoading(true);
        if (searchPage !== response.page) setSearchPage(response.page);
        response.type === defaultFilter ? setIsDefaultFilter(true) : setIsDefaultFilter(false);
        const res = await processHits(response.results.hits.hits);
        setSearchResultsCount(response.totalHits);
        setSearchResults(res as BillingOrder[]);

        setSearchLoading(false);
    };

    const onActiveSearch = (active: boolean) => {
        setSearchActive(active);
    };

    const onActiveFilter = (active: boolean) => {
        setFilterActive(active);
    };

    ///////////////////////////////////////////////

    useEffect(() => {
        const subscription = client.order().adminPortal.subscription.query({
            status: [
                'NEEDS_REVIEW',
                'UNASSIGNED',
                'ON_HOLD',
                'ASSIGNED',
                'DELIVERED',
                'READY_FULL_PICKUP',
                'READY_EMPTY_PICKUP',
                'PICKED_UP_FULL',
                'PICKED_UP_EMPTY',
                'DUMPED',
                'COMPLETED',
            ].join(','),
            closed: false,
            sortBy: 'orderNumber',
            sortDescending: true,
            limit: pageSize,
            offset: (page || 0) * pageSize,
        });

        const observe = subscription.receive.subscribe({
            next: async (response) => {
                const orders = await getOrdersWithInvoice(response.results);
                setOpenInvoices(orders);
                setOpenInvoicesLoading(false);
                setInvoicesCount(response.total);
            },
            error: (error) => {
                showFlash((error as SocketError).additionalInfo ?? 'An Error Occurred Getting Orders', 'warning');
            },
        });

        return () => {
            if (subscription) {
                observe.unsubscribe();
                subscription.unsubscribe();
            }
        };
    }, [page, pageSize]);

    const processOrdersForTable = async (
        passedOrders: (Order.AllianceOrderTransport & {
            receivables: Invoice.ReceivableTransport[];
            payables: Invoice.PayableTransport[];
        })[],
    ) => {
        const results = passedOrders.map((order) => ({
            ...order,
            qb: order.receivables.every((inv) => inv?.invoiceDetails?.paidInFull || inv?.invoiceDetails?.void),
            receivablesStatus: getOrderInvoiceStatus(order),
            payablesStatus: getOrderBillStatus2(order),
            serviceAddress: formatServiceAddress(order.serviceLocation.address),
            customerDisplayName: order.customerCompanyName || order.customerName,
        }));

        return results;
    };

    const processOrdersForSearchTable = async (
        passedOrders: Order.AllianceOrderTransport[],
        receivablesMap: Record<string, Invoice.ReceivableTransport[]>,
        payablesMap: Record<string, Invoice.PayableTransport[]>,
    ) => {
        const results = passedOrders.map((order) => {
            const orderExtended = {
                ...order,
                receivables: receivablesMap[order.id] || [],
                payables: payablesMap[order.id] || [],
            };
            return {
                ...orderExtended,
                receivablesStatus: getOrderInvoiceStatus(orderExtended),
                payablesStatus: getOrderBillStatus2(orderExtended),
                serviceAddress: formatServiceAddress(orderExtended.serviceLocation.address),
                customerDisplayName: order.customerCompanyName || order.customerName,
            };
        });
        return await Promise.all(results);
    };

    const getOrdersWithInvoice = async (orderData: Order.AllianceOrderTransport[]) => {
        const orderIds = orderData.map((order: Order.AllianceOrderTransport) => order.id);

        if (orderIds.length === 0) return [];

        const receivables = await client.invoice().adminPortal.receivable.query({
            orderID: orderIds.join(','),
            limit: 10000,
        });

        const payables = await client.invoice().adminPortal.payable.query({
            orderID: orderIds.join(','),
            limit: 10000,
        });

        const mapped = orderData.flatMap((order: Order.AllianceOrderTransport) => {
            const orderReceivables = receivables.results.filter((receivable) => receivable.invoiceDetails.orderID === order.id);
            const orderPayables = payables.results.filter((payable) => payable.invoiceDetails.orderID === order.id);
            return {
                ...order,
                receivables: orderReceivables,
                payables: orderPayables,
            };
        });

        return mapped;
    };

    useEffect(() => {
        (async () => {
            processOrdersForTable(openInvoices.map((item) => item)).then((processedOrders) => {
                setFilterData(processedOrders as BillingOrder[]);
            });
        })();
    }, [openInvoices]);

    ///////////////////////////////////////////////
    // TABLE SCHEMA
    ///////////////////////////////////////////////

    const columns: DataGridProps<BillingOrder>['columns'] = [
        {
            key: 'orderNumber',
            name: 'Order #',
            width: 75,
            align: 'center',
        },
        {
            key: 'status',
            name: 'Status',
            headerAlign: 'center',
            align: 'center',
            width: 130,
            formatter: (value) => <OrderStatusChanger order={value.row} navigateOnly={true} className="!h-6" />,
        },

        {
            key: 'receivablesStatus',
            name: 'Receivables',
            align: 'center',
            headerAlign: 'center',
            width: 100,
            formatter: (value) => <GridCellStatus value={value.row.receivablesStatus} />,
        },
        {
            key: 'payablesStatus',
            name: 'Payables',
            headerAlign: 'center',
            align: 'center',
            width: 100,
            formatter: (value) => <GridCellStatus value={value.row.payablesStatus} />,
        },
        {
            key: 'customerDisplayName',
            name: 'Customer',
            width: 200,
        },
        {
            key: 'serviceAddress',
            name: 'Address',
            width: 300,
        },
        {
            key: 'vendorName',
            name: 'Hauler',
            width: 200,
        },
        {
            key: 'expectedDeliveryDate',
            name: 'Delivery Date',
            align: 'center',
            headerAlign: 'center',
            width: 110,
            formatter: ({ row }) => {
                if (!row.expectedDeliveryDate) return null;
                return formatISODateString(row.expectedDeliveryDate);
            },
        },
        {
            key: 'expectedPickupDate',
            name: 'Pickup Date',
            headerAlign: 'center',
            align: 'center',
            width: 110,
            formatter: ({ row }) => {
                if (!row.expectedPickupDate) return null;
                return formatISODateString(row.expectedPickupDate);
            },
        },
        {
            key: 'material',
            name: 'Material',
            width: 180,
            formatter: ({ row }) => {
                return <span className="text-sm">{MaterialLabels[row.material as keyof typeof MaterialLabels]}</span>;
            },
        },
        {
            key: 'actualWeightDumped',
            name: 'Tons',
            headerAlign: 'center',
            align: 'center',
            formatter: ({ row }) => {
                return row.actualWeightDumped?.value || row.actualWeightDumped?.value === 0 ? row.actualWeightDumped.value : null;
            },
        },
    ];

    ///////////////////////////////////////////////
    // RENDER
    ///////////////////////////////////////////////

    return (
        <>
            <div className="item-center flex w-full flex-row justify-between py-2">
                <SourSearchWrapperOld
                    options={{
                        application: 'aap',
                        apiKey: import.meta.env.VITE_ELASTIC_KEY as string,
                        environment: import.meta.env.VITE_ELASTIC_ENVIRONMENT,
                    }}
                    onNavigate={onNavigate}
                    onResults={handleSearchResults}
                    onSearch={onActiveSearch}
                    onFilter={onActiveFilter}
                    defaultFilters={{
                        query: {
                            type: defaultFilter,
                        },
                    }}
                    size={searchPageSize}
                    page={searchPage}
                    createQueryParams={{ method: 'with_filter', removeOn: 'empty_string_inactive_filter' }}
                >
                    <div className="flex w-full flex-row justify-between space-x-4">
                        <SourSearchOld
                            options={{
                                searchPopoverFixed: false,
                                showTips: !isDefaultFilter,
                                showMessages: !isDefaultFilter,
                                showResults: !isDefaultFilter,
                                placeholder: 'Search Orders',
                            }}
                        />
                        <SourFiltersOld />
                    </div>
                </SourSearchWrapperOld>
            </div>
            <div className="flex h-0 w-full flex-1 grow basis-0">
                <DataGrid
                    rows={searchActive || filterActive ? searchResults : filterData}
                    loading={searchActive || filterActive ? searchLoading : openInvoicesLoading}
                    columns={columns}
                    onRowClick={(row, column) => {
                        if (column.key === 'status') {
                            return;
                        }
                        setLastViewedId(row.id);
                        history.push({
                            pathname: routes.billing.list,
                            search: `?id=${String(row.id)}`,
                        });
                    }}
                    makeRow={(row) => ({
                        className: row.id === lastViewedId ? 'bg-blue-50' : '',
                    })}
                    pagination={{
                        itemCount: searchActive ? searchResultsCount : invoicesCount,
                        page: searchActive || filterActive ? searchPage : page,
                        rowsPerPage: searchActive || filterActive ? searchPageSize : pageSize,
                        rowsPerPageOptions: [25, 50, 100],
                        onPageChange: (newPage) => {
                            setPage(newPage);
                            setSearchPage(newPage);
                        },
                        onRowsPerPageChange: (rowsPerPage) => {
                            setPageSize(rowsPerPage);
                            setSearchPageSize(rowsPerPage);
                            setPage(0);
                        },
                    }}
                />
            </div>
        </>
    );
};

export default BillingListRolloff;
