import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Customer, Notification, Order, UniversalService } from '@alliance-disposal/transport-types';
import { useSourContext } from '@wayste/sour-context';
import { formatEmailDestination, formatServiceAddress, moneyFormatter } from '@wayste/utils';
import { isAxiosError } from 'axios';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import { getCustomerToAndCCEmails, paymentMethodsEnums } from '../../../../utils';
import { everyPaymentMethods } from '../../../../utils/shared-types';
import TransactionCreate, { CleanTransactionPaymentsType, TransactionPaymentsType } from './TransactionCreate';

interface Props {
    open: boolean;
    onCancel: () => void;
    customer: Customer.AllianceCustomerTransport;
    onSave: () => void;
}

const TransactionCreateContainer = ({ open, onCancel, customer, onSave }: Props) => {
    const client = useWaysteClient();
    const { setShowToast, setShowAlert } = useSourContext();
    const [payments, setPayments] = useState<TransactionPaymentsType[]>([]);
    const [paymentMethod, setPaymentMethod] = useState(customer.defaultPaymentSettings?.paymentMethod || 'creditCard');
    const [totalPayment, setTotalPayment] = useState(0);
    const [invoiceTotals, setInvoiceTotals] = useState({
        total: 0,
        totalDue: 0,
        totalCredit: 0,
    });
    const [isLoading, setIsLoading] = useState(false);

    const prepareTransactions = async () => {
        const receivables = await client.invoice().adminPortal.receivable.query({
            customerID: customer.id,
            paidInFull: false,
            void: false,
            limit: 200,
        });
        const dataArray: TransactionPaymentsType[] = [];

        const filteredReceivables = receivables.results.filter((receivable) => receivable.invoiceDetails.remainingBalance !== 0);

        try {
            await Promise.all(
                filteredReceivables.map(async (receivable) => {
                    let order: Order.AllianceOrderTransport | UniversalService.ServiceOrder | undefined;
                    // we just filtered them above, so we know this is true
                    if (receivable.invoiceDetails.serviceGroupingID) {
                        // payable.invoiceDetails.serviceGroupingID is exists when the invoice is not associated a universal service order
                        const serviceOrderID = receivable.invoiceDetails.lineItems[0]?.serviceOrderID;
                        try {
                            if (serviceOrderID) {
                                order = await client.universalService().serviceOrder.fetch(serviceOrderID);
                            }
                        } catch (error) {
                            console.warn('Error fetching service order', error);
                        }
                    } else if (receivable.invoiceDetails.orderID) {
                        // payable.invoiceDetails.orderID is null when the invoice is not associated with an a roll off order
                        try {
                            order = await client.order().adminPortal.fetch(receivable.invoiceDetails.orderID!);
                        } catch (error) {
                            console.warn('Error fetching order', error);
                        }
                    }

                    const payment: TransactionPaymentsType = {
                        orderId: receivable.invoiceDetails.productInvoiceGroupingID || '',
                        orderNumber:
                            (order as Order.AllianceOrderTransport)?.orderNumber?.toString() ||
                            (order as UniversalService.ServiceOrder)?.fullOrderNumber ||
                            '',
                        invoice: receivable.invoiceDetails.invoiceNumber || '',
                        invoiceID: receivable.invoiceDetails.id,
                        total: receivable.invoiceDetails.total,
                        paid: receivable.invoiceDetails.total - receivable.invoiceDetails.remainingBalance,
                        credit: 0,
                        value: '',
                        serviceAddress: order?.serviceLocation?.address ? formatServiceAddress(order.serviceLocation.address) : '',
                        revenueShare: 0,
                        revenueShareAmount: 0,
                        vendorId: 'no-vendor',
                    };

                    dataArray.push(payment);
                }),
            );
        } catch (error) {
            console.warn('Setup error: ', error);
        } finally {
            handleSetPayments(dataArray);
            setIsLoading(false);
        }
    };

    const handleGetOrders = async () => {
        setIsLoading(true);
        prepareTransactions();
    };

    useEffect(() => {
        handleGetOrders();
    }, [customer]);

    const handleSetPayments = (newPayments: TransactionPaymentsType[]) => {
        let total = 0;
        newPayments.forEach((payment) => {
            total = total + +payment.value;
        });

        setTotalPayment(total);
        getTotalInvoices();
        const sorted = newPayments.sort((a, b) => {
            if ((a.orderNumber || '') > (b.orderNumber || '')) return 1;
            if ((b.orderNumber || '') > (a.orderNumber || '')) return -1;
            return 0;
        });
        setPayments(sorted);
    };

    const getTotalInvoices = () => {
        let total = 0;
        let totalDue = 0;
        let totalCredit = 0;
        payments.forEach((payment) => {
            total = total + payment.total;
            totalDue = totalDue + (payment.total - payment.paid);
            totalCredit = 0;
        });
        setInvoiceTotals({
            total: total,
            totalDue: totalDue,
            totalCredit: totalCredit,
        });
    };

    const handleUpdatePayment = (value: number | '', index: number, updatedPayment?: TransactionPaymentsType) => {
        const newPayments = [...payments];
        if (updatedPayment) {
            newPayments[index] = updatedPayment;
        } else {
            newPayments[index] = {
                ...newPayments[index],
                value: value,
                paymentMethod: value ? paymentMethod : undefined,
            };
        }
        handleSetPayments(newPayments);
    };

    const sanitizeSheetName = (name: string) => {
        let sanitizedName = name.replace(/[:\\/?*[\]]/g, ''); // replace invalid characters
        sanitizedName = sanitizedName.slice(0, 31); // truncate to maximum length
        return sanitizedName;
    };

    const handleSendingRemittanceSummaryCustomerEmail = async (remittanceSummary: {
        receivablesTotal: number;
        transactionID: string;
        paymentMethod: string;
        data: string[][];
    }) => {
        const contactEmail = getCustomerToAndCCEmails('billing', customer);
        // create a new workbook
        const wb = XLSX.utils.book_new();
        const wbSheet = {
            ws: XLSX.utils.aoa_to_sheet(remittanceSummary.data),
            name: sanitizeSheetName(`Transaction ${remittanceSummary.transactionID}`),
        };
        XLSX.utils.book_append_sheet(wb, wbSheet.ws, wbSheet.name);
        // Write workbook to binary string
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        // Convert binary string to base64
        const base64 = btoa(String.fromCharCode(...new Uint8Array(wbout)));
        const attachments = [
            {
                content: base64,
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                filename: `Remittance Summary Transaction ID: ${remittanceSummary.transactionID}.xlsx`,
                disposition: 'attachment',
            },
        ];
        const destination = formatEmailDestination(contactEmail.to, contactEmail.toContact.firstName || '', contactEmail.cc);
        const body: Notification.SendGrid.CustomerRemittanceSummary = {
            first_name: contactEmail.toContact.firstName || '',
            payment_method: remittanceSummary.paymentMethod,
            remittance_total: moneyFormatter(remittanceSummary.receivablesTotal, { decimalPlaces: 2, hideDollarSign: true }),
            transaction_id: remittanceSummary.transactionID,
        };
        try {
            await client.notification().adminPortal.createInstantNotification({
                handler: 'sendgrid',
                topic: 'customer-remittance-summary',
                destination,
                body: JSON.stringify(body),
                directAttachments: attachments,
            });
            setIsLoading(false);
        } catch (error) {
            alert('Something went wrong sending the email');
            throw new Error('Something went wrong sending the email');
        }
    };

    const handleSave = async (cleanedPayments: CleanTransactionPaymentsType[], sendRemittanceSummary: boolean, stripeId?: string) => {
        setIsLoading(true);
        try {
            if (customer.id && !customer.stripeId && stripeId) {
                await client.customer().adminPortal.update(customer.id, {
                    stripeId: stripeId,
                });
            }

            const paymentPromises = cleanedPayments.map(async (paymentObject) => {
                return await client.invoice().adminPortal.payment.create(paymentObject.invoiceID, {
                    stripeChargeID: paymentObject.item.stripeChargeID || undefined,
                    paymentMethod: paymentObject.item.paymentMethod,
                    amount: paymentObject.item.amount,
                    paymentIdentifier: paymentObject.item.paymentIdentifier || undefined,
                    paymentReceivedDate: paymentObject.item.paymentReceivedDate?.toISOString() || undefined,
                    haulerVendorID: paymentObject.vendorId,
                    revenueShare: paymentObject.revenueShare,
                    revenueShareAmount: paymentObject.revenueShareAmount,
                });
            });

            const paymentResponses = await Promise.all(paymentPromises);
            // If payment method is account credit, create account credit records
            if (paymentMethod === paymentMethodsEnums.accountCredit) {
                const accountCreditPromises = paymentResponses.map(async (paymentResponse) => {
                    return await client.invoice().adminPortal.accountCredit.create({
                        amount: -paymentResponse.payment.amount,
                        date: new Date().toISOString(),
                        invoicePaymentID: paymentResponse.payment.id,
                        invoiceID: paymentResponse.payment.invoiceID,
                        customerID: customer.id,
                        accountCreditType: 'PAYMENT_APPLIED',
                    });
                });
                await Promise.all(accountCreditPromises);
            }

            if (sendRemittanceSummary) {
                const receivablesTotal = cleanedPayments.reduce((prev, curr) => {
                    return prev + curr.item.amount;
                }, 0);
                const emailData = [['Date', 'Paid Via', 'Transaction ID', 'Invoice Number', 'Amount', 'Service Address']];
                cleanedPayments.forEach((payment) => {
                    emailData.push([
                        payment.item.paymentReceivedDate ? format(payment.item.paymentReceivedDate, 'MM/dd/yyyy') : '',
                        everyPaymentMethods[payment.item.paymentMethod as keyof typeof everyPaymentMethods],
                        payment.item.paymentIdentifier || '',
                        `${payment.orderNumber} - ${payment.invoice}`,
                        moneyFormatter(payment.item.amount),
                        payment.serviceAddress,
                    ]);
                });
                await handleSendingRemittanceSummaryCustomerEmail({
                    receivablesTotal,
                    transactionID: cleanedPayments[0].item.paymentIdentifier || '',
                    paymentMethod: everyPaymentMethods[cleanedPayments[0].item.paymentMethod as keyof typeof everyPaymentMethods],
                    data: emailData,
                });
            }

            // handleGetOrders();

            setShowToast({
                severity: 'success',
                message: 'Invoices Successfully Updated',
            });
            onSave();
        } catch (error) {
            let errorMessage = 'TOUCH NOTHING GET A SAP DEV. There was an error updating the invoices.';
            if ((error as Error).message) {
                errorMessage = (error as Error).message;
            }

            if (isAxiosError(error)) {
                errorMessage =
                    error.response?.data.additionalInfo || error.response?.data.message || 'There was an error updating the invoices.';
            }
            setShowAlert({
                severity: 'error',
                title: 'Payment Error',
                message: errorMessage,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <TransactionCreate
            onUpdatePayment={handleUpdatePayment}
            open={open}
            onCancel={onCancel}
            customer={customer}
            invoiceTotals={invoiceTotals}
            paymentMethod={paymentMethod}
            onSetPaymentMethod={setPaymentMethod}
            payments={payments}
            totalPaymentCents={totalPayment}
            onSave={handleSave}
            isLoading={isLoading}
        />
    );
};

export default TransactionCreateContainer;
