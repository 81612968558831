import React, { Fragment, useEffect, useState } from 'react';
import {
    type FlipOptions,
    Middleware,
    type OffsetOptions,
    type ShiftOptions,
    autoUpdate,
    flip,
    offset,
    shift,
    useFloating,
    useInteractions,
    useRole,
} from '@floating-ui/react';
import { Listbox, Portal, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import type { FieldError } from 'react-hook-form';

interface ChipSelectOptionProps {
    value: string | undefined;
    onClick?: React.MouseEventHandler<HTMLLIElement> | undefined;
    label?: string | React.ReactNode;
    disabled?: boolean;
}

type ChipSelectProps = {
    disabled?: boolean;
    label?: string;
    required?: boolean;
    error?: FieldError;
    helperText?: string;
    defaultValue?: string[];
    children: React.ReactElement<ChipSelectOptionProps>[] | React.ReactNode;
    dense?: boolean;
    listBoxOptionsClassName?: string;
    onSelect: (value: string[]) => void;
    value?: string[];
    valuesToRender?: {
        label: string | React.ReactNode;
        value: string | number;
    }[];
    /**
     * Custom render function for the select trigger button
     */
    renderTrigger?: (props: { value: string[]; open: boolean; disabled?: boolean }) => React.ReactNode;
    /**
     * Props to be passed to existing floating middleware, such as offset, flip, shift
     */
    floatProps?: {
        offset?: OffsetOptions;
        flip?: FlipOptions;
        shift?: ShiftOptions;
    };
    /**
     * Extra middleware to be used in the floating context (probably should never have to use this, but it's here for flexibility)
     */
    extraMiddleware?: Middleware[];
};

export const ChipSelect = ({
    disabled,
    label,
    required,
    onSelect,
    error,
    helperText,
    defaultValue,
    value,
    children,
    dense,
    listBoxOptionsClassName,
    valuesToRender,
    renderTrigger,
    floatProps,
    extraMiddleware,
}: ChipSelectProps) => {
    const [selected, setSelected] = useState<string[]>(defaultValue || []);
    const [hasValue, setHasValue] = useState<boolean>(
        Array.isArray(selected) ? selected.length > 0 : selected !== '' && selected !== undefined,
    );
    useEffect(() => {
        setHasValue(Array.isArray(selected) ? selected.length > 0 : selected !== '' && selected !== undefined);
    }, [selected]);

    useEffect(() => {
        setSelected(value || []);
    }, [value]);

    const handleSelect = (newValue: string[]) => {
        setSelected(newValue);
        if (newValue !== undefined) {
            (onSelect as (value: string[]) => void)(newValue as string[]);
        }
    };

    const renderValueLabel = (children: React.ReactElement<ChipSelectOptionProps>[]) => {
        if (value === undefined) return undefined;
        if (valuesToRender) {
            return valuesToRender.map((item) => (
                <div
                    className={`flex items-center justify-between gap-2 ${typeof item.label === 'string' ? 'rounded bg-gray-200 px-1.5 py-1 text-sm' : ''}`}
                    key={item.value}
                >
                    {item.label}
                    {typeof item.label === 'string' && (
                        <XMarkIcon
                            className="size-5 cursor-pointer"
                            onClick={() => {
                                const newValue = value.filter((x) => x !== item.value);
                                handleSelect(newValue as string[]);
                            }}
                        />
                    )}
                </div>
            ));
        }

        const filteredChildren = children.filter((child) => child?.props?.value && value.includes(child.props.value));
        return filteredChildren.map((child, index: number) => (
            <div
                className="flex items-center justify-between rounded bg-gray-200 px-1.5 py-1 text-sm"
                key={child?.props?.value?.toString() || index}
            >
                {child?.props?.label || child?.props?.value}
                <XMarkIcon
                    className="size-5 cursor-pointer pl-1"
                    onClick={() => {
                        const newValue = value.filter((item) => item !== child.props.value);
                        handleSelect(newValue as string[]);
                    }}
                />
            </div>
        ));
    };

    const { refs, floatingStyles, context } = useFloating({
        transform: false, // needed for transitions to work properly
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(floatProps?.offset || 5),
            flip({ ...floatProps?.flip }),
            shift({ ...floatProps?.shift }),
            ...(extraMiddleware || []),
        ],
    });

    const interactions = [useRole(context, { role: 'select' })];

    const { getReferenceProps, getFloatingProps } = useInteractions(interactions);

    return (
        <div className={`relative h-fit w-full`}>
            <Listbox value={selected} onChange={(value) => handleSelect(value)} multiple={true} disabled={disabled}>
                {({ open }) => (
                    <div className="relative">
                        {renderTrigger ? (
                            <Listbox.Button as="button" ref={refs.setReference} {...getReferenceProps}>
                                {renderTrigger({ value: selected as string[], open, disabled })}
                            </Listbox.Button>
                        ) : (
                            <div className={`relative size-full min-w-[200px]`} ref={refs.setReference} {...getReferenceProps}>
                                <Listbox.Button
                                    className={`border-blue-gray-200 text-blue-gray-700 disabled:bg-blue-gray-50 peer size-full justify-between rounded-[7px] border bg-white py-[5px] pl-3 pr-6 text-left text-base font-normal outline outline-0 transition-all focus:border-2 focus:outline-0 disabled:border-0 ${
                                        label ? 'focus:border-t-transparent' : ''
                                    } ${(hasValue || open) && label ? 'border border-t-transparent' : ''} ${
                                        error
                                            ? 'border-red-500 placeholder-shown:border-red-500 focus:border-red-500'
                                            : `border-blue-gray-200 focus:border-input-focus flex items-center`
                                    }`}
                                >
                                    {' '}
                                    <div className="flex min-h-[28px] flex-wrap gap-1.5">
                                        {renderValueLabel(children as React.ReactElement<ChipSelectOptionProps>[])}
                                    </div>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                        <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </Listbox.Button>
                                <Listbox.Label
                                    className={`${
                                        dense ? 'h-fit' : ''
                                    } before:content[' '] after:content[' '] text-blue-gray-400 peer-disabled:text-blue-gray-400 pointer-events-none absolute -top-1.5 left-0 flex size-full select-none overflow-clip font-normal transition-all before:pointer-events-none before:mr-1 before:mt-[6.5px] before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:transition-all after:pointer-events-none after:ml-1 after:mt-[6.5px] after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:transition-all peer-focus:text-[11px] peer-focus:leading-tight peer-disabled:text-gray-500 peer-disabled:before:border-transparent peer-disabled:after:border-transparent ${
                                        label
                                            ? 'peer-focus:before:border-l-2 peer-focus:before:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-t-2'
                                            : ''
                                    } ${
                                        (hasValue || open) && label
                                            ? 'before:border-blue-gray-200 after:border-blue-gray-200 text-[11px] leading-tight before:border-l before:border-t after:border-r after:border-t peer-disabled:text-gray-500'
                                            : `text-base leading-[3.25] before:border-transparent after:border-transparent`
                                    } ${
                                        error
                                            ? 'text-red-500 before:border-red-500 after:border-red-500 peer-placeholder-shown:text-red-500 peer-focus:text-red-500 peer-focus:before:border-red-500 peer-focus:after:border-red-500'
                                            : `text-blue-gray-400 before:border-blue-gray-200 after:border-blue-gray-200 peer-focus:text-input-focus peer-focus:before:border-input-focus peer-focus:after:border-input-focus`
                                    }`}
                                >
                                    {label}
                                    {required && !disabled && label && <span className="ml-0.5 inline-block text-gray-500">*</span>}
                                </Listbox.Label>
                            </div>
                        )}
                        <Portal>
                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options
                                    ref={refs.setFloating}
                                    style={{ ...floatingStyles, minWidth: refs.reference.current?.getBoundingClientRect().width }}
                                    {...getFloatingProps()}
                                    className={`z-[1375] mt-1 max-h-56 overflow-auto rounded-md bg-white text-left shadow-lg ring-1 ring-black/5 focus:outline-none ${
                                        dense ? 'py-0 text-xs' : 'py-1 text-base'
                                    } ${listBoxOptionsClassName || ''}`}
                                >
                                    {React.Children.map(children as React.ReactElement<ChipSelectOptionProps>[], (child) =>
                                        React.cloneElement(child),
                                    )}
                                </Listbox.Options>
                            </Transition>
                        </Portal>
                    </div>
                )}
            </Listbox>
            {error ? (
                <p className="mx-3.5 mt-1 text-xs text-[#F44336]">{error.message}</p>
            ) : (
                helperText && <p className="mx-3.5 mt-1 text-xs text-black opacity-50">{helperText}</p>
            )}
        </div>
    );
};

export default ChipSelect;

export const ChipSelectOption = ({ value, label, onClick, disabled }: ChipSelectOptionProps) => {
    return (
        <Listbox.Option
            className={({ active }) =>
                `${
                    disabled ? 'text-blue-gray-300' : active ? 'bg-primary-400 text-white' : ''
                } sour-ui-select-option relative cursor-pointer select-none py-2 pl-3 pr-9`
            }
            value={value}
            onClick={onClick}
            disabled={disabled}
        >
            {({ selected, active }) => (
                <>
                    <div className="flex items-center pr-2">
                        {typeof label === 'string' ? (
                            <span className={`${selected && !disabled ? 'font-semibold' : 'font-normal'} block truncate`}>{label}</span>
                        ) : (
                            label
                        )}
                    </div>

                    {selected && !disabled && (
                        <span className={`${active ? 'text-white' : 'text-primary-400'} absolute inset-y-0 right-0 flex items-center pr-4`}>
                            <CheckIcon className="size-5" aria-hidden="true" />
                        </span>
                    )}
                </>
            )}
        </Listbox.Option>
    );
};
