import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { Hauler, Material, Pricing } from '@alliance-disposal/transport-types';
import { MapEditorSubmit, ServiceAreasMap } from '@wayste/sour-components';
import { useSourContext } from '@wayste/sour-context';
import { Tab, TabPanel, Tabs } from '@wayste/sour-ui';
import { PlusIcon } from '@heroicons/react/24/solid';
import HaulerPricingCreate from '../HaulerPricingCreate';
import HaulerPricingList from '../HaulerPricingList';
import DeletePricingZoneModal from '../HaulerPricingList/DeletePricingZoneDialog';
import HaulerPricingUpdate from '../HaulerPricingUpdate';
import MaterialPricingDialog from '../MaterialPricingDialog';
import MaterialPricingHistoryDialog from '../MaterialPricingHistoryDialog';
import UniversalServicePricingList from '../UniversalServicePricingList';

const HaulerPricingDetails = ({ hauler }: { hauler: Hauler.HaulerWithAapTransport }) => {
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [haulerPricing, setHaulerPricing] = useState<Pricing.PricingTransport[]>([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showCreatePricing, setShowCreatePricing] = useState<boolean>(false);
    const [showUpdateDialog, setShowUpdateDialog] = useState<boolean>(false);
    const [showMaterialPricing, setShowMaterialPricing] = useState<boolean>(false);
    const [showMaterialHistory, setShowMaterialHistory] = useState<boolean>(false);
    const [selectedPricing, setSelectedPricing] = useState<
        { mode: 'Rolloff'; pricing: Pricing.PricingTransport } | { mode: 'Universal'; pricing: Pricing.ProductPricingZoneTransport } | null
    >(null);
    const [selectedMaterial, setSelectedMaterial] = useState<Pricing.PricingDataTransport | undefined>(undefined);
    const [pricingLoading, setPricingLoading] = useState<boolean>(false);
    const [vendorServices, setVendorServices] = useState<Pricing.ProductPricingZoneTransport[]>([]);
    const [isActiveOnWayste, setIsActiveOnWayste] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);

    // GET VENDOR PRICING FOR ROLLOFF
    const getHaulerPricing = async () => {
        setPricingLoading(true);
        const data = await client.pricing().adminPortal.pricing.query({ haulerID: hauler.id });
        setHaulerPricing(data);
        setPricingLoading(false);
    };

    useEffect(() => {
        if (hauler?.id) {
            setIsActiveOnWayste(!hauler.waysteLite);
            getHaulerPricing();
            getVendorServices();
        }
    }, [hauler?.id]);

    // HANDLE CREATE PRICING ZONE ACTION
    const handleCreatePricingClose = (data: { status: string | number }) => {
        setShowCreatePricing(false);
        if (data?.status === 'success') {
            getHaulerPricing();
        }
        if (data?.status === 200) {
            getVendorServices();
        }
    };

    // HANDLE UPDATE PRICING ZONE ACTION
    const handleUpdatePricingClose = (response: { status: string | number }) => {
        setShowUpdateDialog(false);
        setSelectedPricing(null);
        if (response?.status === 'success') {
            getHaulerPricing();
            getVendorServices();
        }
    };

    // HANDLE UPDATE MATERIAL PRICING ZONE ACTION
    const handleMaterialPricingClose = (status?: 'success' | 'error') => {
        setShowMaterialPricing(false);
        setSelectedPricing(null);
        setSelectedMaterial(undefined);
        if (status === 'success') {
            getHaulerPricing();
            getVendorServices();
        }
    };

    const handleDeletePricing = async () => {
        if (!selectedPricing) return;
        setPricingLoading(true);
        try {
            await client.pricing().adminPortal.pricing.delete(selectedPricing.pricing.id);
            setShowToast({ severity: 'success', message: 'Pricing Deleted' });
            await getHaulerPricing();
        } catch (error) {
            setShowToast({ severity: 'error', message: 'Error deleting pricing' });
        } finally {
            setPricingLoading(false);
        }
    };

    // HANDLE UPDATE SERVICE AREA
    const handleServiceAreaSubmit = async (data: MapEditorSubmit[], zone: Pricing.PricingTransport) => {
        const addServiceAreaPayload: Pricing.ServiceAreaMutate[] = data?.map((x) => {
            const serviceArea = {
                boundary: x.serviceArea,
                boundaryFormat: x.serviceAreaFormat,
                areaName: x.areaName,
                taxJurisdiction: x.taxJurisdiction,
            };
            return serviceArea;
        });

        if (zone) {
            try {
                await client.pricing().adminPortal.serviceArea.update(zone.id, addServiceAreaPayload);
                await getHaulerPricing();
                setShowToast({ severity: 'success', message: 'Service Area Updated' });
            } catch (error) {
                setShowToast({ severity: 'error', message: 'Error updating service area' });
            }
        }
    };

    const handleMaterialPricingHistoryClose = () => {
        setShowMaterialHistory(false);
        setSelectedPricing(null);
        setSelectedMaterial(undefined);
    };

    /////////////////////////////////////
    // UNIVERSAL SERVICE SECTION
    /////////////////////////////////////

    // GET VENDOR PRICING FOR UNIVERSAL SERVICE
    const getVendorServices = async () => {
        if (hauler?.id) {
            const response = await client.universalService().pricingZone.query({
                vendorID: hauler?.id,
            });
            setPricingLoading(true);

            setVendorServices(response?.results);
            setPricingLoading(false);
        }
    };

    // HANDLE UPDATE SERVICE AREA
    const handleUniversalServiceAreaSubmit = async (data: MapEditorSubmit[], zoneID: string) => {
        const addServiceAreaPayload: Pricing.ServiceAreaMutate[] = data?.map((x) => {
            const serviceArea = {
                boundary: x.serviceArea,
                boundaryFormat: x.serviceAreaFormat,
                areaName: x.areaName,
                taxJurisdiction: x.taxJurisdiction,
            };
            return serviceArea;
        });

        if (zoneID) {
            try {
                await client.universalService().pricingZone.updateServiceArea(zoneID, addServiceAreaPayload);
                setShowToast({ severity: 'success', message: 'Service Area Updated' });
                await getVendorServices();
            } catch (error) {
                setShowToast({ severity: 'error', message: 'Error updating service area' });
                console.warn('error', error);
            }
        }
    };

    const handleServicesUpdate = () => {
        getVendorServices();
    };

    const handleTabChanged = (index: number) => {
        if (index === 0) {
            getHaulerPricing();
        } else if (index === 1) {
            getVendorServices();
        }
        setTabIndex(index);
    };

    return (
        <Tabs activeIndex={tabIndex} onChange={handleTabChanged}>
            <Tab>Roll Off</Tab>
            <Tab>Universal Services</Tab>

            {/* Roll of Panel */}
            <TabPanel>
                <div className="mb-4">
                    <ServiceAreasMap
                        disableScrollZoom={true}
                        zoneClicked={(zoneID) => {
                            const section = document.getElementById(zoneID);
                            section?.scrollIntoView({ behavior: 'smooth' });

                            //  add a border to the section and remove it after 3 seconds
                            section?.classList.add('shadow-xl', 'shadow-red-500');

                            setTimeout(() => {
                                section?.classList.remove('shadow-xl', 'shadow-red-500');
                            }, 3000);
                        }}
                        pricingZones={haulerPricing}
                    />
                </div>
                <div className="flex flex-col gap-y-4">
                    <div>
                        <button className="btn-primary" onClick={() => setShowCreatePricing(true)} disabled={!hauler.isSourgumPartner}>
                            <PlusIcon className="mr-1 size-5" />
                            New Zone Pricing
                        </button>
                    </div>

                    <HaulerPricingList
                        isActiveOnWayste={isActiveOnWayste}
                        isLoading={pricingLoading}
                        pricing={haulerPricing}
                        onEditClicked={(pricing: Pricing.PricingTransport) => {
                            setSelectedPricing({ mode: 'Rolloff', pricing });
                            setShowUpdateDialog(true);
                        }}
                        onDeleteClicked={(pricing: Pricing.PricingTransport) => {
                            setSelectedPricing({ mode: 'Rolloff', pricing });
                            setShowDeleteDialog(true);
                        }}
                        onServiceAreaSubmit={handleServiceAreaSubmit}
                        onAddMaterialClicked={(pricing: Pricing.PricingTransport) => {
                            setSelectedPricing({ mode: 'Rolloff', pricing });
                            setShowMaterialPricing(true);
                        }}
                        onEditMaterialClicked={(pricing: Pricing.PricingTransport, material: Pricing.PricingDataTransport) => {
                            setSelectedPricing({ mode: 'Rolloff', pricing });
                            setSelectedMaterial(material);
                            setShowMaterialPricing(true);
                        }}
                        onCopyMaterialClicked={(pricing: Pricing.PricingTransport, material: Pricing.PricingDataTransport) => {
                            setSelectedPricing({ mode: 'Rolloff', pricing });
                            setSelectedMaterial({ ...material, id: undefined });
                            setShowMaterialPricing(true);
                        }}
                        onViewHistoryClicked={(pricing: Pricing.PricingTransport, material: Pricing.PricingDataTransport) => {
                            setSelectedPricing({ mode: 'Rolloff', pricing });
                            setSelectedMaterial(material);
                            setShowMaterialHistory(true);
                        }}
                        onRefreshPricing={getHaulerPricing}
                    />
                </div>
                {/* Modals */}

                {selectedPricing?.mode === 'Rolloff' && (
                    <MaterialPricingDialog
                        open={showMaterialPricing}
                        material={selectedMaterial}
                        zone={selectedPricing.pricing}
                        isCopying={selectedMaterial && selectedMaterial.id === undefined}
                        onBackButtonClick={handleMaterialPricingClose}
                        isHauler
                    />
                )}
                {selectedPricing?.mode === 'Rolloff' && selectedMaterial && (
                    <MaterialPricingHistoryDialog
                        open={showMaterialHistory}
                        zoneID={selectedPricing.pricing.id}
                        material={selectedMaterial.material as Material}
                        onClose={handleMaterialPricingHistoryClose}
                    />
                )}
                <HaulerPricingCreate
                    open={showCreatePricing}
                    mode={'Rolloff'}
                    haulerPricingZones={haulerPricing}
                    onBackButtonClick={handleCreatePricingClose}
                    hauler={hauler}
                />
                {selectedPricing?.mode === 'Rolloff' && (
                    <DeletePricingZoneModal
                        open={showDeleteDialog}
                        onClose={() => setShowDeleteDialog(false)}
                        handleDelete={handleDeletePricing}
                        pricingZone={selectedPricing.pricing}
                    />
                )}

                {selectedPricing?.mode === 'Rolloff' && (
                    <HaulerPricingUpdate
                        pricing={selectedPricing.pricing}
                        haulerPricingZones={haulerPricing}
                        mode={selectedPricing.mode}
                        open={showUpdateDialog}
                        onBackButtonClick={handleUpdatePricingClose}
                        hauler={hauler}
                    />
                )}
            </TabPanel>

            {/* Universal Services Panel */}
            <TabPanel>
                <div className="mb-4">
                    <ServiceAreasMap pricingZones={vendorServices} disableScrollZoom={true} />
                </div>
                <HaulerPricingCreate
                    haulerPricingZones={haulerPricing}
                    open={showCreatePricing}
                    mode={'Universal'}
                    onBackButtonClick={handleCreatePricingClose}
                    hauler={hauler}
                />
                {selectedPricing?.mode === 'Universal' && (
                    <HaulerPricingUpdate
                        pricing={selectedPricing.pricing}
                        haulerPricingZones={haulerPricing}
                        mode={selectedPricing.mode}
                        open={showUpdateDialog}
                        onBackButtonClick={handleUpdatePricingClose}
                        hauler={hauler}
                    />
                )}
                <div className="mb-4">
                    <button className="btn-primary" onClick={() => setShowCreatePricing(true)} disabled={!hauler.isSourgumPartner}>
                        <PlusIcon className="mr-1 size-5" />
                        New Zone Pricing
                    </button>
                </div>
                <UniversalServicePricingList
                    isLoading={pricingLoading}
                    pricing={vendorServices}
                    rollOffPricing={haulerPricing || []}
                    handleServicesUpdate={handleServicesUpdate}
                    vendor={hauler}
                    onEditClicked={(pricing) => {
                        setSelectedPricing({ mode: 'Universal', pricing });
                        setShowUpdateDialog(true);
                    }}
                    onServiceAreaSubmit={handleUniversalServiceAreaSubmit}
                />
            </TabPanel>
        </Tabs>
    );
};

export default HaulerPricingDetails;
