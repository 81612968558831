import { useState } from 'react';
import { Material, MaterialLabels, Pricing } from '@alliance-disposal/transport-types';
import { MapEditorModal, MapEditorSubmit } from '@wayste/sour-components';
import { useSourContext } from '@wayste/sour-context';
import { Button, Menu, MenuItem, Tooltip } from '@wayste/sour-ui';
import { moneyFormatter, sortByKey } from '@wayste/utils';
import { EllipsisVerticalIcon, MapIcon } from '@heroicons/react/24/outline';
import { DocumentDuplicateIcon, ExclamationTriangleIcon, PencilIcon, PlusIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { FaHistory } from 'react-icons/fa';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { priceTypes, priceTypesEnums } from '../../utils';

const materialHeadings = ['Material', 'Price Type', 'Haul', 'Dump', 'Sizes', 'Last Updated', ''];

type HaulerPricingCardProps = {
    zone: Pricing.PricingTransport;
    zones: Pricing.PricingTransport[];
    isActiveOnWayste: boolean;
    onEditClicked: (pricing: Pricing.PricingTransport) => void;
    onDeleteClicked: (pricing: Pricing.PricingTransport) => void;
    onAddMaterialClicked: (pricing: Pricing.PricingTransport) => void;
    onCopyMaterialClicked: (pricing: Pricing.PricingTransport, material: Pricing.PricingDataTransport) => void;
    onEditMaterialClicked: (pricing: Pricing.PricingTransport, material: Pricing.PricingDataTransport) => void;
    onServiceAreaSubmit: (data: MapEditorSubmit[], zone: Pricing.PricingTransport) => void;
    onViewPricingChanges: (id: string) => void;
    onViewAcceptPricingChange: React.Dispatch<
        React.SetStateAction<{
            zoneID: string;
            materialID: string;
        }>
    >;
    onViewHistoryClicked: (pricing: Pricing.PricingTransport, material: Pricing.PricingDataTransport) => void;
};

const HaulerPricingCard = ({
    zone,
    zones,
    isActiveOnWayste,
    onEditClicked,
    onDeleteClicked,
    onAddMaterialClicked,
    onCopyMaterialClicked,
    onEditMaterialClicked,
    onServiceAreaSubmit,
    onViewPricingChanges,
    onViewAcceptPricingChange,
    onViewHistoryClicked,
}: HaulerPricingCardProps) => {
    const { godModeActive, setShowToast } = useSourContext();
    const [editServiceArea, setEditServiceArea] = useState(false);

    return (
        <div className="flex flex-col gap-y-3">
            <div className="flex items-end justify-between px-4">
                <div className="text-xl font-semibold leading-7 tracking-tight text-gray-800">{zone.zoneName}</div>
                <div className="flex items-center gap-x-4">
                    <Button className="btn-dark-grey-outlined" onClick={() => setEditServiceArea(true)} endIcon={<MapIcon />} collapse>
                        Edit Pricing Zone
                    </Button>
                    <Button
                        className="btn-dark-grey-outlined"
                        onClick={() => onEditClicked(zone)}
                        endIcon={<HiOutlinePencilAlt className="size-5 md:size-4" />}
                        collapse
                    >
                        Edit Zone Details
                    </Button>
                    <Button
                        className="btn-primary"
                        endIcon={<PlusIcon className="size-5" />}
                        onClick={() => onAddMaterialClicked(zone)}
                        collapse
                    >
                        Add Material Pricing
                    </Button>
                    <Menu
                        button={
                            <Button className="btn-icon">
                                <EllipsisVerticalIcon className="size-5" />
                            </Button>
                        }
                    >
                        <MenuItem
                            className="text-red-500"
                            disabled={!godModeActive && isActiveOnWayste}
                            onClick={() => onDeleteClicked(zone)}
                        >
                            <Tooltip
                                text={
                                    !godModeActive && isActiveOnWayste
                                        ? 'Cannot delete Wayste Standard pricing zone unless in God Mode'
                                        : undefined
                                }
                            >
                                Delete Pricing Zone
                            </Tooltip>
                        </MenuItem>
                        <MenuItem onClick={() => onViewPricingChanges(zone.id)}>View Change History</MenuItem>
                    </Menu>
                </div>
            </div>
            <div className="flex flex-col gap-y-4 overflow-auto rounded-lg border border-gray-300 bg-[#fcfcfd] text-sm shadow" id={zone.id}>
                <div className="grid auto-cols-max grid-flow-col items-center gap-4 px-4 pt-4">
                    <div>
                        <span
                            style={{
                                marginRight: 10,
                                color: 'rgba(0, 0, 0, 0.54)',
                                marginBottom: 16,
                            }}
                        >
                            State:
                        </span>
                        {zone.state}
                    </div>
                    <div>
                        <span
                            className="whitespace-nowrap"
                            style={{
                                marginRight: 10,
                                color: 'rgba(0, 0, 0, 0.54)',
                                marginBottom: 16,
                            }}
                        >
                            Trip Charge:
                        </span>
                        {zone.tripCharge ? moneyFormatter(zone.tripCharge) : ''}
                    </div>
                    <div>
                        <span
                            className="whitespace-nowrap"
                            style={{
                                marginRight: 10,
                                color: 'rgba(0, 0, 0, 0.54)',
                                marginBottom: 16,
                            }}
                        >
                            Last Updated:
                        </span>
                        {/* {format(new Date(zone.updated), 'MM/dd/yy')} */}
                    </div>
                    {!zone.sourgumApproved && (
                        <div>
                            <button
                                type="button"
                                className="btn-icon"
                                onClick={() =>
                                    onViewAcceptPricingChange({
                                        zoneID: zone.id,
                                        materialID: '',
                                    })
                                }
                            >
                                <Tooltip text="Pricing has not been approved by Sourgum">
                                    <ExclamationTriangleIcon className="text-warning size-6" />
                                </Tooltip>
                            </button>
                        </div>
                    )}
                    <div className="w-full">
                        <span
                            style={{
                                marginRight: 10,
                                color: 'rgba(0, 0, 0, 0.54)',
                                marginBottom: 16,
                            }}
                        >
                            Notes:
                        </span>
                        {zone.notes}
                    </div>
                </div>
                <div className="w-full overflow-x-auto">
                    <table className="w-full border-collapse border-spacing-0 text-sm">
                        <thead>
                            <tr className="border-y">
                                {materialHeadings.map((heading) => (
                                    <td key={heading} className="whitespace-nowrap bg-slate-50 px-4 py-1.5">
                                        {heading}
                                    </td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {sortByKey('material', zone.pricingData).map((material: Pricing.PricingDataTransport, index: number) => {
                                if (material.sizes.length === 0) {
                                    return (
                                        <tr key={index} className="border-b [&>*]:px-4 [&>*]:py-1.5">
                                            <td>{MaterialLabels[material.material as Material]}</td>
                                            <td colSpan={5}>
                                                <span>No sizes for this material</span>
                                            </td>
                                            <td>
                                                <div className="flex justify-end align-middle">
                                                    {!material.sourgumApproved && (
                                                        <button
                                                            className="btn-icon ml-4"
                                                            type="button"
                                                            onClick={() =>
                                                                onViewAcceptPricingChange({
                                                                    zoneID: zone.id,
                                                                    materialID: material.id || '',
                                                                })
                                                            }
                                                        >
                                                            <Tooltip text="Pricing has not been approved by Sourgum">
                                                                <ExclamationTriangleIcon className="text-warning size-6" />
                                                            </Tooltip>
                                                        </button>
                                                    )}
                                                    <button
                                                        className="btn-icon"
                                                        type="button"
                                                        onClick={() => onViewHistoryClicked(zone, material)}
                                                    >
                                                        <Tooltip text="View Pricing History">
                                                            <FaHistory className="text-edit size-5" />
                                                        </Tooltip>
                                                    </button>
                                                    <button
                                                        className="btn-icon"
                                                        type="button"
                                                        onClick={() => onEditMaterialClicked(zone, material)}
                                                    >
                                                        <PencilIcon className="text-edit size-5" />
                                                    </button>
                                                    <button
                                                        className="btn-icon"
                                                        type="button"
                                                        onClick={() => onCopyMaterialClicked(zone, material)}
                                                        disabled
                                                    >
                                                        <Tooltip text="Can't copy without pricing">
                                                            <DocumentDuplicateIcon className="size-5" />
                                                        </Tooltip>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                }
                                if (material.doesNotService) {
                                    return (
                                        <tr key={index} className="border-b [&>*]:px-4 [&>*]:py-1.5">
                                            <td>{MaterialLabels[material.material as Material]}</td>
                                            <td colSpan={5}>
                                                <span>Does not service</span>
                                            </td>
                                            <td>
                                                <div className="flex justify-end align-middle">
                                                    <button
                                                        className="btn-icon"
                                                        type="button"
                                                        onClick={() => onEditMaterialClicked(zone, material)}
                                                    >
                                                        <PencilIcon className="text-edit size-5" />
                                                    </button>
                                                    <button
                                                        className="btn-icon"
                                                        type="button"
                                                        onClick={() => onCopyMaterialClicked(zone, material)}
                                                        disabled
                                                    >
                                                        <Tooltip text="Can't copy without pricing">
                                                            <DocumentDuplicateIcon className="size-5" />
                                                        </Tooltip>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                }
                                return (
                                    <tr key={index} className="border-b [&>*]:px-4 [&>*]:py-1.5">
                                        <td>{MaterialLabels[material.material as Material]}</td>
                                        <td>{priceTypes[material.type as keyof typeof priceTypes]}</td>
                                        <td>
                                            {material.type !== priceTypesEnums.flat &&
                                            material.sizes.every((size) => size.haul === material.sizes[0].haul) &&
                                            material.sizes.every((size) => size.tonLimit === 0)
                                                ? moneyFormatter(material.sizes[0].haul)
                                                : Object.values(material.sizes).map((item) => (
                                                      <div key={item.size}>
                                                          <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}>{item.size}:</span>
                                                          {moneyFormatter(item.haul)}
                                                          {material.type === priceTypesEnums.ton &&
                                                          material.sizes.some((size) => size.tonLimit) ? (
                                                              <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}> TL: {item.tonLimit}</span>
                                                          ) : null}
                                                          {', '}
                                                      </div>
                                                  ))}
                                        </td>
                                        {material.type !== priceTypesEnums.flat ? (
                                            <td>
                                                {material.sizes.every((item) => item.dump === material.sizes[0].dump) &&
                                                material.sizes[0].dump
                                                    ? moneyFormatter(material.sizes[0].dump)
                                                    : material.sizes.map((item) => (
                                                          <div key={item.size}>
                                                              <span style={{ color: 'rgba(0, 0, 0, 0.48)' }}>{item.size}:</span>
                                                              {moneyFormatter(item.dump)}
                                                          </div>
                                                      ))}
                                            </td>
                                        ) : (
                                            <td />
                                        )}
                                        {
                                            <td>
                                                {material.sizes.map((size, index: number) => (
                                                    <span key={size.size}>
                                                        {size.size}
                                                        {index === material.sizes.length - 1 ? '' : ', '}
                                                    </span>
                                                ))}
                                            </td>
                                        }
                                        <td>{material.lastUpdatedAt ? format(new Date(material.lastUpdatedAt), 'MM/dd/yy') : ''}</td>
                                        <td>
                                            <div className="flex justify-end align-middle">
                                                {!material.sourgumApproved && (
                                                    <button
                                                        className="btn-icon ml-4"
                                                        type="button"
                                                        onClick={() =>
                                                            onViewAcceptPricingChange({
                                                                zoneID: zone.id,
                                                                materialID: material.id || '',
                                                            })
                                                        }
                                                    >
                                                        <Tooltip text="Pricing has not been approved by Sourgum">
                                                            <ExclamationTriangleIcon className="text-warning size-6" />
                                                        </Tooltip>
                                                    </button>
                                                )}
                                                <button
                                                    className="btn-icon"
                                                    type="button"
                                                    onClick={() => onViewHistoryClicked(zone, material)}
                                                >
                                                    <Tooltip text="View Pricing History">
                                                        <FaHistory className="text-edit size-5" />
                                                    </Tooltip>
                                                </button>
                                                <button
                                                    className="btn-icon"
                                                    type="button"
                                                    onClick={() => onEditMaterialClicked(zone, material)}
                                                >
                                                    <Tooltip text="Edit Material Pricing">
                                                        <PencilIcon className="text-edit size-5" />
                                                    </Tooltip>
                                                </button>
                                                <button
                                                    className="btn-icon"
                                                    type="button"
                                                    onClick={() => onCopyMaterialClicked(zone, material)}
                                                >
                                                    <Tooltip text="Copy Material Pricing">
                                                        <DocumentDuplicateIcon className="text-edit size-5" />
                                                    </Tooltip>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Dialogs */}
            <MapEditorModal
                open={editServiceArea}
                googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_KEY}
                serviceAreas={zone?.serviceAreas}
                zones={zones}
                onClose={() => {
                    setEditServiceArea(false);
                }}
                onSubmit={(data) => onServiceAreaSubmit(data, zone)}
                onError={(error) => setShowToast({ message: error, severity: 'error' })}
            />
        </div>
    );
};

export default HaulerPricingCard;
