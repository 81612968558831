import { Customer } from '@alliance-disposal/transport-types';
import axios from 'axios';
import DetailsCardWrapper from '../../../components/DetailsCardWrapper';
import { useAuthToken } from '../../../hooks/authhook';

type CustomerInvoicesProps = {
    customer: Customer.AllianceCustomerTransport;
};

const CustomerInvoices = ({ customer }: CustomerInvoicesProps) => {
    const { token } = useAuthToken();

    const handleGetDelinquentInvoices = () => {
        axios({
            url: `${import.meta.env.VITE_BASE_API_URL}/reports/invoice/delinquent/customer/${customer.id}?output=CSV`, //your url
            method: 'GET',
            headers: {
                Authorization: token,
            },
            responseType: 'blob', // important
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'delinquent-invoices.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch();
    };

    return (
        <DetailsCardWrapper
            heading="Invoices"
            buttons={[
                {
                    label: 'Open Invoices Report',
                    onClick: () => handleGetDelinquentInvoices(),
                },
            ]}
        >
            Delinquent Report Button Still Works. Need to update component to use SAP invoices. Was using Stripe Invoices, which are
            deprecated.
        </DetailsCardWrapper>
    );
};

export default CustomerInvoices;
