import { moneyFormatter } from '@wayste/utils';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';

interface PriceChangeIndicatorProps {
    current?: number | null;
    previous?: number | null;
    isCurrency?: boolean;
}

const PriceChangeIndicator = ({ current, previous, isCurrency = true }: PriceChangeIndicatorProps) => {
    // Early return if values aren't provided
    if (current == null || previous == null) {
        return null;
    }

    // Return null if values are equal
    if (current === previous) {
        return null;
    }

    const diff = current - previous;
    const isIncrease = diff > 0;

    // Calculate percentage change, avoiding division by zero
    const percentChange = previous !== 0 ? ((diff / previous) * 100).toFixed(1) : null;

    // Determine styles based on increase/decrease
    const bgColor = isIncrease ? 'bg-red-100' : 'bg-green-100';
    const textColor = isIncrease ? 'text-red-800' : 'text-green-800';
    const arrow = isIncrease ? <ArrowUpIcon className="size-3" /> : <ArrowDownIcon className="size-3" />;

    return (
        <div
            className={`mt-1.5 inline-flex items-center gap-1.5 rounded-full px-2.5 py-1 text-xs font-medium ${bgColor} ${textColor} transition-colors duration-200 hover:${bgColor.replace('100', '200')} `}
            title={`Change from previous: ${isCurrency ? moneyFormatter(diff) : diff}`}
        >
            <span className="font-bold">{arrow}</span>
            {percentChange && <span>{Math.abs(Number(percentChange))}%</span>}
            <span>{isCurrency ? `(${moneyFormatter(Math.abs(diff))})` : `(${isIncrease ? '+' : ''}${diff})`}</span>
        </div>
    );
};

export default PriceChangeIndicator;
