import { useEffect, useState } from 'react';
import { Pricing } from '@alliance-disposal/transport-types';
import { MapEditorSubmit } from '@wayste/sour-components';
import { sortByKey } from '@wayste/utils';
import HaulerPricingList from './HaulerPricingList';

type Props = {
    isLoading: boolean;
    pricing: Pricing.PricingTransport[];
    onEditClicked: (pricing: Pricing.PricingTransport) => void;
    onDeleteClicked: (pricing: Pricing.PricingTransport) => void;
    onAddMaterialClicked: (pricing: Pricing.PricingTransport) => void;
    onCopyMaterialClicked: (pricing: Pricing.PricingTransport, material: Pricing.PricingDataTransport) => void;
    onEditMaterialClicked: (pricing: Pricing.PricingTransport, material: Pricing.PricingDataTransport) => void;
    onServiceAreaSubmit: (data: MapEditorSubmit[], zone: Pricing.PricingTransport) => void;
    onViewHistoryClicked: (pricing: Pricing.PricingTransport, material: Pricing.PricingDataTransport) => void;
    onRefreshPricing: () => void;
    isActiveOnWayste: boolean;
};

/**
 * Gets and displays all pricing for hauler broken down by county
 * @param {boolean} isLoading Loading state
 * @param {Pricing.PricingTransport[]} pricing Array of pricing
 * @param {Function} onEditClicked Action to take on edit pricing
 * @param {Function} onDeleteClicked Action to take on delete pricing
 * @param {Function} onAddMaterialClicked Run function on Add Material Pricing clicked
 * @param {Function} onCopyMaterialClicked Run function on copy material pricing clicked
 * @param {Function} onEditMaterialClicked Run function on edit material icon clicked
 * @param {Function} onServiceAreaSubmit Run function on service area submit
 * @param {Function} onViewHistoryClicked Run function on view history clicked
 * @param {Function} onRefreshPricing Run function on refresh pricing
 * @param {boolean} isActiveOnWayste Whether the hauler is active on Wayste
 */
const HaulerPricingListContainer = ({
    isLoading,
    pricing,
    onEditClicked,
    onDeleteClicked,
    onAddMaterialClicked,
    onCopyMaterialClicked,
    onEditMaterialClicked,
    onServiceAreaSubmit,
    onRefreshPricing,
    onViewHistoryClicked,
    isActiveOnWayste,
}: Props) => {
    const [sortedPricing, setSortedPricing] = useState(pricing);

    useEffect(() => {
        const pricingSorted = sortByKey('zoneName', pricing).filter((item) => item.type !== 'PUBLIC');
        setSortedPricing(pricingSorted);
    }, [pricing]);

    return (
        <HaulerPricingList
            pricing={sortedPricing}
            isLoading={isLoading}
            onEditClicked={onEditClicked}
            onDeleteClicked={onDeleteClicked}
            onAddMaterialClicked={onAddMaterialClicked}
            onCopyMaterialClicked={onCopyMaterialClicked}
            onEditMaterialClicked={onEditMaterialClicked}
            onServiceAreaSubmit={onServiceAreaSubmit}
            onRefreshPricing={onRefreshPricing}
            onViewHistoryClicked={onViewHistoryClicked}
            isActiveOnWayste={isActiveOnWayste}
        />
    );
};

export default HaulerPricingListContainer;
