import { InternalTicket } from '@alliance-disposal/transport-types';
import { Button } from '@wayste/sour-ui';
import { ArrowTopRightOnSquareIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import ServiceGroupingDetails from '../../pages/site-services/ServiceGroupingDetails/ServiceGroupingDetails';
import { routes } from '../../utils';
import CustomerDetails from '../CustomerDetails';
import HaulerDetails from '../HaulerDetails';
import LeadDetails from '../LeadDetails';
import OrderDetails from '../OrderDetails';

type DrawerProps = {
    isOpen: boolean;
    onClose: () => void;
    task?: InternalTicket.WorkflowTaskTransport;
};

export const DetailsDrawer: React.FC<DrawerProps> = ({ isOpen, onClose, task }) => {
    const getContent = (taskInfo?: InternalTicket.WorkflowTaskTransport) => {
        if (!taskInfo) return null;
        switch (taskInfo.entityType) {
            case 'sourgum-order':
                return <OrderDetails id={taskInfo.entityID} />;
            case 'sourgum-customer':
                return <CustomerDetails id={taskInfo.entityID} />;
            case 'sourgum-service-grouping':
                return <ServiceGroupingDetails serviceGroupingId={taskInfo.entityID} />;
            case 'sourgum-lead':
                return <LeadDetails id={taskInfo.entityID} />;
            case 'sourgum-vendor':
                return <HaulerDetails id={taskInfo.entityID} />;
            default:
                return <div>Missing entity type</div>;
        }
    };

    const handleFullDetailsNavigate = (): string | null => {
        if (!task) return null;
        switch (task.entityType) {
            case 'sourgum-order':
                return routes.orders.details(task.entityID);
            case 'sourgum-customer':
                return routes.customers.details(task.entityID);
            case 'sourgum-service-grouping':
                return routes.universalServices.serviceGrouping.details(task.entityID);
            case 'sourgum-lead':
                return routes.leads.details(task.entityID);
            case 'sourgum-vendor':
                return routes.haulers.details(task.entityID);
            default:
                return null;
        }
    };

    return (
        <>
            {/* Overlay */}
            <div className={`fixed inset-0 z-40 bg-black opacity-50 ${isOpen ? 'block' : 'hidden'}`} onClick={onClose}></div>

            {/* Drawer */}
            <div
                className={`fixed right-0 top-0 z-50 h-full w-4/5 transform bg-slate-50 p-3 transition-transform ${
                    isOpen ? 'translate-x-0' : 'translate-x-full'
                } overflow-auto`}
            >
                <div className="mb-4 flex justify-end gap-2">
                    <Link to={handleFullDetailsNavigate() || ''}>
                        <Button className="btn-secondary-outlined" endIcon={<ArrowTopRightOnSquareIcon className="size-5" />}>
                            To Full Details
                        </Button>
                    </Link>
                    <Button className="btn-icon" onClick={onClose}>
                        <XMarkIcon className="size-5" />
                    </Button>
                </div>
                <div className="flex flex-col gap-y-3">{getContent(task)}</div>
            </div>
        </>
    );
};
