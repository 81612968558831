import { useEffect, useState } from 'react';
import { useWaysteClient } from '@alliance-disposal/client';
import { useSourContext } from '@wayste/sour-context';
import { Button, Loading, Pagination } from '@wayste/sour-ui';
import { formatServiceDate } from '@wayste/utils';
import { AxiosError } from 'axios';
import { format, isToday } from 'date-fns';
import { useHistory, useLocation } from 'react-router-dom';

type DailyUpload = {
    date: Date;
    status: string;
    total: number;
    synced: number;
    errors: number;
};

const pageSize = 10; // 10 items per page

const QboDailyUploads = () => {
    const location = useLocation();
    const history = useHistory();
    const client = useWaysteClient();
    const { setShowToast } = useSourContext();
    const [dailyUploads, setDailyUploads] = useState<DailyUpload[]>([]);
    const [paginatedDates, setPaginatedDates] = useState<DailyUpload[]>([]);
    const [isLoading, setIsLoading] = useState({ loading: false, index: -1 });
    const [pageLoading, setPageLoading] = useState(false);
    const [error, setError] = useState(false);

    // Get the current search params from the URL
    const query = new URLSearchParams(location.search);
    const currentPage = parseInt(query.get('page') || '1');

    const handleGetQuickBooksData = async () => {
        setPageLoading(true);
        try {
            const res = await client.quickBooks().getQuickBooksSettings();
            const start = formatServiceDate(res.data.cutoffDate, 'date'); // Convert string to Date object
            const end = new Date(); // Current date
            const dates: DailyUpload[] = [];

            while (start <= end) {
                dates.push({
                    date: new Date(start),
                    status: 'Pending',
                    errors: 0,
                    synced: 0,
                    total: 0,
                }); // Add copy of the date
                start.setDate(start.getDate() + 1); // Increment date by 1 day
            }
            const sortedDates = dates.sort((a, b) => b.date.getTime() - a.date.getTime());
            setDailyUploads(sortedDates);
        } catch (error) {
            setError(true);
            setShowToast({
                message: 'There was an error fetching the data',
                severity: 'error',
            });
        } finally {
            setPageLoading(false);
        }
    };

    useEffect(() => {
        handleGetQuickBooksData();
    }, []);

    const fetchPaginatedDates = async () => {
        setPageLoading(true);
        const start = currentPage * pageSize;
        const end = start + pageSize;
        const datesForPage = dailyUploads.slice(start, end); // Slice dates for the current page
        const completeData: DailyUpload[] = [];
        try {
            for (const daily of datesForPage) {
                const results = await client.quickBooks().querySyncedDailyCount({
                    dateFrom: formatServiceDate(daily.date, 'string'),
                    dateTo: formatServiceDate(daily.date, 'string'),
                });
                // Initialize sum variables
                let totalSum = 0;
                let syncedSum = 0;
                let errorsSum = 0;

                // Iterate over each category (vendors, customers, invoices, etc.)
                for (const category of Object.values(results)) {
                    totalSum += category.total || 0;
                    syncedSum += category.synced || 0;
                    errorsSum += category.errors || 0;
                }
                completeData.push({
                    date: daily.date,
                    total: totalSum,
                    synced: syncedSum,
                    errors: errorsSum,
                    status: Object.values(results).some((result) => result.total > 0)
                        ? Object.values(results).some((result) => result.synced > 0)
                            ? Object.values(results).some((result) => result.errors > 0)
                                ? 'SYNCED_WITH_ERRORS'
                                : 'SYNCED'
                            : 'PENDING'
                        : 'NONE',
                });
            }
            setPaginatedDates(completeData);
        } catch (error) {
            setError(true);
            if (error instanceof AxiosError && 'response' in error && error.response?.data?.message) {
                setShowToast({
                    message: error.response.data.message,
                    severity: 'error',
                });
            } else {
                setShowToast({
                    message: 'An error occurred while fetching data',
                    severity: 'error',
                });
            }
        } finally {
            setPageLoading(false);
        }
    };

    useEffect(() => {
        fetchPaginatedDates();
    }, [currentPage, dailyUploads]);

    const handleUpload = async (date: Date, index: number) => {
        if (error) {
            return;
        }
        setIsLoading({ loading: true, index });
        try {
            /* const results = */ await client.quickBooks().createQuickBooksDailyUpload({
                dateFrom: formatServiceDate(date, 'string'),
                dateTo: formatServiceDate(date, 'string'),
            });

            setShowToast({
                message: 'Data uploaded successfully',
                severity: 'success',
            });
        } catch (error) {
            setShowToast({
                message: 'There was an error uploading the data',
                severity: 'error',
            });
        } finally {
            setIsLoading({ loading: false, index: -1 });
        }
    };

    return (
        <div className="p-6">
            <div className="flex flex-col gap-y-4">
                {paginatedDates.map((upload, index) => (
                    <div key={upload.date.toString()} className="flex items-center justify-between">
                        <p>{format(upload.date, 'MM/dd/yyyy')}</p>
                        <div className="flex items-center gap-x-4">
                            <p>Total: {upload.total}</p>
                            <p>Synced: {upload.synced}</p>
                            <p>Errors: {upload.errors}</p>
                            <Button
                                className="btn-primary"
                                onClick={() => handleUpload(upload.date, index)}
                                loading={isLoading.loading && isLoading.index === index}
                                disabled={isToday(upload.date) || isLoading.loading}
                            >
                                {upload.status}
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
            <Pagination
                page={currentPage}
                itemCount={dailyUploads.length}
                pageCount={Math.ceil(dailyUploads.length / pageSize)}
                onPageChange={(page) => {
                    query.set('page', page.toString());
                    history.push({ search: query.toString() });
                }}
                rowsPerPage={pageSize}
            />
            {pageLoading && <Loading fullScreen />}
        </div>
    );
};

export default QboDailyUploads;
