import React, { Fragment, useEffect, useState } from 'react';
import {
    FlipOptions,
    Middleware,
    OffsetOptions,
    ShiftOptions,
    autoUpdate,
    flip,
    offset,
    shift,
    useFloating,
    useInteractions,
    useRole,
} from '@floating-ui/react';
import { Listbox, Portal, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import type { FieldError } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { Loading } from '../Loading/Loading';
import { Tooltip, TooltipProps } from '../Tooltip/Tooltip';

type BaseSelectProps = {
    minWidth?: string;
    disabled?: boolean;
    label?: string;
    required?: boolean;
    inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    error?: FieldError;
    helperText?: string;
    defaultValue?: string;
    variant?: 'standard' | 'filled' | undefined;
    children: any;
    dense?: boolean;
    renderLabel?: (value: string | number | string[] | number[] | undefined | null) => any;
    listBoxOptionsClassName?: string;
    loading?: boolean;
    /**
     * Props to be passed to existing floating middleware, such as offset, flip, shift
     */
    floatProps?: {
        offset?: OffsetOptions;
        flip?: FlipOptions;
        shift?: ShiftOptions;
    };
    /**
     * Extra middleware to be used in the floating context (probably should never have to use this, but it's here for flexibility)
     */
    extraMiddleware?: Middleware[];
};

type SingleSelectProps = BaseSelectProps & {
    multiple?: false;
    onSelect: (value: string) => void;
    value?: string | number | null;
};

type MultipleSelectProps = BaseSelectProps & {
    multiple: true;
    onSelect: (value: string[]) => void;
    value?: string[] | number[];
};

type SelectProps = SingleSelectProps | MultipleSelectProps;

export const Select = ({
    minWidth,
    disabled,
    label,
    required,
    onSelect,
    inputProps,
    error,
    helperText,
    defaultValue,
    value,
    variant,
    children,
    dense,
    multiple,
    renderLabel,
    listBoxOptionsClassName,
    loading,
    floatProps,
    extraMiddleware,
}: SelectProps) => {
    const [selected, setSelected] = useState<string | number | string[] | number[]>(defaultValue || (multiple ? [] : ''));
    const [hasValue, setHasValue] = useState<boolean>(
        Array.isArray(selected) ? selected.length > 0 : selected !== '' && selected !== undefined,
    );
    useEffect(() => {
        setHasValue(Array.isArray(selected) ? selected.length > 0 : selected !== '' && selected !== undefined);
    }, [selected]);

    useEffect(() => {
        if (multiple) {
            setSelected(value || []);
        } else {
            setSelected(value || value === 0 ? value : '');
        }
    }, [value]);

    const handleSelect = (newValue: string | number | string[] | number[]) => {
        if (!multiple && newValue === selected) {
            setSelected('');
            (onSelect as (value: string | number) => void)('');
            return;
        }
        setSelected(newValue);
        if (newValue !== undefined) {
            if (multiple) {
                (onSelect as (value: string[] | number[]) => void)(newValue as string[] | number[]);
            } else {
                (onSelect as (value: string | number) => void)(newValue as string | number);
            }
        }
    };

    const renderValueLabel = (children: any) => {
        if (renderLabel) return renderLabel(selected);
        if (value === undefined || value === '') return null;
        if (Array.isArray(value)) {
            let found = '';
            children.forEach((child: any) => {
                if (value.map(String).includes(child?.props?.value)) {
                    if (found.length > 0 && (child?.props?.label || child?.props?.children)) found += ', ';
                    found += `${child?.props?.label || child?.props?.children}`;
                }
            });
            return found;
        }
        const found = children?.find ? children?.find((child: any) => child?.props?.value === value) : children || undefined;
        if (found?.props?.label) return found.props.label;
        return found?.props?.children || null;
    };

    const { refs, floatingStyles, context } = useFloating({
        transform: false, // needed for transitions to work properly
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(floatProps?.offset || 5),
            flip({
                ...floatProps?.flip,
            }),
            shift({ ...floatProps?.shift }),
            ...(extraMiddleware || []),
        ],
    });

    const interactions = [useRole(context, { role: 'select' })];

    const { getReferenceProps, getFloatingProps } = useInteractions(interactions);

    // TODO consolidate the two variants together and just use conditionals on the classes
    if (variant === 'filled') {
        return (
            <div className={'relative w-full'} style={{ minWidth: minWidth || 200 }}>
                <Listbox value={selected} onChange={(newValue) => handleSelect(newValue)} multiple={multiple} disabled={disabled}>
                    {({ open }) => (
                        <div className="relative">
                            <Listbox.Button
                                id={inputProps?.name || inputProps?.id || undefined}
                                ref={refs.setReference}
                                className={`text-blue-gray-700 border-blue-gray-200 focus:border-input-focus peer block h-14 w-full appearance-none rounded-t-lg border-0 border-b-2 bg-gray-50 px-2.5 pb-2.5 pt-5 text-base focus:outline-none focus:ring-0 ${
                                    error && 'border-red-500 focus:border-red-500'
                                }`}
                                {...getReferenceProps}
                            >
                                <span className="flex items-center">
                                    <span className="block truncate">{renderValueLabel(children)}</span>
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                    <ChevronUpDownIcon className="size-5 text-gray-600" aria-hidden="true" />
                                </span>
                            </Listbox.Button>
                            <Listbox.Label
                                htmlFor={inputProps?.name || inputProps?.id || undefined}
                                className={`text-blue-gray-500 peer-focus:text-input-focus absolute left-2.5 top-4 z-10 origin-[0] -translate-y-4 cursor-pointer text-base duration-300 ${
                                    error &&
                                    'text-red-500 before:border-red-500 after:border-red-500 peer-placeholder-shown:text-red-500 peer-focus:text-red-500 peer-focus:before:border-red-500 peer-focus:after:border-red-500'
                                }`}
                            >
                                {label}
                                {required && label && (
                                    <span className={`ml-0.5 inline-block ${disabled ? 'text-gray-400' : 'text-gray-500'}`}>*</span>
                                )}
                            </Listbox.Label>
                            <Portal>
                                <Transition
                                    show={open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <Listbox.Options
                                        ref={refs.setFloating}
                                        className="z-[1375] mt-1 max-h-56 overflow-auto rounded-md bg-white py-1 text-left text-base shadow-lg ring-1 ring-black/5 focus:outline-none"
                                        style={{ ...floatingStyles, minWidth: refs.reference.current?.getBoundingClientRect().width }}
                                        {...getFloatingProps()}
                                    >
                                        {React.Children.map(children, (child) =>
                                            React.cloneElement(child, {
                                                isSelected: value === child.props.value,
                                            }),
                                        )}
                                    </Listbox.Options>
                                </Transition>
                            </Portal>
                        </div>
                    )}
                </Listbox>
                {error ? (
                    <p className="mx-3.5 mt-1 text-xs text-[#F44336]">{error.message}</p>
                ) : (
                    helperText && <p className="mx-3.5 mt-1 text-xs text-black opacity-50">{helperText}</p>
                )}
            </div>
        );
    }

    return (
        <div
            className={`relative w-full ${dense ? 'h-6' : `${helperText || Boolean(error) ? 'h-12' : 'h-10'}`}`}
            style={{ minWidth: dense ? minWidth : minWidth || 200 }}
        >
            <Listbox value={selected} onChange={(value: string) => handleSelect(value)} multiple={multiple} disabled={disabled}>
                {({ open }) => (
                    <div className="relative">
                        <div
                            ref={refs.setReference}
                            {...getReferenceProps}
                            className={`relative w-full ${dense ? 'h-6 rounded-[7px] bg-white' : `h-10`}`}
                            style={{ minWidth: dense ? minWidth : minWidth || 200 }}
                        >
                            <Listbox.Button
                                className={`border-blue-gray-200 text-blue-gray-700 disabled:bg-blue-gray-50 peer size-full rounded-[7px] border bg-white text-left font-normal outline outline-0 transition-all focus:border-2 focus:outline-0 disabled:border-0 ${
                                    dense ? 'px-1 py-1.5 text-xs' : 'px-3 py-2.5 text-base'
                                } ${label ? 'focus:border-t-transparent' : ''} ${(hasValue || open) && label ? 'border border-t-transparent' : ''} ${
                                    error
                                        ? 'border-red-500 placeholder-shown:border-red-500 focus:border-red-500'
                                        : `border-blue-gray-200 focus:border-input-focus flex items-center`
                                }`}
                            >
                                {/* {value ? renderValueLabel(children) : null} */}
                                <span className="block truncate">{renderValueLabel(children)}</span>
                                <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                                    {loading ? (
                                        <Loading size="h-6 w-6" className="text-sourgum-greyblue-900" />
                                    ) : (
                                        <ChevronUpDownIcon
                                            className={`${dense ? 'h-4 w-6 bg-white' : 'size-5'} text-gray-400 ${
                                                disabled ? 'bg-blue-gray-50' : 'bg-white'
                                            }`}
                                            aria-hidden="true"
                                        />
                                    )}
                                </div>
                            </Listbox.Button>
                            <Listbox.Label
                                // DO NOT LISTEN TO THIS LINT ERROR, BREAKS OVERFLOW CLIPPING, CAUSES DOUBLE SCROLLING, WEIRD RENDERING, STOP DON"T DO IT PLEASE
                                // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
                                className={`${
                                    dense ? 'h-fit' : ''
                                } before:content[' '] after:content[' '] text-blue-gray-400 peer-disabled:text-blue-gray-400 pointer-events-none absolute -top-1.5 left-0 flex size-full select-none overflow-clip font-normal transition-all before:pointer-events-none before:mr-1 before:mt-[6.5px] before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:transition-all after:pointer-events-none after:ml-1 after:mt-[6.5px] after:box-border after:block after:h-1.5 after:w-2.5 after:grow after:rounded-tr-md after:transition-all peer-focus:text-[11px] peer-focus:leading-tight peer-disabled:text-gray-500 peer-disabled:before:border-transparent peer-disabled:after:border-transparent ${
                                    label
                                        ? 'peer-focus:before:border-l-2 peer-focus:before:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-t-2'
                                        : ''
                                } ${
                                    (hasValue || open) && label
                                        ? 'before:border-blue-gray-200 after:border-blue-gray-200 text-[11px] leading-tight before:border-l before:border-t after:border-r after:border-t peer-disabled:text-gray-500'
                                        : `${
                                              dense ? 'text-xs leading-[2.9]' : 'text-base leading-[3.25]'
                                          } before:border-transparent after:border-transparent`
                                } ${
                                    error
                                        ? 'text-red-500 before:border-red-500 after:border-red-500 peer-placeholder-shown:text-red-500 peer-focus:text-red-500 peer-focus:before:border-red-500 peer-focus:after:border-red-500'
                                        : `text-blue-gray-400 before:border-blue-gray-200 after:border-blue-gray-200 peer-focus:text-input-focus peer-focus:before:border-input-focus peer-focus:after:border-input-focus`
                                }`}
                            >
                                {label}
                                {required && label && (
                                    <span className={`ml-0.5 inline-block ${disabled ? 'text-gray-400' : 'text-gray-500'}`}>*</span>
                                )}
                            </Listbox.Label>
                        </div>
                        <Portal>
                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options
                                    ref={refs.setFloating}
                                    style={{ ...floatingStyles, minWidth: refs.reference.current?.getBoundingClientRect().width }}
                                    {...getFloatingProps()}
                                    className={`z-[1375] mt-1 max-h-56 overflow-auto rounded-md bg-white text-left shadow-lg ring-1 ring-black/5 focus:outline-none ${
                                        dense ? 'py-0 text-xs' : 'py-1 text-base'
                                    } ${listBoxOptionsClassName || ''}`}
                                >
                                    {React.Children.map(children, (child) =>
                                        React.cloneElement(child, {
                                            isSelected: value === child.props.value,
                                            dense: dense,
                                        }),
                                    )}
                                </Listbox.Options>
                            </Transition>
                        </Portal>
                    </div>
                )}
            </Listbox>
            {error ? (
                <p className="mx-3.5 mt-1 text-xs text-[#F44336]">{error.message}</p>
            ) : (
                helperText && <p className="mx-3.5 mt-1 text-xs text-black opacity-50">{helperText}</p>
            )}
        </div>
    );
};

export default Select;

interface SelectOptionProps {
    value: string | number | boolean | Array<string | number> | undefined | null;
    children: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLLIElement> | undefined;
    label?: string;
    dense?: boolean;
    disabled?: boolean;
    warning?: boolean;
    className?: string;
    tooltipProps?: Omit<TooltipProps, 'children'>;
}

export const SelectOption = ({ value, children, onClick, dense, disabled, warning, className, tooltipProps }: SelectOptionProps) => {
    // sour-ui-select-option does nothing but allows it to be accessed via target
    return (
        <Listbox.Option
            className={({ active }) =>
                twMerge(
                    `${
                        disabled
                            ? 'text-blue-gray-300'
                            : active
                              ? `bg-primary-400 ${warning ? 'text-[#F44336]' : 'text-white'}`
                              : `${warning ? 'text-[#F44336]' : 'text-blue-gray-900'}`
                    } relative cursor-pointer select-none pr-9 ${dense ? 'py-1.5 pl-1' : 'py-2 pl-3'} sour-ui-select-option ${className}`,
                )
            }
            value={value}
            onClick={onClick}
            disabled={disabled}
        >
            {({ selected, active }) => (
                <Tooltip {...tooltipProps}>
                    <div className="flex items-center">
                        <span className={`${selected && !disabled ? 'font-semibold' : 'font-normal'} block w-full truncate`}>
                            {children}
                        </span>
                    </div>

                    {selected && !disabled && (
                        <span
                            className={
                                warning
                                    ? `${active ? 'text-[#F44336]' : 'text-[#F44336]'} absolute inset-y-0 right-0 flex items-center pr-4`
                                    : `${active ? 'text-white' : 'text-primary-400'} absolute inset-y-0 right-0 flex items-center pr-4`
                            }
                        >
                            <CheckIcon className={`${dense ? 'size-4' : 'size-5'}`} aria-hidden="true" />
                        </span>
                    )}
                </Tooltip>
            )}
        </Listbox.Option>
    );
};

type BaseSelectMenuProps = {
    disabled?: boolean;
    defaultValue?: string;
    children: any;
    dense?: boolean;
    listBoxOptionsClassName?: string;
    /**
     * Props to be passed to existing floating middleware, such as offset, flip, shift
     */
    floatProps?: {
        offset?: OffsetOptions;
        flip?: FlipOptions;
        shift?: ShiftOptions;
    };
    /**
     * Extra middleware to be used in the floating context (probably should never have to use this, but it's here for flexibility)
     */
    extraMiddleware?: Middleware[];
    button: React.ReactNode;
};

type SingleSelectMenuProps = BaseSelectMenuProps & {
    multiple?: false;
    onSelect: (value: string) => void;
    value?: string | number | null;
};

type MultipleSelectMenuProps = BaseSelectMenuProps & {
    multiple: true;
    onSelect: (value: string[]) => void;
    value?: string[] | number[];
};

type SelectMenuProps = SingleSelectMenuProps | MultipleSelectMenuProps;

export const SelectMenu = ({
    disabled,
    onSelect,
    defaultValue,
    value,
    children,
    dense,
    multiple,
    listBoxOptionsClassName,
    floatProps,
    extraMiddleware,
    button,
}: SelectMenuProps) => {
    const [selected, setSelected] = useState<string | number | string[] | number[]>(defaultValue || (multiple ? [] : ''));

    useEffect(() => {
        if (multiple) {
            setSelected(value || []);
        } else {
            setSelected(value || value === 0 ? value : '');
        }
    }, [value]);

    const handleSelect = (newValue: string | number | string[] | number[]) => {
        if (!multiple && newValue === selected) {
            setSelected('');
            (onSelect as (value: string | number) => void)('');
            return;
        }
        setSelected(newValue);
        if (newValue !== undefined) {
            if (multiple) {
                (onSelect as (value: string[] | number[]) => void)(newValue as string[] | number[]);
            } else {
                (onSelect as (value: string | number) => void)(newValue as string | number);
            }
        }
    };

    const { refs, floatingStyles, context } = useFloating({
        transform: false, // needed for transitions to work properly
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(floatProps?.offset || 5),
            flip({
                ...floatProps?.flip,
            }),
            shift({ ...floatProps?.shift }),
            ...(extraMiddleware || []),
        ],
    });

    const interactions = [useRole(context, { role: 'select' })];

    const { getReferenceProps, getFloatingProps } = useInteractions(interactions);

    return (
        <Listbox value={selected} onChange={(value: string) => handleSelect(value)} multiple={multiple} disabled={disabled}>
            {({ open }) => (
                <div className="relative">
                    <div ref={refs.setReference} {...getReferenceProps}>
                        <Listbox.Button>{button}</Listbox.Button>
                    </div>
                    <Portal>
                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options
                                ref={refs.setFloating}
                                style={{ ...floatingStyles, minWidth: refs.reference.current?.getBoundingClientRect().width }}
                                {...getFloatingProps()}
                                className={`z-[1375] mt-1 max-h-56 overflow-auto rounded-md bg-white text-left shadow-lg ring-1 ring-black/5 focus:outline-none ${
                                    dense ? 'py-0 text-xs' : 'py-1 text-base'
                                } ${listBoxOptionsClassName || ''}`}
                            >
                                {React.Children.map(children, (child) =>
                                    React.cloneElement(child, {
                                        isSelected: value === child.props.value,
                                        dense: dense,
                                    }),
                                )}
                            </Listbox.Options>
                        </Transition>
                    </Portal>
                </div>
            )}
        </Listbox>
    );
};
